import dayjs from 'dayjs';
import { useTranslation } from 'translations/hooks';

import { SingleCardPageWithFilterCardLayout } from 'business/layout/single-card-page-with-filter-card';
import { ProdAndPerfFilters } from 'business/production-and-performance/components/prod-and-perf-filters';
import { ProdAndPerfPrintHeader } from 'business/production-and-performance/components/prod-and-perf-print-title';
import { ExcavationBuildTimeGraphContainer } from 'business/production-and-performance/pages/excavation-build-time/components/excavation-build-time-graph-container';
import { LegendFilterProvider } from 'business/production-and-performance/pages/excavation-build-time/providers/legend-filter';
import { ProdAndPerfFiltersProvider } from 'business/production-and-performance/providers/prod-and-perf-filters-provider';
import { SingleGraphReferenceContextProvider } from 'business/providers/graph-reference';
import { FilterCard } from 'ui/filter-card';

import { ExcavationBuildTimeHeader } from './components/excavation-build-time-header';

const ExcavationBuildTimePage = () => {
  const { t } = useTranslation();

  return (
    <SingleGraphReferenceContextProvider>
      <ProdAndPerfFiltersProvider
        initialFilters={{
          filterType: 'date',
          dateRange: [dayjs().subtract(1, 'week'), dayjs()],
          dayMode: 'shift',
          showTotalDurations: false,
          durationInterval: [0, null],
        }}
      >
        <LegendFilterProvider>
          <SingleCardPageWithFilterCardLayout
            title={t('productionAndPerformance.excavationBuildTime.title')}
            header={<ExcavationBuildTimeHeader />}
            filters={
              <FilterCard>
                <ProdAndPerfFilters
                  dayModeFilterEnabled
                  durationIntervalEnabled
                  showTotalDurationEnabled
                />
              </FilterCard>
            }
            printHeader={
              <ProdAndPerfPrintHeader
                printTitle={t(
                  'productionAndPerformance.excavationBuildTime.print.title',
                )}
                printDayMode
              />
            }
          >
            <ExcavationBuildTimeGraphContainer />
          </SingleCardPageWithFilterCardLayout>
        </LegendFilterProvider>
      </ProdAndPerfFiltersProvider>
    </SingleGraphReferenceContextProvider>
  );
};

export default ExcavationBuildTimePage;
