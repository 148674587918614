import { Flex } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { TFunction, useTranslation } from 'translations/hooks';
import { getLocale } from 'translations/locale';

import { useAppContext } from 'business/contextProviders/useAppContext';
import { useReportProdAnalyzeFilters } from 'business/shift-report/report-analyze/pages/production-analyze/providers/filters';
import config from 'config';
import AppLogo from 'ui/appLogo';

import styles from './index.module.scss';

interface PrintHeaderProps {
  printTitle: string;
}

const getDateRange = (
  t: TFunction,
  startDate: Dayjs | null | undefined,
  endDate: Dayjs | null | undefined,
) => {
  const locale = getLocale();

  if (startDate && endDate) {
    return t('common.printTitle.dateRange', {
      startDate: dayjs.tz(startDate).locale(locale).format('L'),
      endDate: dayjs.tz(endDate).locale(locale).format('L'),
    });
  }

  if (startDate) {
    return t('common.printTitle.startDate', {
      startDate: dayjs.tz(startDate).locale(locale).format('L'),
    });
  }

  if (endDate) {
    return t('common.printTitle.startDate', {
      endDate: dayjs.tz(endDate).locale(locale).format('L'),
    });
  }

  return null;
};

const getRingRange = (
  t: TFunction,
  startRing: number | null | undefined,
  endRing: number | null | undefined,
) => {
  if (startRing && endRing) {
    return t('common.printTitle.ringRange', {
      startRing,
      endRing,
    });
  }

  if (startRing) {
    return t('common.printTitle.startRing', {
      startRing,
    });
  }

  if (endRing) {
    return t('common.printTitle.endRing', {
      endRing,
    });
  }

  return null;
};

export const PrintHeader = ({ printTitle }: PrintHeaderProps) => {
  const { currentConstructionSite } = useAppContext();
  const { t } = useTranslation();
  const filters = useReportProdAnalyzeFilters();

  const { filterType } = filters;
  let dateRange, ringRange;

  if (filterType === 'date') {
    dateRange = filters.dateRange;
  }
  if (filterType === 'ring') {
    ringRange = filters.ringRange;
  }

  const range = {
    date: getDateRange(t, dateRange?.[0], dateRange?.[1]),
    ring: getRingRange(t, ringRange?.[0], ringRange?.[1]),
  };

  return (
    <Flex vertical className={styles.header}>
      <Flex justify="center" gap={20}>
        <AppLogo />
        {currentConstructionSite?.logo ? (
          <AppLogo
            alt="Construction site logo"
            path={`${config.gcp.publicUri}/${currentConstructionSite.logo}`}
          />
        ) : null}
      </Flex>
      {currentConstructionSite?.name ? (
        <h1 className={styles.constructionSiteName}>
          {currentConstructionSite?.name}
        </h1>
      ) : null}
      <h1>{printTitle}</h1>
      <div>{range[filterType] ? <h3>{range[filterType]}</h3> : null}</div>
    </Flex>
  );
};
