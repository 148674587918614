import { App } from 'antd';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useTranslation } from 'translations/hooks';

import { useRestApiHeaders } from 'business/contextProviders/useApiHeaders';
import { ProdAndPerfRangeFilters } from 'business/production-and-performance/providers/prod-and-perf-filters-provider/types';
import {
  PlanningControllerGetPlanningQueryParams,
  usePlanningControllerGetPlanning,
} from 'generated/apiComponents';
import { Module_Enum } from 'generated/graphql';
import { SIMPLE_DATE_FORMAT } from 'technical/string/formatter';
import { notEmpty } from 'technical/utils/not-empty';
import { validateRingRange } from 'technical/validation/rules';

export const useGetFilteredRingsRealProgressionQuery = (
  constructionSiteId: string,
  filters: ProdAndPerfRangeFilters,
) => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const headers = useRestApiHeaders(Module_Enum.ProdPerf);

  const queryParams: PlanningControllerGetPlanningQueryParams = {
    constructionSiteId,
    withProjection: filters.withProjection,
  };

  if (filters.filterType === 'date' && filters.dateRange) {
    const [startDate, endDate] = filters.dateRange ?? [null, null];
    if (startDate) {
      queryParams.startDate = startDate?.format(SIMPLE_DATE_FORMAT);
    }
    if (endDate) {
      queryParams.endDate = endDate?.format(SIMPLE_DATE_FORMAT);
    }
  }

  if (
    filters.filterType === 'ring' &&
    filters.ringRange &&
    validateRingRange(filters.ringRange)
  ) {
    const [startRing, endRing] = filters.ringRange ?? [null, null];
    if (startRing) {
      queryParams.startRing = startRing;
    }
    if (endRing) {
      queryParams.endRing = endRing;
    }
  }

  const {
    data: result,
    isLoading,
    error,
  } = usePlanningControllerGetPlanning(
    {
      queryParams,
      headers,
    },
    {
      select: (res) => ({
        data: res.data.map((item) => ({
          ...item,
          date: dayjs(item.date).toDate(),
        })),
      }),
    },
  );

  useEffect(() => {
    if (error) {
      message.open({
        type: 'error',
        content: t('errors.fetching_error'),
      });
    }
  }, [error, message, t]);

  const filteredData = result?.data.filter(
    (
      ring,
    ): ring is { highestRing: number; date: Date; projectedRing: number } =>
      notEmpty(ring) && notEmpty(ring.date),
  );

  return {
    filteredData,
    loading: isLoading,
    error,
  };
};
