import {
  AgCartesianAxisOptions,
  AgCartesianSeriesOptions,
  AgChartOptions,
} from 'ag-charts-enterprise';
import { AgCharts } from 'ag-charts-react';
import { useMemo } from 'react';
import { useTranslation } from 'translations/hooks';

import { useProdAndPerfFilters } from 'business/production-and-performance/providers/prod-and-perf-filters-provider';
import { formatGraphDateAxisLabel } from 'business/production-and-performance/services/graph-date-format';
import { useSingleGraphReferenceContext } from 'business/providers/graph-reference';
import { useChartResize } from 'technical/chart-resize';

import styles from './index.module.scss';

type UseRingPerDayCalendarGraphSeriesOptionsProps = {
  withProjection?: boolean;
};

function useRingPerDayCalendarGraphSeriesOptions({
  withProjection,
}: UseRingPerDayCalendarGraphSeriesOptionsProps) {
  const { t } = useTranslation();

  return useMemo(() => {
    const seriesOptions: AgCartesianSeriesOptions[] = [
      {
        type: 'line',
        xKey: 'date',
        yKey: 'highestRing',
        marker: { size: 4 },
        connectMissingData: true,
        yName: t('productionAndPerformance.planning.graph.seriesNames.real'),
      },
    ];

    if (withProjection) {
      seriesOptions.push({
        type: 'line',
        xKey: 'date',
        yKey: 'projectedRing',
        marker: { size: 4 },
        yName: t(
          'productionAndPerformance.planning.graph.seriesNames.projection',
        ),
      });
    }

    return seriesOptions;
  }, [withProjection, t]);
}

type RingPerDayGraphProps = {
  data: {
    date: Date;
    highestRing?: number | string;
    projectedRing?: number | string;
  }[];
};

const ConstructionSitePlanningGraphAxesOptions = [
  {
    type: 'time',
    position: 'bottom',
    nice: false,
    label: {
      formatter: ({ value }) => formatGraphDateAxisLabel(value),
    },
  },
  {
    type: 'number',
    position: 'left',
  },
] satisfies AgCartesianAxisOptions[];

export function ConstructionSitePlanningGraph({ data }: RingPerDayGraphProps) {
  const { ref } = useSingleGraphReferenceContext();
  const filters = useProdAndPerfFilters();

  const seriesOptions = useRingPerDayCalendarGraphSeriesOptions(filters);

  const chartOptions = {
    data,
    series: seriesOptions,
    axes: ConstructionSitePlanningGraphAxesOptions,
    zoom: {
      // zoom on where the mouse is
      anchorPointX: 'pointer',
      anchorPointY: 'pointer',
    },
  } satisfies AgChartOptions;

  useChartResize(ref);

  return <AgCharts className={styles.graph} options={chartOptions} ref={ref} />;
}
