import { Dayjs } from 'dayjs';

import { parseShiftTimeStringWithReportDate } from 'business/shift-report/shift/services/timeOperations';
import { parseTaskDateString } from 'business/shift-report/task/services/timeOperations';
import { ShiftFullFragment, TaskFullFragment } from 'generated/graphql';

import { abscissaScale, ordinalScale } from './axis';
import {
  getEmptyZoneHeight,
  getEmptyZoneStyle,
  getEmptyZoneWidth,
  getEmptyZoneXPos,
  getEmptyZoneYPos,
} from './metadata/emptyZone';
import {
  CreateElementGroup,
  EmptyZone,
  UpdateElementGroup,
} from './metadata/types';

export const getEmptyZones = (
  shift: ShiftFullFragment,
  tasks: TaskFullFragment[],
  date: Dayjs,
  criticalPathValid?: boolean,
) => {
  let emptyZoneBeforeEnd = null as EmptyZone | null;
  if (tasks.length && !criticalPathValid) {
    // We need to loop over all tasks to find the one ending last.
    // Even if tasks are sorted by startTime, the last starting task is not always the last ending.
    const lastTask = tasks
      .filter((task) => task.critical)
      .reduce((prev, current) => {
        const currentEndTime = parseTaskDateString(current.endDate);
        const prevEndTime = parseTaskDateString(prev.endDate);
        return currentEndTime.isAfter(prevEndTime) ? current : prev;
      });
    if (
      parseTaskDateString(lastTask.endDate).isBefore(
        parseShiftTimeStringWithReportDate(shift.endTime, date),
      )
    ) {
      emptyZoneBeforeEnd = {
        startDate: lastTask.endDate,
        endDate: parseShiftTimeStringWithReportDate(shift.endTime, date)
          .format()
          .toString(),
        prevTaskId: lastTask.id,
        nextTaskId: null,
      };
    }
  }

  return tasks
    .filter((task) => task.critical)
    .reduce<EmptyZone[]>(
      (acc, task, id, tasksFiltered) => {
        if (id > 0) {
          // Need to find task that started before current task with the max endDate.
          const lastTaskBeforeIndex = tasksFiltered
            .slice(0, id)
            .reduce<TaskFullFragment>((tmpAcc, tmpTask) => {
              if (
                parseTaskDateString(tmpTask.endDate).isAfter(
                  parseTaskDateString(tmpAcc.endDate),
                )
              ) {
                // eslint-disable-next-line no-param-reassign
                tmpAcc = tmpTask;
              }
              return tmpAcc;
            }, tasksFiltered[0]);
          // Check if there is an empty zone
          if (
            parseTaskDateString(task.startDate).isAfter(
              parseTaskDateString(lastTaskBeforeIndex.endDate),
            )
          ) {
            return [
              ...acc,
              {
                startDate: lastTaskBeforeIndex.endDate,
                endDate: task.startDate,
                prevTaskId: lastTaskBeforeIndex.id,
                nextTaskId: task.id,
              },
            ];
          }
        }
        if (
          id === 0 &&
          parseTaskDateString(task.startDate).isAfter(
            parseShiftTimeStringWithReportDate(shift.startTime, date),
          )
        ) {
          return [
            ...acc,
            {
              startDate: parseShiftTimeStringWithReportDate(
                shift.startTime,
                date,
              ).toISOString(),
              endDate: task.startDate,
              prevTaskId: null,
              nextTaskId: task.id,
            },
          ];
        }
        return acc;
      },
      emptyZoneBeforeEnd ? [emptyZoneBeforeEnd] : [],
    );
};

export const createEmptyZones = (
  emptyZoneGroup: CreateElementGroup<EmptyZone>,
  xScale: abscissaScale,
  yScale: ordinalScale,
  activitiesCount: number,
  handleEmptyZoneClick: (event: any, emptyZone: EmptyZone) => void,
) => {
  emptyZoneGroup
    .append('rect')
    .attr('x', getEmptyZoneXPos(xScale))
    .attr('y', getEmptyZoneYPos())
    .attr('rx', 5)
    .attr('ry', 5)
    .attr('width', getEmptyZoneWidth(xScale))
    .attr('height', getEmptyZoneHeight(yScale, activitiesCount))
    .attr('style', getEmptyZoneStyle)
    .on('click', handleEmptyZoneClick);
};

export const updateEmptyZones = (
  emptyZoneGroup: UpdateElementGroup<EmptyZone>,
  xScale: abscissaScale,
  yScale: ordinalScale,
  activitiesCount: number,
  handleEmptyZoneClick: (event: any, emptyZone: EmptyZone) => void,
) => {
  emptyZoneGroup
    .select('rect')
    .attr('x', getEmptyZoneXPos(xScale))
    .attr('y', getEmptyZoneYPos())
    .attr('rx', 5)
    .attr('ry', 5)
    .attr('width', getEmptyZoneWidth(xScale))
    .attr('height', getEmptyZoneHeight(yScale, activitiesCount))
    .attr('style', getEmptyZoneStyle)
    .on('click', handleEmptyZoneClick);

  emptyZoneGroup.exit().remove();
};
