import { Flex } from 'antd';
import classNames from 'classnames';
import { PropsWithChildren, ReactNode } from 'react';

import styles from './index.module.scss';

interface CapCardProps {
  title: ReactNode;
  className?: string;
  isFinished?: boolean;
  isValidated?: boolean;
}

function CapCard({
  title,
  children,
  className,
  isFinished = true,
  isValidated = false,
}: PropsWithChildren<CapCardProps>) {
  return (
    <Flex vertical className={classNames(styles.capCard, className)}>
      <div className={styles.titleContainer}>
        <h3
          className={classNames(
            styles.title,
            !(isFinished || isValidated) ? styles.titleInProgress : undefined,
          )}
        >
          {title}
        </h3>
        <div className={styles.titleDivider} />
      </div>

      <Flex vertical flex={1} className={styles.cardContainer}>
        {children}
      </Flex>
    </Flex>
  );
}

export default CapCard;
