import { Drawer } from 'antd';
import { PropsWithChildren } from 'react';

import { MenuButton } from 'ui/menu/menu-button';

interface MenuDrawerProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export const MenuDrawer = ({
  open,
  onOpenChange,
  children,
}: PropsWithChildren<MenuDrawerProps>) => {
  return (
    <>
      <MenuButton onClick={() => onOpenChange(true)} />
      <Drawer open={open} onClose={() => onOpenChange(false)} placement="left">
        {children}
      </Drawer>
    </>
  );
};
