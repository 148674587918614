import dayjs from 'dayjs';
import { useTranslation } from 'translations/hooks';

import { SingleCardPageWithFilterCardLayout } from 'business/layout/single-card-page-with-filter-card';
import { ProdAndPerfFilters } from 'business/production-and-performance/components/prod-and-perf-filters';
import { ProdAndPerfPrintHeader } from 'business/production-and-performance/components/prod-and-perf-print-title';
import { RingPerDayLegendFilterProvider } from 'business/production-and-performance/pages/ring-per-day/providers/legend-filter';
import { ProdAndPerfFiltersProvider } from 'business/production-and-performance/providers/prod-and-perf-filters-provider';
import { SingleGraphReferenceContextProvider } from 'business/providers/graph-reference';
import { FilterCard } from 'ui/filter-card';

import { RingPerDayGraphContainer } from './components/ring-per-day-graph-container';
import { RingPerDayHeader } from './components/ring-per-day-header';

const RingPerDayPage = () => {
  const { t } = useTranslation();

  return (
    <SingleGraphReferenceContextProvider>
      <ProdAndPerfFiltersProvider
        initialFilters={{
          filterType: 'date',
          dateRange: [dayjs().subtract(1, 'week'), dayjs()],
          dayMode: 'shift',
        }}
      >
        <RingPerDayLegendFilterProvider>
          <SingleCardPageWithFilterCardLayout
            title={t('productionAndPerformance.ringPerDay.title')}
            header={<RingPerDayHeader />}
            filters={
              <FilterCard>
                <ProdAndPerfFilters dayModeFilterEnabled />
              </FilterCard>
            }
            printHeader={
              <ProdAndPerfPrintHeader
                printTitle={t(
                  'productionAndPerformance.ringPerDay.print.title',
                )}
                printDayMode
              />
            }
          >
            <RingPerDayGraphContainer />
          </SingleCardPageWithFilterCardLayout>
        </RingPerDayLegendFilterProvider>
      </ProdAndPerfFiltersProvider>
    </SingleGraphReferenceContextProvider>
  );
};

export default RingPerDayPage;
