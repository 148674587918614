import { CloseOutlined } from '@ant-design/icons';
import { t } from 'i18next';
import { Link } from 'react-router-dom';

import { usePermissions } from 'business/contextProviders/usePermissions';
import Routes from 'config/routes';
import Button, { ButtonProps } from 'ui/button';

interface ExitReportButtonProps extends Omit<ButtonProps, 'icon'> {
  managerRoute: string | null;
}

const ExitReportButton = ({
  managerRoute = Routes.ReportListInProgress,
  ...buttonProps
}: ExitReportButtonProps) => {
  const {
    modules: {
      SHIFT_REPORT: { hasManagerReadPermission },
    },
  } = usePermissions();

  return (
    <Link
      to={
        hasManagerReadPermission && managerRoute
          ? managerRoute
          : Routes.ReportUserHome
      }
    >
      <Button {...buttonProps} icon={<CloseOutlined />}>
        {t('common.close')}
      </Button>
    </Link>
  );
};

export default ExitReportButton;
