import { GetFromLocalStorageInputWithSchema } from 'technical/local-storage/types';
import logger from 'technical/logger';

const getKey = ({ scope, key }: { scope: string; key: string }) => {
  return `${scope}-${key}`;
};

export const insertOrUpdateLocalStorageValue = <T>({
  scope,
  key,
  value,
}: {
  scope: string;
  key: string;
  value: T;
}) => {
  const fullKey = getKey({ scope, key });

  if (localStorage.getItem(fullKey) === null) {
    logger.info('Specified key already exists', { key: fullKey });
  }

  localStorage.setItem(fullKey, JSON.stringify(value));
};

export function getLocalStorageValue(
  input: Omit<GetFromLocalStorageInputWithSchema<any>, 'schema'>,
): unknown;
export function getLocalStorageValue<T>(
  input: Required<GetFromLocalStorageInputWithSchema<T>>,
): T;
export function getLocalStorageValue<T>({
  scope,
  key,
  schema,
}: GetFromLocalStorageInputWithSchema<T>) {
  const fullKey = getKey({ scope, key });

  const value = localStorage.getItem(fullKey);

  if (value === null) {
    logger.info('Specified key does not exist', { key: fullKey });
    return null;
  }

  const fromLocalStorageString = JSON.parse(value);

  if (schema) {
    const parsedValue = schema.safeParse(fromLocalStorageString);
    if (!parsedValue.success) {
      logger.error(parsedValue.error);
      return null;
    }

    return parsedValue.data;
  }

  return fromLocalStorageString;
}
