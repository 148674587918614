import { Form } from 'antd';
import { FormProviderProps } from 'antd/es/form/context';
import dayjs from 'dayjs';
import { PropsWithChildren, createContext, useContext, useState } from 'react';

import { GROUP_BY_TYPES_MAP } from 'business/shift-report/report-analyze/pages/production-analyze/types';
import { DateSelectionTypeEnum } from 'ui/form/temporality-select-input';

import { ReportProdAnalyzeRangeFilters } from './types';

const FILTERS_FORM_NAME = 'report-production-analyze-filters-form';

const defaultFilters = {
  filterType: 'date',
  dateRange: [null, null],
  groupByType: GROUP_BY_TYPES_MAP.shift,
} satisfies ReportProdAnalyzeRangeFilters;

const ReportProdAnalyzeFiltersContext =
  createContext<ReportProdAnalyzeRangeFilters>(defaultFilters);

const ReportProdAnalyzeFiltersProvider = ({
  initialFilters,
  noDefaultFor,
  children,
}: PropsWithChildren<{
  initialFilters: ReportProdAnalyzeRangeFilters;
  noDefaultFor?: DateSelectionTypeEnum[];
}>) => {
  const [filters, setFilters] =
    useState<ReportProdAnalyzeRangeFilters>(initialFilters);

  const onFormChange: FormProviderProps['onFormChange'] = (
    formName,
    { forms },
  ) => {
    if (formName === FILTERS_FORM_NAME) {
      const form = forms[formName];

      const dateRange =
        form.getFieldValue('filterType') === 'date' &&
        // Check for `period` included in noDefaultFor to empty the input when
        // it is changed back to date filter type from ring filter type
        noDefaultFor?.includes(DateSelectionTypeEnum.Period)
          ? [null, null]
          : [dayjs().subtract(1, 'week'), dayjs()];

      const values = {
        dateRange,
        ringRange: [null, null],
        ...form.getFieldsValue(),
      };

      // Setting default range for both day and ring because Antd Form remove the fields value if the input is
      // not rendered. Since we conditionnaly render the dateRange or ringRange depending on the `filterType`
      // it is required to add them with defaut value to the FiltersProvider. Therefore, we're sure it is always
      // defined when the filterType is changed
      setFilters(values);
      form.setFieldsValue(values);
    }
  };

  return (
    <ReportProdAnalyzeFiltersContext.Provider value={filters}>
      <Form.Provider onFormChange={onFormChange}>{children}</Form.Provider>
    </ReportProdAnalyzeFiltersContext.Provider>
  );
};

const useReportProdAnalyzeFiltersFormName = () => FILTERS_FORM_NAME;

const useReportProdAnalyzeFilters = () =>
  useContext(ReportProdAnalyzeFiltersContext);

export {
  ReportProdAnalyzeFiltersProvider,
  useReportProdAnalyzeFiltersFormName,
  useReportProdAnalyzeFilters,
};
