import { Flex, Modal } from 'antd';
import { useTranslation } from 'translations/hooks';

import { Task } from 'business/shift-report/report/pages/ReportEdit/components/mass-validation-modal/task';
import { TaskFullFragment } from 'generated/graphql';

import styles from './index.module.scss';

interface MassValidationModalProps {
  tasks?: TaskFullFragment[];
  visibility: boolean;
  onCancel: () => void;
  onOk: () => void;
}

export const MassValidationModal = ({
  tasks,
  visibility: open,
  onCancel,
  onOk,
}: MassValidationModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal
      open={open}
      onCancel={onCancel}
      onOk={onOk}
      okText={t('pages.report.massValidation.accept')}
      className={styles.modal}
      title={t('pages.report.massValidation.title')}
    >
      <Flex vertical gap={24} className={styles.content}>
        {tasks?.map((task) => <Task task={task} />)}
      </Flex>
    </Modal>
  );
};
