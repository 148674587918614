import { Col, Flex, Row, message } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'translations/hooks';

import { useAppContext } from 'business/contextProviders/useAppContext';
import MaintenanceAlert from 'business/user/components/maintenanceAlert';
import aggregatedReport from 'business/user/pages/Dashboard/assets/aggregatedReport.svg';
import bi from 'business/user/pages/Dashboard/assets/bi.svg';
import weekReport from 'business/user/pages/Dashboard/assets/weekReport.svg';
import DashboardTile from 'business/user/pages/Dashboard/components/dashboard-tile';
import styles from 'business/user/pages/Dashboard/index.module.scss';
import Routes from 'config/routes';
import { useAvailableCustomIndicatorsQuery } from 'generated/graphql';
import { useMediaType } from 'technical/media/hooks';

export function AnalyzeShiftReportDashboardPage() {
  const { t } = useTranslation();
  const { currentConstructionSite } = useAppContext();
  const id = currentConstructionSite?.id;

  const indicatorQuery = useAvailableCustomIndicatorsQuery({
    variables: { constructionSiteId: id },
    fetchPolicy: 'network-only',
  });

  const { isMobile, isTablet } = useMediaType();

  useEffect(() => {
    if (indicatorQuery.error) {
      message.error(t('errors.error_generic'));
    }
  }, [indicatorQuery.error, t]);

  return (
    <Flex vertical align="center" className={styles.container}>
      <MaintenanceAlert />
      <Row gutter={[30, 30]} className={styles.menuContainer}>
        <Col xs={24} md={12}>
          <DashboardTile
            title={t('pages.manager.aggregatedReport.title')}
            icon={aggregatedReport}
            loading={false}
            route={Routes.ReportExportAggregated}
            ctaLabel={t('pages.manager.aggregatedReport.button')}
            disabled={isMobile && !isTablet}
            disabledText={t('pages.manager.disabledMobile')}
          />
        </Col>
        <Col xs={24} md={12}>
          <DashboardTile
            title={t('pages.manager.batchReport.title')}
            icon={weekReport}
            loading={false}
            route={Routes.ReportExportBatch}
            ctaLabel={t('pages.manager.batchReport.button')}
            disabled={isMobile && !isTablet}
            disabledText={t('pages.manager.disabledMobile')}
          />
        </Col>
        <Col xs={24} md={12}>
          <DashboardTile
            title={t('pages.manager.taskAnalyzer.title')}
            icon={bi}
            loading={false}
            route={Routes.TaskAnalyzer}
            ctaLabel={t('pages.manager.taskAnalyzer.button')}
          />
        </Col>
        <Col xs={24} md={12}>
          <DashboardTile
            title={t('pages.manager.productionAnalyze.title')}
            icon={bi}
            loading={false}
            route={Routes.ProductionAnalyze}
            ctaLabel={t('pages.manager.productionAnalyze.button')}
          />
        </Col>
        {indicatorQuery.data?.indicator.length ? (
          <Col xs={24} md={12}>
            <DashboardTile
              title={t('pages.manager.indicators.title')}
              icon={bi}
              loading={false}
              route={Routes.Indicators}
              ctaLabel={t('pages.manager.indicators.button')}
            />
          </Col>
        ) : null}
        <Col xs={24} md={12}>
          <DashboardTile
            title={t('pages.manager.taskExport.title')}
            icon={bi}
            loading={false}
            route={Routes.TaskExport}
            ctaLabel={t('pages.manager.taskExport.button')}
            disabled={isMobile && !isTablet}
            disabledText={t('pages.manager.disabledMobile')}
          />
        </Col>
      </Row>
      {/* </Flex> */}
    </Flex>
  );
}
