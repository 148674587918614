import { GraphPageState } from './../types';

export const toggleParameterList = (
  graphSet: GraphPageState,
  display?: boolean,
) => {
  if (display !== undefined) {
    return { ...graphSet, displayParameterList: display };
  }

  return { ...graphSet, displayParameterList: !graphSet.displayParameterList };
};
