import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import merge from 'lodash.merge';
import { initReactI18next } from 'react-i18next';

import config from 'config';

import translationEn from './en.json';
import translationEnUS from './en_us.json';
import translationEs from './es.json';
import translationFr from './fr.json';
import { ILanguage } from './types';

export const DEV_LANG_ENABLED = config.env === 'test' || config.env === 'local';

const resources = {
  [ILanguage.enGB]: {
    translation: structuredClone(translationEn),
  },
  [ILanguage.enUS]: {
    translation: merge(structuredClone(translationEn), translationEnUS),
  },
  [ILanguage.fr]: {
    translation: translationFr,
  },
  [ILanguage.es]: {
    translation: translationEs,
  },
  ...(DEV_LANG_ENABLED
    ? {
        [ILanguage.dev]: {
          translation: {},
        },
      }
    : {}),
};

i18n
  .use(LanguageDetector) // detect browser language
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: DEV_LANG_ENABLED ? undefined : ILanguage.enGB,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export { ILanguage, i18n };
