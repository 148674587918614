import { Link, To } from 'react-router-dom';
import { useTranslation } from 'translations/hooks';

import Button from 'ui/button';

interface GoBackButtonProps {
  to: To;
}

export const GoBackButton = ({ to }: GoBackButtonProps) => {
  const { t } = useTranslation();

  return (
    <Link to={to}>
      <Button type="primary" ghost>
        {t('common.go_back')}
      </Button>
    </Link>
  );
};
