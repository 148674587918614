import { Flex, message, Skeleton } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

import Button from 'ui/button';
import CapCard from 'ui/cap-card';

import styles from './index.module.scss';

interface DashboardTileProps {
  title: string;
  icon: string;
  loading: boolean;
  text?: React.ReactNode;
  route: string;
  ctaLabel: string;
  disabled?: boolean;
  disabledText?: string;
}

export default function DashboardTile(props: DashboardTileProps) {
  const {
    title,
    icon,
    loading,
    text,
    route,
    ctaLabel,
    disabled,
    disabledText,
  } = props;

  return (
    <CapCard title={title} className={styles.card}>
      <Flex vertical justify="flex-end" align="center" gap={20} flex={1}>
        <Flex vertical gap={20} flex={1} justify="center">
          <img src={icon} alt={`${title} icon`} />
          <Skeleton
            title={false}
            paragraph={{ rows: 1, width: '100' }}
            active
            loading={loading}
          >
            {text}
          </Skeleton>
        </Flex>
        {disabled ? (
          <Button
            onClick={() => {
              message.info(disabledText);
            }}
            type="primary"
            ghost
          >
            {ctaLabel}
          </Button>
        ) : (
          <Link to={route}>
            <Button type="primary">{ctaLabel}</Button>
          </Link>
        )}
      </Flex>
    </CapCard>
  );
}
