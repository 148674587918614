import { useTranslation } from 'translations/hooks';

import { AggregatedTaskPieChart } from 'business/shift-report/report/pages/ReportAggregated/components/aggregated-task-pie-chart';

import { TopLevelTasksAggregate } from './types';

interface AggregatedGraphProps {
  topLevelTasksAggregate: TopLevelTasksAggregate[];
  critical: boolean;
  title?: string;
}

export default function AggregatedGraph({
  topLevelTasksAggregate,
  critical,
  title,
}: AggregatedGraphProps) {
  const { t } = useTranslation();

  return (
    <div key="graph-container" className="bloc">
      <h1>
        {title
          ? title
          : t('pages.print.shiftProductionTime', {
              context: critical ? 'critical' : '',
            })}
      </h1>
      <AggregatedTaskPieChart aggregatedTasks={topLevelTasksAggregate} />
    </div>
  );
}
