import { ExportOutlined } from '@ant-design/icons';
import invariant from 'tiny-invariant';
import { useTranslation } from 'translations/hooks';
import { getLocale } from 'translations/locale';

import { useRestApiHeaders } from 'business/contextProviders/useApiHeaders';
import { useAppContext } from 'business/contextProviders/useAppContext';
import { useRingPerDayLegendFilter } from 'business/production-and-performance/pages/ring-per-day/providers/legend-filter';
import { useProdAndPerfFilters } from 'business/production-and-performance/providers/prod-and-perf-filters-provider';
import { Module_Enum } from 'generated/graphql';
import { notEmpty } from 'technical/utils/not-empty';
import Button from 'ui/button';

import { useRingPerDayExport } from './use-ring-per-day-export';

export const RingPerDayExport = () => {
  const { t } = useTranslation();
  const { currentConstructionSiteId } = useAppContext();
  const headers = useRestApiHeaders(Module_Enum.ProdPerf);
  invariant(currentConstructionSiteId, 'No constructionSite id');

  const { dayMode } = useProdAndPerfFilters();
  const { legendFilter } = useRingPerDayLegendFilter();

  const { generateExport, fetchRange } = useRingPerDayExport();

  const onClick = () => {
    const legendFilterKeys = Object.entries(legendFilter)
      .map(([key, value]) => (value ? key : null))
      .filter(notEmpty);

    generateExport({
      body: {
        constructionSiteId: currentConstructionSiteId,
        dayMode: dayMode,
        legendFilter: legendFilterKeys,
        locale: getLocale(),
        ...fetchRange,
      },
      headers,
    });
  };

  return (
    <Button icon={<ExportOutlined />} onClick={onClick}>
      {t('common.export')}
    </Button>
  );
};
