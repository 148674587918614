import { AgChartInstance, AgChartOptions } from 'ag-charts-enterprise';
import { RefObject, useEffect } from 'react';

import { MAX_CHART_HEIGHT } from 'technical/chart-resize/constants';

export const useChartResize = (
  chartRef: RefObject<AgChartInstance<AgChartOptions>> | undefined,
  squareChart: boolean = false,
  maxHeight: number = MAX_CHART_HEIGHT,
) => {
  useEffect(() => {
    const resizeChart = async () => {
      const chartContainer = chartRef?.current?.getOptions().container;

      if (squareChart) {
        await chartRef?.current?.updateDelta({
          width:
            chartContainer?.clientWidth &&
            chartContainer.clientWidth < maxHeight
              ? chartContainer.clientWidth
              : maxHeight,
          height:
            chartContainer?.clientWidth &&
            chartContainer.clientWidth < maxHeight
              ? chartContainer.clientWidth
              : maxHeight,
        });
        return;
      }

      await chartRef?.current?.updateDelta({
        width: chartContainer?.clientWidth,
      });
    };

    addEventListener('resize', resizeChart);

    return () => removeEventListener('resize', resizeChart);
  }, [chartRef, maxHeight, squareChart]);
};
