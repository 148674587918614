import { Col, Flex } from 'antd';
import { PropsWithChildren } from 'react';

import { useCheckPermission } from 'business/contextProviders/usePermissions';
import { useMediaType } from 'technical/media/hooks';

interface Props {
  permissions: string[];
  numberOfColumns: number;
}

const MenuColumn = ({
  children,
  permissions,
  numberOfColumns,
}: PropsWithChildren<Props>) => {
  const { isMobile } = useMediaType();
  const permissionCheck = useCheckPermission(permissions);
  if (!permissionCheck) {
    return null;
  }

  if (isMobile) {
    return (
      <Col xs={24}>
        <Flex gap={20} vertical>
          {children}
        </Flex>
      </Col>
    );
  }

  return (
    <Col xs={24} lg={24 / Math.min(3, numberOfColumns)}>
      <Flex gap={20} vertical>
        {children}
      </Flex>
    </Col>
  );
};

export default MenuColumn;
