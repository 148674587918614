import { setDateToStartOfDay } from 'technical/time-utils';
import { objectWithDateSort } from 'technical/time-utils/date-sort';
import { getMissingDatesInArray } from 'technical/time-utils/get-missing-dates-in-arrray';
import { DateRangeType } from 'technical/types';

export type GenericGraphData = {
  date: Date;
  [key: string]: unknown | undefined;
};

// Durring the developpement, we found a strange bug when there is only 2 values
// with a 2 days interterval between.
// The only fix was to add the missing days with values at 0
// This also completed the missing days within a selected day range
export const addMissingDaysForGraphPerDay = <
  GraphData extends GenericGraphData,
>(
  dateRange: DateRangeType,
  graphData: GraphData[],
  getEmptyGraphPoint: (date: Date) => GraphData,
) => {
  const graphDataClone = structuredClone(graphData);

  if (dateRange && dateRange[0] && dateRange[1]) {
    const [startDate, endDate] = dateRange;

    const missingDates = getMissingDatesInArray(
      graphDataClone.map(({ date }) => date),
      setDateToStartOfDay(startDate).toDate(),
      setDateToStartOfDay(endDate).toDate(),
    );

    const missingValues = missingDates.map(getEmptyGraphPoint);
    graphDataClone.push(...missingValues);
  }

  return graphDataClone.sort(objectWithDateSort);
};
