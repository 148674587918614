import { ConfigProvider, Select } from 'antd';

import { useAppContext } from 'business/contextProviders/useAppContext';

export const ConstructionSiteSelector = ({}) => {
  const {
    constructionSites,
    currentConstructionSite,
    setCurrentConstructionSiteId,
  } = useAppContext();

  function handleConstructionSiteChange(value: string): void {
    const constructionSite = constructionSites.find((cs) => cs.id === value);
    if (constructionSite) {
      setCurrentConstructionSiteId(constructionSite.id);
    }
  }

  return (
    <ConfigProvider
      theme={{
        components: {
          Select: {
            colorText: '#012169',
          },
        },
      }}
    >
      <Select
        popupMatchSelectWidth={false}
        defaultValue={currentConstructionSite?.id || constructionSites[0].id}
        onChange={handleConstructionSiteChange}
        options={constructionSites.map((constructionSite) => ({
          value: constructionSite.id,
          label: constructionSite.name,
        }))}
      />
    </ConfigProvider>
  );
};
