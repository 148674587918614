import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react';

export type RingPerDayLegendFilter = {
  shift1Count: boolean;
  shift2Count: boolean;
  shift3Count: boolean;
};

const defaultRingPerDayLegendFilter: RingPerDayLegendFilter = {
  shift1Count: true,
  shift2Count: true,
  shift3Count: true,
};

const RingPerDayLegendFilterContext = createContext<{
  legendFilter: RingPerDayLegendFilter;
  setLegendFilter: Dispatch<SetStateAction<RingPerDayLegendFilter>>;
}>({
  legendFilter: defaultRingPerDayLegendFilter,
  setLegendFilter: () => {},
});

const RingPerDayLegendFilterProvider = ({ children }: PropsWithChildren) => {
  const [legendFilter, setLegendFilter] = useState<RingPerDayLegendFilter>(
    defaultRingPerDayLegendFilter,
  );

  return (
    <RingPerDayLegendFilterContext.Provider
      value={{ legendFilter, setLegendFilter }}
    >
      {children}
    </RingPerDayLegendFilterContext.Provider>
  );
};

const useRingPerDayLegendFilter = () =>
  useContext(RingPerDayLegendFilterContext);

export { RingPerDayLegendFilterProvider, useRingPerDayLegendFilter };
