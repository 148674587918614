import { Flex, Descriptions } from 'antd';
import { DescriptionsItemType } from 'antd/es/descriptions';
import { ILanguage, i18n } from 'translations';

import ChainingTable from 'business/shift-report/report/pages/ReportAggregated/components/chaining-table';
import RingTable from 'business/shift-report/report/pages/ReportAggregated/components/ring-table';
import { AggregatedReportType } from 'business/shift-report/report/types';
import {
  AggregatedShiftReportFragment,
  GetAggregatedIndicatorResultsQuery,
} from 'generated/graphql';

interface TablesProps {
  type: AggregatedReportType;
  aggregatedReport: AggregatedShiftReportFragment;
  nbDays: number;
  nbWeeks: number;
  indicators: Exclude<
    Exclude<
      GetAggregatedIndicatorResultsQuery,
      null | undefined
    >['calculateAggregatedIndicatorResults'],
    null | undefined
  >['data'];
}

export const Tables = ({
  type,
  aggregatedReport,
  nbDays,
  nbWeeks,
  indicators,
}: TablesProps) => {
  const indicatorItems =
    indicators && indicators.length > 0
      ? indicators.map((indicator) => {
          return {
            key: indicator.id,
            label:
              {
                [ILanguage.fr]: indicator.nameFr,
                [ILanguage.enGB]: indicator.nameEn,
                [ILanguage.enUS]: indicator.nameEn,
                [ILanguage.es]: indicator.nameEs,
              }[i18n.language] ?? indicator.nameEn,
            children: (
              <span>
                {indicator.value != null ? +indicator.value.toFixed(2) : '- '}
                {indicator.unit}
              </span>
            ),
          } satisfies DescriptionsItemType;
        })
      : [];

  return (
    <Flex gap={5} vertical className="tables">
      <RingTable
        type={type}
        report={aggregatedReport}
        nbDays={nbDays}
        nbWeeks={nbWeeks}
      />
      <ChainingTable
        type={type}
        report={aggregatedReport}
        nbDays={nbDays}
        nbWeeks={nbWeeks}
      />
      <Descriptions
        layout="horizontal"
        size="small"
        className="header-info"
        column={{ md: 1, lg: 2 }}
        items={indicatorItems}
      />
    </Flex>
  );
};
