import { ExportOutlined } from '@ant-design/icons';
import invariant from 'tiny-invariant';
import { useTranslation } from 'translations/hooks';
import { getLocale } from 'translations/locale';

import { useRestApiHeaders } from 'business/contextProviders/useApiHeaders';
import { useAppContext } from 'business/contextProviders/useAppContext';
import { useReportProdAnalyzeFilters } from 'business/shift-report/report-analyze/pages/production-analyze/providers/filters';
import { useReportProdAnalyzeEnabledSeriesFilter } from 'business/shift-report/report-analyze/pages/production-analyze/providers/series-filter';
import {
  GROUP_BY_TYPES_MAP,
  SerieMetadata,
} from 'business/shift-report/report-analyze/pages/production-analyze/types';
import { Module_Enum } from 'generated/graphql';
import { getFetchRange } from 'technical/range-filters/get-fetch-range';
import Button from 'ui/button';

import { useReportProdAnalyzeExport } from './use-report-prod-analyze-export';

export const ReportProdAnalyzeExportButton = ({
  shifts,
  teams,
}: {
  shifts: SerieMetadata[];
  teams: SerieMetadata[];
}) => {
  const { t } = useTranslation();
  const { currentConstructionSiteId } = useAppContext();
  const headers = useRestApiHeaders(Module_Enum.ShiftReport);
  invariant(currentConstructionSiteId, 'No constructionSite id');

  const filters = useReportProdAnalyzeFilters();
  const enabledSeriesFilters = useReportProdAnalyzeEnabledSeriesFilter();
  const { generateExport } = useReportProdAnalyzeExport();

  const fetchRange = getFetchRange(filters);

  const onClick = () => {
    generateExport({
      body: {
        constructionSiteId: currentConstructionSiteId,
        groupByType: filters.groupByType,
        seriesIds: enabledSeriesFilters[filters.groupByType],
        locale: getLocale(),
        seriesHeaders: Object.values(
          filters.groupByType === GROUP_BY_TYPES_MAP.shift ? shifts : teams,
        ).reduce<Record<string, string>>((acc, cur) => {
          acc[cur.id] = cur.name;
          return acc;
        }, {}),
        ...fetchRange,
      },
      headers,
    });
  };

  return (
    <Button icon={<ExportOutlined />} onClick={onClick}>
      {t('common.export')}
    </Button>
  );
};
