import { DescriptionsItemType } from 'antd/es/descriptions';
import { Descriptions } from 'antd/lib';
import { useTranslation } from 'translations/hooks';
import { getLocale } from 'translations/locale';

import { SECONDARY_MAX_DECIMAL_DIGITS } from 'business/constants';
import { calculAverage } from 'business/shift-report/report/pages/ReportAggregated/services';
import { AggregatedReportType } from 'business/shift-report/report/types';
import { AggregatedShiftReportFragment } from 'generated/graphql';
import { createNumberFormatter } from 'technical/format/number';
import { notEmpty } from 'technical/utils/not-empty';

interface ChainingTableProps {
  type: AggregatedReportType;
  report: AggregatedShiftReportFragment;
  nbDays: number;
  nbWeeks: number;
}

function displayAverage(average: number, distanceUnit: string) {
  return Number.isNaN(average) ? 'N/A' : `${average}${distanceUnit}`;
}

export default function ChainingTable(props: ChainingTableProps) {
  const { type, report, nbDays, nbWeeks } = props;
  const { t } = useTranslation();

  const locale = getLocale();

  const numberFormatter = createNumberFormatter(
    locale,
    SECONDARY_MAX_DECIMAL_DIGITS,
  );

  const distanceItems = [
    {
      key: 'startMetricPoint',
      label: t('pages.print.startMetricPoint'),
      children: <span>{report.startMetricPoint}</span>,
      className: 'item',
    },
    {
      key: 'endMetricPoint',
      label: t('pages.print.endMetricPoint'),
      children: <span>{report.endMetricPoint}</span>,
      className: 'item',
    },
    {
      key: 'totalDistance',
      label: t('pages.print.totalDistance'),
      children: (
        <span>
          {`${numberFormatter.format(report.distance)} ${report.distanceUnit}`}
        </span>
      ),
      className: 'item',
    },
  ] satisfies DescriptionsItemType[];

  const averageDistanceItems = [
    type === 'week' || type === 'month'
      ? {
          key: 'averageDistancePerDay',
          label: t('pages.print.averageDistancePerDay'),
          children: (
            <span>
              {displayAverage(
                calculAverage(report.distance, nbDays),
                report.distanceUnit,
              )}
            </span>
          ),
          className: 'item',
        }
      : undefined,
    type === 'month'
      ? {
          key: 'averageDistancePerWeek',
          label: t('pages.print.averageDistancePerWeek'),
          children: (
            <span>
              {displayAverage(
                calculAverage(report.distance, nbWeeks),
                report.distanceUnit,
              )}
            </span>
          ),
          className: 'item',
        }
      : undefined,
  ].filter(notEmpty) satisfies DescriptionsItemType[];

  return (
    <div>
      <Descriptions items={distanceItems} column={{ md: 1, lg: 3 }} />
      {type === 'week' || type === 'month' ? (
        <Descriptions
          items={averageDistanceItems}
          column={{ md: 1, lg: type === 'month' ? 2 : 1 }}
        />
      ) : null}
    </div>
  );
}
