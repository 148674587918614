import { Alert } from 'antd';
import { useTranslation } from 'translations/hooks';

import { useAppContext } from 'business/contextProviders/useAppContext';
import {
  LastKnownState,
  State,
} from 'business/dashboard/components/last-known-state';
import { GetTbmInfosQuery, GetTbmInfosQueryResult } from 'generated/graphql';
import { useMediaType } from 'technical/media/hooks';
import { isUndefinedOrNull } from 'technical/utils/is-null-or-undefined';
import Card from 'ui/card';
import Loader from 'ui/loader';

interface Props {
  tbmInfos: GetTbmInfosQueryResult;
}

const getState = (data: GetTbmInfosQuery) => {
  const isExcavating = data.constructionSite[0].metrics.at(0)?.isExcavating;
  const isBuilding = data.constructionSite[0].metrics.at(0)?.isBuilding;

  if (isExcavating === true && isBuilding === false) {
    return State.excavating;
  }
  if (isExcavating === false && isBuilding === true) {
    return State.building;
  }

  return State.stopped;
};

export const TunnelBoringMachineHeader = ({
  tbmInfos: { data, error, loading },
}: Props) => {
  const { t } = useTranslation();

  const { currentConstructionSite } = useAppContext();
  const { isTablet } = useMediaType();

  if (error) {
    return (
      <>
        <Card.Header title={'TBM'} />
        <Alert message={t('errors.error_generic')} type="error" />
      </>
    );
  }

  if (loading || isUndefinedOrNull(data)) {
    return (
      <>
        <Card.Header title={'TBM'} />
        <Loader />
      </>
    );
  }

  const timeZone = data.constructionSite.at(0)?.timeZone;

  const state = getState(data);

  return (
    <Card.Header
      title={currentConstructionSite?.tunnelBoringMachineName ?? 'TBM'}
      actions={<LastKnownState timeZone={timeZone} state={state} />}
      vertical={isTablet}
    />
  );
};
