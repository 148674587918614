import { nameWithPreviousActivityName } from 'business/shift-report/activity/services';
import {
  abscissaScale,
  ordinalScale,
} from 'business/shift-report/report/components/ReportChart/services/axis';
import { TaskFullFragment } from 'generated/graphql';
import { shadeColor } from 'technical/utils/converter';

import { VERTICAL_SPACE, taskToValidatePattern } from './chart';
import { getWidth, getXPos, getYPos } from './svg';

// any instead of TaskFullFragment because d3.select.data
// do not accept a typed value in keyFunction
export const taskKeyFunction = ({ id }: any) => id;

// Task Meta
export const getTaskXPos =
  (xScale: abscissaScale) =>
  ({ startDate }: TaskFullFragment) =>
    getXPos(xScale, startDate);

export const getTaskYPos =
  (yScale: ordinalScale) =>
  ({ activity }: TaskFullFragment) =>
    getYPos(yScale, nameWithPreviousActivityName(activity).join(' / ')) +
    VERTICAL_SPACE / 2;

export const getTaskWidth =
  (xScale: abscissaScale) =>
  ({ startDate, endDate }: TaskFullFragment) =>
    getWidth(xScale, startDate, endDate);

export const getTaskHeight = (yScale: ordinalScale) => () =>
  yScale.step() - VERTICAL_SPACE;

export const getTaskStyle = ({
  activity,
  critical,
  isValidated,
}: TaskFullFragment) => {
  if (!isValidated) {
    return `fill:${taskToValidatePattern};stroke:${shadeColor(
      activity.color,
      -30,
    )};stroke-width:2`;
  }

  if (critical) {
    return `fill:${activity.color};stroke:${shadeColor(
      activity.color,
      -30,
    )};stroke-width:2`;
  }
  return `fill:${activity.secondaryColor};stroke:${shadeColor(
    activity.secondaryColor,
    -30,
  )};stroke-width:2`;
};
