import { i18n } from 'translations';

import {
  abscissaScale,
  ordinalScale,
} from 'business/shift-report/report/components/ReportChart/services/axis';
import { TaskFullFragment } from 'generated/graphql';

import { VERTICAL_SPACE } from './chart';
import { getTaskXPos, getTaskWidth, getTaskYPos } from './task';

// Task Text Meta
export const getTaskTextXPos =
  (xScale: abscissaScale) => (task: TaskFullFragment) =>
    getTaskXPos(xScale)(task) + getTaskWidth(xScale)(task) / 2;

export const getTaskTextYPos =
  (yScale: ordinalScale) => (task: TaskFullFragment) =>
    getTaskYPos(yScale)(task) + (yScale.step() - VERTICAL_SPACE) / 2;

export const getTaskTextValue = (task: TaskFullFragment) =>
  task.activity.showRingNumber
    ? i18n.t('chart.task.ring', { ring: task.ring })
    : '';

export const getTaskTextTransformations =
  (xScale: abscissaScale, yScale: ordinalScale) => (task: TaskFullFragment) => {
    const position = {
      x: getTaskTextXPos(xScale)(task),
      y: getTaskTextYPos(yScale)(task),
    };

    return `rotate(-45, ${position.x}, ${position.y})`;
  };
