import { useState } from 'react';
import invariant from 'tiny-invariant';

import { useGraphqlApiHeaders } from 'business/contextProviders/useApiHeaders';
import { useAppContext } from 'business/contextProviders/useAppContext';
import { mapPlotterModeToGraphSetType } from 'business/data-analysis/constants';
import { DataAnalysePrintTitle } from 'business/data-analysis/pages/graph/components/data-analyse-print-title';
import { FilterCardByMode } from 'business/data-analysis/pages/graph/components/filter-card';
import { useFilters } from 'business/data-analysis/pages/graph/components/filter-card/hooks/use-filters';
import { GraphList } from 'business/data-analysis/pages/graph/components/graph-list';
import { GraphListSidebar } from 'business/data-analysis/pages/graph/components/graph-list-sidebar';
import { GraphPageFooter } from 'business/data-analysis/pages/graph/components/page-footer';
import { GraphPageHeader } from 'business/data-analysis/pages/graph/components/page-header';
import { ParameterSidebar } from 'business/data-analysis/pages/graph/components/parameter-sidebar';
import SaveSidebar from 'business/data-analysis/pages/graph/components/save-side-bar';
import { useMode } from 'business/data-analysis/pages/graph/hooks/use-mode';
import { Module_Enum, useGetSavedGraphCountQuery } from 'generated/graphql';
import Flex from 'ui/flex';

import styles from './index.module.scss';

export const DataAnalysisGraph = () => {
  const mode = useMode();

  const { currentConstructionSiteId, user } = useAppContext();
  const headers = useGraphqlApiHeaders(Module_Enum.DataAnalysis);
  invariant(user && currentConstructionSiteId, 'No construction site id');

  const { filters, onChangeFilters } = useFilters(mode);

  const [graphListSideBarVisible, setGraphListSideBarVisible] = useState(false);
  const [saveSidebarVisible, setSaveSidebarVisible] = useState(false);

  const {
    data: graphSetCountResult,
    loading,
    refetch: refetchGraphSetCount,
  } = useGetSavedGraphCountQuery({
    variables: {
      constructionSiteId: currentConstructionSiteId,
      userId: user.id,
      type: mapPlotterModeToGraphSetType[mode],
    },
    context: {
      headers,
    },
  });

  return (
    <Flex className={styles.page}>
      <ParameterSidebar />
      <SaveSidebar
        visible={saveSidebarVisible}
        setVisible={setSaveSidebarVisible}
        onCloseSaveSideBar={() => setSaveSidebarVisible(false)}
        refetchGraphSetCount={refetchGraphSetCount}
      />
      <GraphListSidebar
        visible={graphListSideBarVisible}
        onClose={() => setGraphListSideBarVisible(false)}
        refetchGraphSetCount={refetchGraphSetCount}
      />
      <Flex className={styles.container} column>
        <GraphPageHeader
          displaySavedGraphs={() => setGraphListSideBarVisible(true)}
          displaySavedGraphsButtonIsDisabled={
            graphSetCountResult?.graphSet_aggregate.aggregate?.count === 0 ||
            loading
          }
          setSaveSideBarVisible={setSaveSidebarVisible}
          filters={filters}
        />
        <Flex className={styles.content} column>
          <FilterCardByMode
            onChangeFilters={onChangeFilters}
            filters={filters}
          />
          <DataAnalysePrintTitle filters={filters} />
          <GraphList />
          <GraphPageFooter filters={filters} />
        </Flex>
      </Flex>
    </Flex>
  );
};
