import { Radio, Form, ConfigProvider, Flex, Space, Switch } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'translations/hooks';

import { usePermissions } from 'business/contextProviders/usePermissions';
import { ShiftOrCalendarSelector } from 'business/production-and-performance/components/shift-or-calendar-selector';
import {
  useProdAndPerfFilters,
  useProdAndPerfFiltersFormName,
} from 'business/production-and-performance/providers/prod-and-perf-filters-provider';
import { useGetCurrentRingQuery } from 'generated/graphql';
import NumberRangeInput from 'ui/form/number-range-input';
import { NumberRangeInputWithMessage } from 'ui/form/number-range-input-with-message';
import TemporalitySelectInput, {
  DateSelectionTypeEnum,
} from 'ui/form/temporality-select-input';

import styles from './index.module.scss';

export interface ProdAndPerfFiltersProps {
  dayModeFilterEnabled?: boolean;
  durationIntervalEnabled?: boolean;
  showTotalDurationEnabled?: boolean;
  withProjectionEnabled?: boolean;
  noDefaultFor?: DateSelectionTypeEnum[];
}

export const ProdAndPerfFilters = ({
  dayModeFilterEnabled,
  durationIntervalEnabled,
  showTotalDurationEnabled,
  withProjectionEnabled,
  noDefaultFor,
}: ProdAndPerfFiltersProps) => {
  const { t } = useTranslation();
  const formName = useProdAndPerfFiltersFormName();
  const filters = useProdAndPerfFilters();
  const [form] = Form.useForm();

  const { constructionSiteId } = usePermissions();
  const { data, error } = useGetCurrentRingQuery({
    variables: { constructionSiteId },
  });

  return (
    <ConfigProvider
      theme={{
        components: {
          Form: {
            itemMarginBottom: 0,
          },
        },
      }}
    >
      <Form layout="inline" form={form} name={formName} initialValues={filters}>
        <Form.Item
          layout="vertical"
          name="filterType"
          label={t('input.filterByOptions.label')}
        >
          <Radio.Group
            optionType="button"
            buttonStyle="solid"
            options={[
              {
                label: t('input.filterByOptions.date'),
                value: 'date',
              },
              {
                label: t('input.filterByOptions.ring'),
                value: 'ring',
              },
            ]}
          />
        </Form.Item>

        <Form.Item
          shouldUpdate={(prevValues, curValues) =>
            prevValues.filterType !== curValues.filterType
          }
          noStyle
        >
          {({ getFieldValue }) => {
            const filterType = getFieldValue('filterType');

            if (filterType === 'date') {
              return (
                <Flex>
                  <Form.Item
                    name="dateRange"
                    layout="vertical"
                    label={t('input.dateRange.label')}
                  >
                    <TemporalitySelectInput
                      defaultDate={dayjs()}
                      noDefaultFor={noDefaultFor}
                    />
                  </Form.Item>
                </Flex>
              );
            } else if (filterType === 'ring') {
              return (
                <NumberRangeInputWithMessage
                  name="ringRange"
                  label={t('input.ringRange.label')}
                  leftPlaceholder={t('input.ringRange.startPlaceholder')}
                  rightPlaceholder={t('input.ringRange.endPlaceholder')}
                  message={
                    error
                      ? t('dataAnalysis.errors.currentRingNumberError')
                      : t('dataAnalysis.labels.currentRingNumber', {
                          ringNumber:
                            data?.parameter_ring_aggregate.aggregate?.max
                              ?.ringNumber,
                        })
                  }
                />
              );
            }
            return null;
          }}
        </Form.Item>

        {dayModeFilterEnabled ? (
          <Form.Item
            label={t('input.filterByOptions.startOfDay')}
            layout="vertical"
            name="dayMode"
          >
            <ShiftOrCalendarSelector />
          </Form.Item>
        ) : null}

        {durationIntervalEnabled ? (
          <Form.Item
            name="durationInterval"
            layout="vertical"
            label={t(
              'productionAndPerformance.excavationBuildTime.filters.durationInterval.label',
            )}
            validateTrigger="onChange"
          >
            <NumberRangeInput
              allowClear
              leftInputProps={{
                min: 0,
                placeholder: t(
                  'productionAndPerformance.excavationBuildTime.filters.durationInterval.start',
                ),
              }}
              rightInputProps={{
                placeholder: t(
                  'productionAndPerformance.excavationBuildTime.filters.durationInterval.end',
                ),
              }}
              className={styles.durationInterval}
            />
          </Form.Item>
        ) : null}

        {showTotalDurationEnabled ? (
          <Space align="end">
            <Form.Item
              name="showTotalDurations"
              layout="vertical"
              label={t(
                'productionAndPerformance.excavationBuildTime.filters.toggleTotalAverages',
              )}
            >
              <Switch size="small" />
            </Form.Item>
          </Space>
        ) : null}

        {withProjectionEnabled ? (
          <Space align="end">
            <Form.Item
              name="withProjection"
              layout="vertical"
              label={t(
                'productionAndPerformance.planning.filters.withProjections',
              )}
            >
              <Switch size="small" />
            </Form.Item>
          </Space>
        ) : null}
      </Form>
    </ConfigProvider>
  );
};
