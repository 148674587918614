import { TFunction } from 'translations/hooks';

import { GraphMeta } from 'business/shift-report/task/pages/TasksAnalyzer/types';

import { getTaskName } from './utils';

interface PieChartData {
  id: string;
  activityName: string;
  activityNameExport: string;
  total: number;
}

interface AggregatedPieChartData {
  id: string;
  name: string;
  totalTime: number;
}

export function formatPieChartData(
  {
    t,
    isExportData = false,
  }: {
    t: TFunction;
    isExportData?: boolean;
  },
  chartRawData?: PieChartData[] | null,
) {
  if (!chartRawData) {
    return undefined;
  }
  const data = chartRawData.map((task) => [
    getTaskName(task, isExportData),
    task.total,
  ]);
  return [
    [
      t('tasksAnalyzer.pieChart.activies'),
      t('tasksAnalyzer.pieChart.duration'),
    ],
    ...data,
  ];
}

export function formatAggregatedPieChartData(
  t: TFunction,
  chartRawData?: AggregatedPieChartData[],
) {
  if (!chartRawData) {
    return undefined;
  }
  const data = chartRawData.map((task) => [task.name, task.totalTime]);
  return [
    [
      t('tasksAnalyzer.pieChart.activies'),
      t('tasksAnalyzer.pieChart.duration'),
    ],
    ...data,
  ];
}

export function computePieData(
  { analysisType, chartDateRawData, chartRingRawData }: GraphMeta,
  t: TFunction,
) {
  const chartRawData =
    analysisType === 'date' ? chartDateRawData : chartRingRawData;
  return {
    data: formatPieChartData({ t, isExportData: false }, chartRawData),
    exportData: formatPieChartData({ t, isExportData: true }, chartRawData),
    options: {},
  };
}
