import { i18n } from 'translations';

import { getActivityTotalTime } from 'business/shift-report/report/components/ReportChart/services/activity';
import { ordinalScale } from 'business/shift-report/report/components/ReportChart/services/axis';
import { TaskFullFragment } from 'generated/graphql';

import { getYPos } from './svg';
import { UsedActivity } from './types';

export const getActivityTotalXPos = (width: number) => () => width + 5;

export const getActivityTotalYPos =
  (yScale: ordinalScale) =>
  ({ names }: UsedActivity) =>
    getYPos(yScale, names.join(' / ')) + yScale.step() / 2;

export const getActivityTotalText =
  (tasks: TaskFullFragment[]) =>
  ({ id, names, hasChildActivity }: UsedActivity) => {
    const critical = getActivityTotalTime(id, tasks, true);
    const total = getActivityTotalTime(id, tasks);

    const hasTimeRegistered = critical !== 0 || total !== 0;
    const isTopLevelActivity = names.length === 1;

    // do not show totals
    if (isTopLevelActivity && hasChildActivity && !hasTimeRegistered) {
      return i18n.t('chart.total.task', {
        critical: '--',
        total: '--',
      }) as string;
    }

    return i18n.t('chart.total.task', { critical, total }) as string;
  };
