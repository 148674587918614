import { Descriptions } from 'antd';
import { DescriptionsItemType } from 'antd/es/descriptions';
import { useTranslation } from 'translations/hooks';

import { calculAverage } from 'business/shift-report/report/pages/ReportAggregated/services';
import { AggregatedReportType } from 'business/shift-report/report/types';
import { AggregatedShiftReportFragment } from 'generated/graphql';
import { notEmpty } from 'technical/utils/not-empty';

interface RingTableProps {
  type: AggregatedReportType;
  report: AggregatedShiftReportFragment;
  nbDays: number;
  nbWeeks: number;
}

function displayAverage(average: number) {
  return Number.isNaN(average) ? 'N/A' : average;
}

export default function RingTable(props: RingTableProps) {
  const { type, report, nbDays, nbWeeks } = props;
  const { t } = useTranslation();

  const ringItems = [
    {
      key: 'startRing',
      label: t('pages.print.startRing'),
      children: <span>{report.startRing}</span>,
      className: 'item',
    },
    {
      key: 'endRing',
      label: t('pages.print.endRing'),
      children: <span>{report.endRing}</span>,
      className: 'item',
    },
    {
      key: 'totalRing',
      label: t('pages.print.totalRing'),
      children: <span>{report.totalRing}</span>,
      className: 'item',
    },
  ] satisfies DescriptionsItemType[];

  const averageRingItems = [
    type === 'week' || type === 'month'
      ? {
          key: 'averageRingPerDay',
          label: t('pages.print.averageRingPerDay'),
          children: (
            <span>
              {displayAverage(calculAverage(report.totalRing, nbDays))}
            </span>
          ),
          className: 'item',
        }
      : undefined,
    type === 'month'
      ? {
          key: 'averageRingPerWeek',
          label: t('pages.print.averageRingPerWeek'),
          children: (
            <span>
              {displayAverage(calculAverage(report.totalRing, nbWeeks))}
            </span>
          ),
          className: 'item',
        }
      : undefined,
  ].filter(notEmpty) satisfies DescriptionsItemType[];

  return (
    <div>
      <Descriptions items={ringItems} column={{ md: 1, lg: 3 }} />
      {type === 'week' || type === 'month' ? (
        <Descriptions
          items={averageRingItems}
          column={{ md: 1, lg: type === 'month' ? 2 : 1 }}
        />
      ) : null}
    </div>
  );
}
