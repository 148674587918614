import { MenuLink } from 'ui/menu-link';

import styles from './index.module.scss';

interface Props {
  path?: string;
  alt?: string;
  small?: boolean;
}

export default function AppLogo({ path, alt, small }: Readonly<Props>) {
  return (
    <MenuLink path="/">
      <img
        className={small ? styles.smallAppLogo : styles.appLogo}
        src={path ?? '/appLogo.svg'}
        alt={alt ?? 'logo'}
      />
    </MenuLink>
  );
}
