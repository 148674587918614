import { EyeOutlined } from '@ant-design/icons';
import { Flex, Result } from 'antd';
import classNames from 'classnames';
import { useParams, generatePath, Link } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { useTranslation } from 'translations/hooks';

import ExitReportButton from 'business/shift-report/report/components/exit-report-button';
import Routes from 'config/routes';
import Button from 'ui/button';
import CapCard from 'ui/cap-card';

import icon from './endReportIcon.svg';
import styles from './index.module.scss';

function ReportEditEndPage() {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  invariant(id, "id isn't set within the route");
  return (
    <Flex
      justify="center"
      className={classNames('main-bg-image', styles.endReportResultContainer)}
    >
      <CapCard
        className={styles.card}
        title={t('pages.report.chart.operatorValidation.validatedTitle')}
      >
        <Result
          icon={
            <img className={styles.icon} src={icon} alt="result success icon" />
          }
          extra={[
            <Link
              to={generatePath(Routes.ReportView, {
                id,
              })}
            >
              <Button key="primary" type="primary" icon={<EyeOutlined />}>
                {t('pages.report.chart.operatorValidation.validatedButton')}
              </Button>
            </Link>,
            <ExitReportButton
              managerRoute={Routes.ReportDashboardVisualize}
              key="secondary"
              type="primary"
              ghost
            />,
          ]}
        />
      </CapCard>
    </Flex>
  );
}

export default ReportEditEndPage;
