import { HistoryOutlined } from '@ant-design/icons';
import { Flex, message } from 'antd';
import classNames from 'classnames';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'translations/hooks';

import { useAppContext } from 'business/contextProviders/useAppContext';
import { usePermissions } from 'business/contextProviders/usePermissions';
import NewReportForm from 'business/shift-report/report/components/NewReportForm';
import ReportCard from 'business/shift-report/report/components/ReportCard';
import MaintenanceAlert from 'business/user/components/maintenanceAlert';
import Routes from 'config/routes';
import { useGetPreviousReportQuery } from 'generated/graphql';
import Button from 'ui/button';
import { MainPageButton } from 'ui/button/main-page-button';
import Loader from 'ui/loader';

import styles from './index.module.scss';

function ReportCreatePage() {
  const { t } = useTranslation();
  const { currentConstructionSite } = useAppContext();
  const {
    modules: {
      SHIFT_REPORT: { hasManagerReadPermission },
    },
  } = usePermissions();

  const { loading, error, data, refetch } = useGetPreviousReportQuery({
    variables: {
      constructionSiteId: currentConstructionSite?.id,
    },
    fetchPolicy: 'network-only',
    skip: !currentConstructionSite?.id,
  });

  useEffect(
    function alertUser() {
      if (error) {
        message.error(error.message);
      }
    },
    [error],
  );

  const lastReport =
    data?.shiftReport && data.shiftReport.length > 0
      ? data.shiftReport[0]
      : undefined;

  return (
    <Flex
      flex={1}
      vertical
      justify="space-around"
      gap={20}
      className={classNames(
        styles.reportCreatePage,
        'main-bg-image',
        'page-appear',
      )}
    >
      <MaintenanceAlert />
      {loading && <Loader />}
      {!loading && (
        <>
          <NewReportForm previousReport={lastReport} />
          {lastReport && (
            <ReportCard
              report={lastReport}
              refetchList={refetch}
              routeRedirection={Routes.ReportCreate}
            />
          )}

          <Link to={{ pathname: Routes.ReportList }} className="button-center">
            <Button type="primary" icon={<HistoryOutlined />}>
              {t('pages.report.new.openReportHistory')}
            </Button>
          </Link>
          {hasManagerReadPermission ? (
            <MainPageButton to={Routes.ReportDashboardVisualize} />
          ) : null}
        </>
      )}
    </Flex>
  );
}

export default ReportCreatePage;
