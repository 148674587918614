import { Row, Col, Statistic } from 'antd';
import { useTranslation } from 'translations/hooks';
import { getLocale } from 'translations/locale';

import { createNumberFormatter } from 'technical/format/number';
import { isUndefinedOrNull } from 'technical/utils/is-null-or-undefined';

interface RingPerDayAveragesProps {
  averagePerDay: string | null;
  averagePerDayAndShift: string | null;
  dayMode: 'shift' | 'calendar' | undefined;
}

export const RingPerDayAverages = ({
  averagePerDayAndShift,
  averagePerDay,
  dayMode,
}: RingPerDayAveragesProps) => {
  const { t } = useTranslation();

  const locale = getLocale();

  const numberFormatter = createNumberFormatter(locale);

  return (
    <Row gutter={8}>
      <Col span={6}>
        <Statistic
          title={t(
            'productionAndPerformance.ringPerDay.averages.averagePerDay',
          )}
          value={
            isUndefinedOrNull(averagePerDay)
              ? 0
              : numberFormatter.format(parseFloat(averagePerDay))
          }
        />
      </Col>
      {dayMode === 'shift' ? (
        <Col span={6}>
          <Statistic
            title={t(
              'productionAndPerformance.ringPerDay.averages.averagePerDayAndShift',
            )}
            value={
              isUndefinedOrNull(averagePerDayAndShift)
                ? 0
                : numberFormatter.format(parseFloat(averagePerDayAndShift))
            }
          />
        </Col>
      ) : null}
    </Row>
  );
};
