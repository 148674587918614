import { DatePicker, Flex, Form, InputNumber, Select } from 'antd';
import { FormInstance } from 'antd/es/form/hooks/useForm';
import { useTranslation } from 'translations/hooks';

import { useAppContext } from 'business/contextProviders/useAppContext';
import { notEmpty } from 'technical/utils/not-empty';
import { DecimalInput } from 'ui/form/decimal-input';
import { DurationInput } from 'ui/form/duration-input';

import { UpdateRingFormValues } from './types';

interface UpdateRingFormProps {
  form: FormInstance<UpdateRingFormValues>;
  save: (row: UpdateRingFormValues) => void;
}

export const UpdateRingForm: React.FC<UpdateRingFormProps> = ({
  form,
  save,
}) => {
  const { t } = useTranslation();

  const { currentConstructionSite } = useAppContext();

  const editableLength =
    notEmpty(currentConstructionSite?.ringLengthCode0) &&
    notEmpty(currentConstructionSite?.ringLengthCode1);

  const lengthOptions = editableLength
    ? [
        {
          label: currentConstructionSite?.ringLengthCode0,
          value: 1,
        },
        {
          label: currentConstructionSite?.ringLengthCode1,
          value: 2,
        },
      ]
    : [];

  return (
    <Form<UpdateRingFormValues> form={form} layout="vertical" onFinish={save}>
      <Form.Item<UpdateRingFormValues>
        name="keyPosition"
        label={t('productionAndPerformance.ring.table.keyPosition')}
        rules={[{ required: true }]}
      >
        <InputNumber
          min={1}
          prefix={currentConstructionSite?.keyPositionPrefix}
          max={currentConstructionSite?.nbKeyPosition ?? undefined}
        />
      </Form.Item>
      <Form.Item<UpdateRingFormValues>
        name="length"
        label={t('productionAndPerformance.ring.table.length')}
      >
        <Select
          options={lengthOptions}
          disabled={!editableLength}
          allowClear={false}
        />
      </Form.Item>
      <Form.Item<UpdateRingFormValues>
        name="buildEndMetricPoint"
        label={t('productionAndPerformance.ring.table.buildEndMetricPoint')}
        rules={[{ required: true }]}
      >
        <DecimalInput min={0} style={{ width: '100%' }} step={0.001} />
      </Form.Item>

      <Flex gap={8}>
        <Form.Item<UpdateRingFormValues>
          name="excavationStart"
          label={t('productionAndPerformance.ring.table.excavationStart')}
          style={{ flex: 1 }}
        >
          <DatePicker
            style={{ width: '100%' }}
            showTime={{ format: 'HH:mm' }}
            format="L HH:mm"
            allowClear={false}
          />
        </Form.Item>
        <Form.Item<UpdateRingFormValues>
          name="excavationEnd"
          label={t('productionAndPerformance.ring.table.excavationEnd')}
          style={{ flex: 1 }}
        >
          <DatePicker
            style={{ width: '100%' }}
            showTime={{ format: 'HH:mm' }}
            format="L HH:mm"
            allowClear={false}
          />
        </Form.Item>
      </Flex>
      <Form.Item<UpdateRingFormValues>
        name="realExcavationDuration"
        label={t('productionAndPerformance.ring.table.realExcavationDuration')}
      >
        <DurationInput />
      </Form.Item>

      <Flex gap={8}>
        <Form.Item<UpdateRingFormValues>
          style={{ flex: 1 }}
          name="buildStart"
          label={t('productionAndPerformance.ring.table.buildStart')}
        >
          <DatePicker
            style={{ width: '100%' }}
            showTime={{ format: 'HH:mm' }}
            format="L HH:mm"
            allowClear={false}
          />
        </Form.Item>
        <Form.Item<UpdateRingFormValues>
          style={{ flex: 1 }}
          name="buildEnd"
          label={t('productionAndPerformance.ring.table.buildEnd')}
        >
          <DatePicker
            style={{ width: '100%' }}
            showTime={{ format: 'HH:mm' }}
            format="L HH:mm"
            allowClear={false}
          />
        </Form.Item>
      </Flex>
      <Form.Item
        name={['realBuildDuration']}
        label={t('productionAndPerformance.ring.table.realBuildDuration')}
      >
        <DurationInput />
      </Form.Item>
    </Form>
  );
};
