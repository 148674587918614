import { DownloadOutlined } from '@ant-design/icons';
import { Flex, Form, message } from 'antd';
import classNames from 'classnames';
import { i18n } from 'translations';
import { useTranslation } from 'translations/hooks';

import { useAppContext } from 'business/contextProviders/useAppContext';
import config from 'config';
import Routes from 'config/routes';
import { useGenerateTaskExcelExportMutation } from 'generated/graphql';
import errorReporting from 'technical/error-reporting';
import { downloadFile } from 'technical/print';
import { SIMPLE_DATE_FORMAT } from 'technical/string/formatter';
import { requiredErrorAlert } from 'technical/validation/rules';
import Button from 'ui/button';
import { MainPageButton } from 'ui/button/main-page-button';
import CapCard from 'ui/cap-card';
import { DateRangePicker } from 'ui/form/date-range-picker';

import style from './index.module.scss';

interface FormValues {
  constructionSiteId?: string;
  picker?: any;
  range?: any;
}

function TaskExportPage() {
  const { t } = useTranslation();
  const { currentConstructionSite } = useAppContext();

  const [generateExcel] = useGenerateTaskExcelExportMutation();

  const onFinish = async (values: FormValues) => {
    try {
      if (!values.range[0] || !values.range[1]) {
        throw new Error('missing fields');
      }
      // Search for each report with current constructionSite, in values.date
      const generatedZip = await generateExcel({
        variables: {
          constructionSiteId: currentConstructionSite?.id,
          startDate: values.range[0].format(SIMPLE_DATE_FORMAT),
          endDate: values.range[1].format(SIMPLE_DATE_FORMAT),
          locale: i18n.language,
        },
      });
      if (!generatedZip.data?.generateExcelFromTaskRangeExport?.success) {
        message.error(t('pages.manager.taskExport.getExportFailed'), 15);
      }
      if (
        generatedZip.data?.generateExcelFromTaskRangeExport?.success &&
        !generatedZip.data?.generateExcelFromTaskRangeExport?.url
      ) {
        message.warning(t('pages.manager.taskExport.noFile'), 15);
      }
      if (generatedZip.data?.generateExcelFromTaskRangeExport?.url) {
        message.success(t('pages.manager.taskExport.getExportSuccess'));
        downloadFile(
          `${config.gcp.publicUri}/${generatedZip.data?.generateExcelFromTaskRangeExport.url}`,
          generatedZip.data?.generateExcelFromTaskRangeExport.url
            .split('/')
            .pop(),
        );
      }
    } catch (newTaskError) {
      message.error(t('pages.manager.taskExport.getExportFailed'), 15);
      if (newTaskError instanceof Error) {
        errorReporting.error(newTaskError);
      }
    }
  };

  return (
    <Flex
      vertical
      justify="center"
      className={classNames(
        style.taskExportPage,
        'main-bg-image',
        'page-appear',
      )}
    >
      <CapCard title={t('pages.manager.taskExport.title')}>
        <Form
          name="basic"
          layout="vertical"
          size="middle"
          onFinish={onFinish}
          onFinishFailed={requiredErrorAlert}
        >
          <Form.Item noStyle>
            <Form.Item
              label={t('pages.form.date.label')}
              name="range"
              rules={[{ required: true, message: t('pages.form.date.alert') }]}
            >
              <DateRangePicker className="input" picker="date" format="L" />
            </Form.Item>
          </Form.Item>
          <Form.Item className="button-center">
            <Button
              type="primary"
              htmlType="submit"
              icon={<DownloadOutlined />}
            >
              {t('pages.manager.taskExport.generate')}
            </Button>
          </Form.Item>
        </Form>
        <MainPageButton
          to={{ pathname: Routes.ReportDashboardAnalyze }}
          className="button-center"
        />
      </CapCard>
    </Flex>
  );
}

export default TaskExportPage;
