import { z } from 'zod';

import { dayjsSchema } from 'technical/type-schemas/dayjs';

const dayjsOrNullSchema = z.union([dayjsSchema, z.null()]);

export const dateRangeSchema = z.union([
  z.tuple([dayjsOrNullSchema, dayjsOrNullSchema]),
  z.null(),
]);
export type DateRangeType = z.infer<typeof dateRangeSchema>;

export type CamelToPascal<T extends string> =
  T extends `${infer P}${infer Rest}` ? `${Uppercase<P>}${Rest}` : never;

export type PascalToCamelRecord<T extends string> = Record<CamelToPascal<T>, T>;

export type SelfMapping<T extends Record<keyof T, keyof T>> = {
  [K in keyof T]: K;
};
