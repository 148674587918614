import { gql } from '@apollo/client';

export const ShiftReportCardInfoFragment = gql`
  fragment ShiftReportCardInfo on shiftReport {
    id
    operator {
      id
      name
    }
    shiftLeader {
      id
      name
    }
    team {
      id
      name
    }
    shiftEngineer {
      id
      email
      firstName
      lastName
    }
    shiftManager {
      id
      email
      firstName
      lastName
    }
    shift {
      id
      name
    }
    tasks_aggregate(where: { files: { _neq: [] } }) {
      files: aggregate {
        count(columns: files)
      }
    }
    date
    note
    startMetricPoint
    endMetricPoint
    lastFinishedRing
    operatorValidation
    managerValidation
    userId
    userName
  }
`;

export const DELETE_SHIFT_REPORT = gql`
  mutation DeleteShiftReport($id: uuid!) {
    delete_shiftReport_by_pk(id: $id) {
      id
    }
  }
`;
