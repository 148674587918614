import { message, Result } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'translations/hooks';

import { useAppContext } from 'business/contextProviders/useAppContext';
import { ReportListPageTemplate } from 'business/shift-report/report/components/report-list-template';
import ReportList, {
  DEFAULT_PAGE_LIMIT,
} from 'business/shift-report/report/components/ReportList';
import { useFilterParams } from 'business/shift-report/report/hooks/use-filter-params';
import Routes from 'config/routes';
import { useGetReportsBeingEditedQuery } from 'generated/graphql';
import { useMediaType } from 'technical/media/hooks';
import { MainPageButton } from 'ui/button/main-page-button';
import SectionTitle from 'ui/title/sectionTitle';

import styles from './index.module.scss';

function ReportListInProgressPage() {
  const { t } = useTranslation();
  const { currentConstructionSite } = useAppContext();
  const { isMobile } = useMediaType();

  const { currentPage } = useFilterParams();

  const { data, loading, error, refetch } = useGetReportsBeingEditedQuery({
    variables: {
      limit: DEFAULT_PAGE_LIMIT,
      offset: (currentPage - 1) * DEFAULT_PAGE_LIMIT,
      constructionSiteId: currentConstructionSite?.id,
    },
    fetchPolicy: 'network-only',
    skip: !currentConstructionSite?.id,
  });

  useEffect(
    function alertUser() {
      if (error) {
        message.error(error.message);
      }
    },
    [error],
  );

  const emptyListComponent = (
    <Result status="success" title={t('pages.manager.pending.done')} />
  );

  return (
    <ReportListPageTemplate>
      <SectionTitle
        label={t('pages.manager.pending.title')}
        className={styles.title}
        leftButton={
          isMobile ? null : (
            <MainPageButton
              to={{ pathname: Routes.ReportDashboardVisualize }}
            />
          )
        }
      />
      <ReportList
        loading={loading}
        refetchList={refetch}
        shiftReports={data?.shiftReports}
        paginationMeta={{
          shiftReportCount: data?.shiftReportAggregate.aggregate?.count || 0,
        }}
        emptyListComponent={emptyListComponent}
        routeRedirection={Routes.ReportListInProgress}
      />
      <MainPageButton
        to={Routes.ReportDashboardVisualize}
        className="button-center"
      />
    </ReportListPageTemplate>
  );
}

export default ReportListInProgressPage;
