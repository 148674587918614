import { PrinterOutlined } from '@ant-design/icons';
import { useTranslation } from 'translations/hooks';

import Button, { ButtonProps } from 'ui/button';

export const PrintButton = (
  props: Omit<ButtonProps, 'iconPosition' | 'icon'>,
) => {
  const { t } = useTranslation();

  return (
    <Button icon={<PrinterOutlined />} {...props}>
      {t('common.print')}
    </Button>
  );
};
