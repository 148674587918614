import { Alert, Empty } from 'antd';
import React from 'react';
import { useTranslation } from 'translations/hooks';

import { Performance } from 'business/dashboard/components/performance';
import { isUndefinedOrNull } from 'technical/utils/is-null-or-undefined';
import Loader from 'ui/loader';

import { usePerformanceMetrics } from './use-performance-metrics';

const PerformanceContainer: React.FC = () => {
  const { loading, error, metrics } = usePerformanceMetrics();
  const { t } = useTranslation();

  if (error) {
    return <Alert message={t('errors.error_generic')} type="error" />;
  }

  if (loading) {
    return <Loader />;
  }

  if (isUndefinedOrNull(metrics)) {
    return <Empty />;
  }

  return (
    <Performance
      dayWithMostRingsBuilt={metrics.dayWithMostRingsBuilt}
      weekWithMostRingsBuilt={metrics.weekWithMostRingsBuilt}
      monthWithMostRingsBuilt={metrics.monthWithMostRingsBuilt}
      fastestExcavation={metrics.fastestExcavation}
      fastestPose={metrics.fastestPose}
    />
  );
};

export default PerformanceContainer;
