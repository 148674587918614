import { Popover } from 'antd';
import { PopoverProps } from 'antd/es/popover';

interface MenuPopoverProps
  extends Pick<
    PopoverProps,
    | 'content'
    | 'children'
    | 'open'
    | 'onOpenChange'
    | 'placement'
    | 'overlayClassName'
    | 'overlayInnerStyle'
    | 'styles'
    | 'classNames'
  > {}

const MenuPopover = ({ children, ...otherProps }: MenuPopoverProps) => {
  return (
    <>
      <Popover autoAdjustOverflow trigger="click" arrow={false} {...otherProps}>
        {/**
         * A native html component is required as a direct children of Popover
         * cf doc: https://ant.design/components/popover#why-does-the-warning-finddomnode-is-deprecated-some-times-appear-in-strict-mode
         */}
        <div>{children}</div>
      </Popover>
    </>
  );
};

export default MenuPopover;
