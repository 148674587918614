import { UploadFile } from 'antd/lib/upload/interface';
import { Dayjs } from 'dayjs';
import zipWith from 'lodash.zipwith';

import { ACTIVITY_TYPES } from 'business/shift-report/activity/types';
import { NewTaskFormValues } from 'business/shift-report/task/types';
import {
  ActivityInvertedTreeFullFragment,
  TaskFullFragment,
} from 'generated/graphql';
import { formatFileListToFileUploadValue } from 'ui/form/fileUpload/services';
import { UploadMetadata } from 'ui/form/fileUpload/types';

import { parseTaskDateString } from './timeOperations';

export const getTaskActivityForCascader = (
  activity: ActivityInvertedTreeFullFragment,
): string[] => {
  let activitiesList: string[] = [];
  if (activity.parent) {
    if (activity.parent.parent) {
      if (activity.parent.parent.parent) {
        activitiesList = [activity.parent.parent.parent.id];
      }
      activitiesList = [...activitiesList, activity.parent.parent.id];
    }
    activitiesList = [...activitiesList, activity.parent.id];
  }
  activitiesList = [...activitiesList, activity.id];
  return activitiesList;
};

export const zipFileListWithUrlList = <T>(files: T[], urls: string[]) =>
  zipWith(files, urls, (taskFiles, url) => {
    return {
      ...taskFiles,
      url,
    };
  });

export const formatFileListToFileUploadWithUrl = (
  files: UploadMetadata[],
  fileUrls?: string[],
): UploadFile[] => {
  const formatedFiles = formatFileListToFileUploadValue(files);
  return formatedFiles.map((file) => ({
    ...file,
    url: fileUrls?.find((url) => decodeURI(url).includes(file.response)),
  }));
};

export const getInitialValues = ({
  task,
  taskTime,
  taskActivity,
  previousTask,
  previousRing,
  prefillStartDate,
  prefillEndDate,
}: {
  task?: TaskFullFragment;
  taskTime?: [string, string];
  taskActivity?: ActivityInvertedTreeFullFragment;
  previousTask?: TaskFullFragment;
  previousRing?: number;
  prefillStartDate?: Dayjs;
  prefillEndDate?: Dayjs;
}): NewTaskFormValues => {
  const defaultValues = {
    activity: undefined,
    startDate: undefined,
    endDate: undefined,
    ring: undefined,
    note: undefined,
    critical: true,
    ended: false,
  };
  // Editing a task
  if (task) {
    return {
      activity: getTaskActivityForCascader(task.activity),
      startDate: parseTaskDateString(task.startDate),
      endDate: parseTaskDateString(task.endDate),
      ring: task.ring,
      note: task.note,
      critical: task.critical,
      files: formatFileListToFileUploadValue(task.files),
      ended: task.ended,
    };
  }
  // Duplication
  if (taskActivity && previousTask) {
    return {
      ...defaultValues,
      activity: getTaskActivityForCascader(taskActivity),
      startDate: parseTaskDateString(previousTask.endDate),
      ring:
        previousTask.ended &&
        previousTask.activity.type === ACTIVITY_TYPES.BUILD
          ? previousTask.ring + 1
          : previousTask.ring,
    };
  }
  if (taskActivity) {
    return {
      ...defaultValues,
      activity: getTaskActivityForCascader(taskActivity),
    };
  }
  // Filling empty zone
  if (taskTime) {
    return {
      ...defaultValues,
      startDate: parseTaskDateString(taskTime[0]),
      endDate: parseTaskDateString(taskTime[1]),
    };
  }
  // New task
  if (previousTask) {
    return {
      ...defaultValues,
      startDate: parseTaskDateString(previousTask.endDate),
      endDate: prefillEndDate,
      ring:
        previousTask.ended &&
        previousTask.activity.type === ACTIVITY_TYPES.BUILD
          ? previousTask.ring + 1
          : previousTask.ring,
    };
  }
  // First task without previous ring (first shift of construction site of previous report not finished)
  if (previousRing !== undefined) {
    return {
      ...defaultValues,
      startDate: prefillStartDate,
      endDate: prefillEndDate,
      ring: previousRing + 1,
    };
  }

  // if not editing a task or no previous task
  return {
    ...defaultValues,
    startDate: prefillStartDate,
    endDate: prefillEndDate,
  };
};
