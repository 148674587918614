import { Affix, Flex } from 'antd';
import { Dayjs } from 'dayjs';

import { AddNewGraphButton } from 'business/data-analysis/pages/graph/components/add-new-graph-button';
import { GenerateGraphsButton } from 'business/data-analysis/pages/graph/components/generate-graphs-button';
import { useGraphSet } from 'business/data-analysis/pages/graph/hooks/graph-context/contexts';
import { NumberRangeValue } from 'ui/form/number-range-input';

import styles from './index.module.scss';

const ACTION_OFFSET = 20; // The padding of the page container

export const GraphPageFooter = ({
  filters,
}: {
  filters: {
    dateFilters: {
      startDate: Dayjs;
      endDate: Dayjs;
    };
    ringFilters: NumberRangeValue;
  };
}) => {
  const { structure } = useGraphSet();

  const atLeastOneParameterIsSelected = Array.from(
    structure.graphs.values(),
  ).some((graph) => graph.parameters.size > 0);

  return (
    <Flex
      justify="space-between"
      align="center"
      className={styles.btnContainer}
    >
      <Affix offsetBottom={ACTION_OFFSET}>
        {/**
         * A native html component is required as a direct children of the Affix
         * It is the same problem as the Popover but no info is present in the documentation
         * cf doc: https://ant.design/components/popover#why-does-the-warning-finddomnode-is-deprecated-some-times-appear-in-strict-mode
         */}
        <div>
          <GenerateGraphsButton
            atLeastOneParameterIsSelected={atLeastOneParameterIsSelected}
            filters={filters}
          />
        </div>
      </Affix>
      <AddNewGraphButton className={styles.centerBtn} />
    </Flex>
  );
};
