import { ReportProdAnalyzeGraphPoint } from 'business/shift-report/report-analyze/pages/production-analyze/types';

export const getEmptyReportProdAnalyzeGraphPoint = (
  date: Date,
): ReportProdAnalyzeGraphPoint => {
  return {
    date,
    count: 0,
  };
};
