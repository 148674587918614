import { CloseCircleFilled, SwapRightOutlined } from '@ant-design/icons';
import { InputNumber, Space, Typography } from 'antd';
import { InputNumberProps } from 'antd/lib/input-number';
import classNames from 'classnames';
import { z } from 'zod';

import { isUndefinedOrNull } from 'technical/utils/is-null-or-undefined';
import { notEmpty } from 'technical/utils/not-empty';

import styles from './index.module.scss';

const numberOrEmptySchema = z.union([z.number(), z.null(), z.undefined()]);

export const numberRangeSchema = z.union([
  z.tuple([numberOrEmptySchema, numberOrEmptySchema]),
  z.null(),
]);
export type NumberRangeValue = z.infer<typeof numberRangeSchema>;

export type NumberRangeInputProps = {
  value?: NumberRangeValue;
  onChange?: (value: NumberRangeValue) => void;
  allowClear?: boolean;
  leftInputProps?: InputNumberProps;
  leftTitle?: string;
  rightInputProps?: InputNumberProps;
  rightTitle?: string;
  errorMessage?: string;
  className?: string;
};

const ClearAllNumberRangeInput = ({
  allowClear,
  value,
  onClick,
}: {
  allowClear?: boolean;
  value?: NumberRangeValue;
  onClick?: (value: NumberRangeValue) => void;
}) => {
  if (!allowClear) {
    return <div className={styles.clearAllContainer} />;
  }

  const isValueDefined =
    value?.length == 2 &&
    (!isUndefinedOrNull(value.at(0)) || !isUndefinedOrNull(value.at(1)));

  const clearAll = isValueDefined ? (
    <CloseCircleFilled
      type="text"
      className={classNames(styles.clearAll)}
      onClick={() => {
        if (!value || !onClick) {
          return;
        }
        onClick([undefined, undefined]);
      }}
    />
  ) : null;

  return <div className={styles.clearAllContainer}>{clearAll}</div>;
};

function NumberRangeInput({
  value,
  onChange,
  leftInputProps,
  leftTitle,
  rightInputProps,
  rightTitle,
  errorMessage,
  className,
  allowClear,
}: Readonly<NumberRangeInputProps>) {
  const leftInputId = `leftInput-${leftTitle}`;
  const rightInputId = `rightInput-${rightTitle}`;

  return (
    <Space
      direction={'vertical'}
      className={classNames(styles.rangeInputContainer, className)}
    >
      <Space
        className={classNames(
          styles.rangeInput,
          styles.customPicker,
          //custom picker is duplicate of  'ant-input ant-picker ant-picker-range ant-picker-outlined',
        )}
      >
        <Space>
          {leftTitle ? (
            <label htmlFor={leftInputId}>
              <Typography.Text type="secondary">{leftTitle}</Typography.Text>
            </label>
          ) : null}
          <InputNumber
            className={styles.inputNumber}
            {...leftInputProps}
            variant={'borderless'}
            size={'small'}
            id={leftTitle ? leftInputId : undefined}
            value={notEmpty(value?.[0]) ? value[0] : undefined}
            onChange={(inputValue) => {
              if (!onChange) {
                return;
              }

              if (typeof inputValue !== 'string') {
                onChange([inputValue, value ? value[1] : undefined]);
                return;
              }
              onChange([undefined, value ? value[1] : undefined]);
            }}
          />
        </Space>
        <SwapRightOutlined
          className={
            styles.customPickerSeparator
            // duplicate of native ant class 'ant-picker-separator'
          }
        />
        <Space>
          {rightTitle ? (
            <label htmlFor={rightInputId}>
              <Typography.Text type="secondary">{rightTitle}</Typography.Text>
            </label>
          ) : null}
          <InputNumber
            {...rightInputProps}
            id={rightTitle ? rightInputId : undefined}
            value={notEmpty(value?.[1]) ? value[1] : undefined}
            variant={'borderless'}
            size={'small'}
            className={styles.inputNumber}
            onChange={(inputValue) => {
              if (!onChange) {
                return;
              }

              if (typeof inputValue !== 'string') {
                onChange([value ? value[0] : undefined, inputValue]);
                return;
              }
              onChange([value ? value[0] : undefined, undefined]);
            }}
            addonAfter={
              <ClearAllNumberRangeInput
                allowClear={allowClear}
                value={value}
                onClick={onChange}
              />
            }
          />
        </Space>
      </Space>
      {errorMessage ? (
        <Typography.Text type="danger">{errorMessage}</Typography.Text>
      ) : null}
    </Space>
  );
}

export default NumberRangeInput;
