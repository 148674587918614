import { MAX_DECIMAL_DIGITS } from 'business/constants';

/**
 * Utility function to create a number formatter based on language and formatting options
 * @param language locale or user locals
 */
export const createNumberFormatter = (
  language: string | string[],
  maxDecimalDigits: number = MAX_DECIMAL_DIGITS,
): Intl.NumberFormat => {
  return new Intl.NumberFormat(language, {
    maximumFractionDigits: maxDecimalDigits, // Limits the number of fraction digits to 3
  });
};
