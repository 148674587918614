import { App, TablePaginationConfig } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import { useEffect } from 'react';

import { useGraphqlApiHeaders } from 'business/contextProviders/useApiHeaders';
import { RingTableRow } from 'business/production-and-performance/pages/ring-table/types';
import { ProdAndPerfRangeFilters } from 'business/production-and-performance/providers/prod-and-perf-filters-provider/types';
import {
  Module_Enum,
  Parameter_Ring_Bool_Exp,
  useGetRingsQuery,
} from 'generated/graphql';
import { SORT_ORDER } from 'technical/graphql/sort-order';
import { validateRingRange } from 'technical/validation/rules';

export type TableParamsProps = Required<
  Pick<TablePaginationConfig, 'current' | 'pageSize'> & {
    sortField: SorterResult<RingTableRow>['field'];
    sortOrder: SorterResult<RingTableRow>['order'];
  }
>;

export const useGetFilteredAndPaginatedRingsQuery = (
  constructionSiteId: string,
  tableParams: TableParamsProps,
  filter: ProdAndPerfRangeFilters,
) => {
  const { message } = App.useApp();
  const headers = useGraphqlApiHeaders(Module_Enum.ProdPerf);

  const combinedFilters: Parameter_Ring_Bool_Exp = {};

  if (
    filter.filterType === 'ring' &&
    filter.ringRange &&
    validateRingRange(filter.ringRange)
  ) {
    const [min, max] = filter.ringRange;
    combinedFilters.ringNumber = {
      _gte: min === null ? undefined : min,
      _lte: max === null ? undefined : max,
    };
  }

  if (filter.filterType === 'date' && filter.dateRange) {
    const [min, max] = filter.dateRange;
    combinedFilters.buildEnd = {
      _gte: min === null ? undefined : min.toISOString(),
      _lte: max === null ? undefined : max.toISOString(),
    };
  }

  const { data, loading, error } = useGetRingsQuery({
    variables: {
      constructionSiteId,
      limit: tableParams.pageSize,
      offset: (tableParams.current - 1) * tableParams.pageSize,
      filters: combinedFilters,
      orderBy:
        tableParams.sortField && tableParams.sortOrder
          ? {
              [tableParams.sortField.toString()]:
                SORT_ORDER[tableParams.sortOrder],
            }
          : undefined,
    },
    context: {
      headers,
    },
  });

  useEffect(() => {
    if (error) {
      message.open({
        type: 'error',
        content: error.message,
      });
    }
  }, [error, message]);

  return { data, loading, error };
};
