import { AgChartOptions } from 'ag-charts-enterprise';
import { AgCharts } from 'ag-charts-react';

import { TopLevelTasksAggregate } from 'business/shift-report/report/pages/ReportAggregated/types';

interface AggregatedTaskPieChartProps {
  aggregatedTasks: TopLevelTasksAggregate[];
}

export const AggregatedTaskPieChart = ({
  aggregatedTasks,
}: AggregatedTaskPieChartProps) => {
  const chartOptions = {
    data: aggregatedTasks,
    series: [
      {
        type: 'pie',
        angleKey: 'total',
        legendItemKey: 'name',
      },
    ],
    width: 500,
    legend: {
      maxWidth: 500,
      position: 'right',
      item: {
        label: {
          formatter: ({ datum }) => {
            return `${datum.total}min - ${datum.name}`;
          },
        },
      },
    },
  } satisfies AgChartOptions;

  return <AgCharts options={chartOptions} />;
};
