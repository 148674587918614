import { ExportOutlined } from '@ant-design/icons';
import { App, Space } from 'antd';
import invariant from 'tiny-invariant';
import { useTranslation } from 'translations/hooks';
import { getLocale } from 'translations/locale';

import { useRestApiHeaders } from 'business/contextProviders/useApiHeaders';
import { useAppContext } from 'business/contextProviders/useAppContext';
import { usePermissions } from 'business/contextProviders/usePermissions';
import { useProdAndPerfFilters } from 'business/production-and-performance/providers/prod-and-perf-filters-provider';
import { useSingleGraphReferenceContext } from 'business/providers/graph-reference';
import config from 'config';
import { usePlanningControllerGeneratePlanningExport } from 'generated/apiComponents';
import { Module_Enum } from 'generated/graphql';
import { handlePrintWithChart } from 'technical/handle-print-with-chart';
import { downloadFile } from 'technical/print';
import { SIMPLE_DATE_FORMAT } from 'technical/string/formatter';
import Button from 'ui/button';
import { PrintButton } from 'ui/button/print-button';

import { ManagerControls } from './manager-controls';

export const PlanningHeader = () => {
  const { t } = useTranslation();
  const {
    isDataManager,
    modules: {
      PROD_PERF: { isConstructionSiteManager },
    },
  } = usePermissions();

  const { message } = App.useApp();
  const headers = useRestApiHeaders(Module_Enum.ProdPerf);

  const { currentConstructionSiteId } = useAppContext();
  invariant(currentConstructionSiteId, 'No constructionSite id');

  const filters = useProdAndPerfFilters();

  const { mutate: generateExport, isPending: isExportPending } =
    usePlanningControllerGeneratePlanningExport({
      onSuccess: (data) => {
        downloadFile(`${config.gcp.publicUri}/${data.url}`, data.fileName);
      },
      onError: () => {
        message.error(t('errors.export_download_error'));
      },
    });

  const onClick = () => {
    if (filters.filterType === 'date') {
      const [startDate, endDate] = filters.dateRange ?? [null, null];

      generateExport({
        body: {
          constructionSiteId: currentConstructionSiteId,
          startDate: startDate
            ? startDate.format(SIMPLE_DATE_FORMAT)
            : undefined,
          endDate: endDate ? endDate.format(SIMPLE_DATE_FORMAT) : undefined,
          withProjection: filters.withProjection ?? false,
          locale: getLocale(),
        },
        headers,
      });
    }
    if (filters.filterType === 'ring') {
      const [startRing, endRing] = filters.ringRange ?? [null, null];

      generateExport({
        body: {
          constructionSiteId: currentConstructionSiteId,
          startRing: startRing ?? undefined,
          endRing: endRing ?? undefined,
          withProjection: filters.withProjection ?? false,
          locale: getLocale(),
        },
        headers,
      });
    }
  };

  const { ref } = useSingleGraphReferenceContext();

  return (
    <Space>
      <PrintButton onClick={() => handlePrintWithChart(ref)} />
      <Button
        icon={<ExportOutlined />}
        loading={isExportPending}
        onClick={onClick}
      >
        {t('common.export')}
      </Button>
      {isConstructionSiteManager || isDataManager ? (
        <ManagerControls constructionSiteId={currentConstructionSiteId} />
      ) : null}
    </Space>
  );
};
