import { useTranslation } from 'translations/hooks';

import { useAppContext } from 'business/contextProviders/useAppContext';
import { SingleCardPageWithFilterCardLayout } from 'business/layout/single-card-page-with-filter-card';
import { ProdAndPerfFilters } from 'business/production-and-performance/components/prod-and-perf-filters';
import { ProdAndPerfPrintHeader } from 'business/production-and-performance/components/prod-and-perf-print-title';
import { ConstructionSitePlanningContainer } from 'business/production-and-performance/pages/planning-page/components/construction-site-planning-container';
import { PlanningHeader } from 'business/production-and-performance/pages/planning-page/components/construction-site-planning-header';
import { ProdAndPerfFiltersProvider } from 'business/production-and-performance/providers/prod-and-perf-filters-provider';
import { SingleGraphReferenceContextProvider } from 'business/providers/graph-reference';
import { isUndefinedOrNull } from 'technical/utils/is-null-or-undefined';
import { FilterCard } from 'ui/filter-card';
import { DateSelectionTypeEnum } from 'ui/form/temporality-select-input';

const PlanningPage = () => {
  const { t } = useTranslation();

  const { currentConstructionSiteId } = useAppContext();

  if (isUndefinedOrNull(currentConstructionSiteId)) {
    return null;
  }

  return (
    <SingleGraphReferenceContextProvider>
      <ProdAndPerfFiltersProvider
        initialFilters={{
          filterType: 'date',
          dateRange: [null, null],
          dayMode: 'shift',
          withProjection: true,
        }}
        noDefaultFor={[DateSelectionTypeEnum.Period]}
      >
        <SingleCardPageWithFilterCardLayout
          title={t('productionAndPerformance.planning.title')}
          header={<PlanningHeader />}
          filters={
            <FilterCard>
              <ProdAndPerfFilters
                withProjectionEnabled
                noDefaultFor={[DateSelectionTypeEnum.Period]}
              />
            </FilterCard>
          }
          printHeader={
            <ProdAndPerfPrintHeader
              printTitle={t('productionAndPerformance.planning.print.title')}
              printDayMode={false}
            />
          }
        >
          <ConstructionSitePlanningContainer />
        </SingleCardPageWithFilterCardLayout>
      </ProdAndPerfFiltersProvider>
    </SingleGraphReferenceContextProvider>
  );
};

export default PlanningPage;
