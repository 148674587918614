import { ApolloCache, FetchResult, MutationUpdaterFn } from '@apollo/client';
import { gql } from '@apollo/client';

import { TaskFragment } from 'business/shift-report/report/pages/ReportEdit/query.gql';
import {
  CreateNewTaskMutation,
  ExtendNextTaskMutation,
  ExtendPrevTaskMutation,
  UpdateExistingTaskMutation,
} from 'generated/graphql';
import logger from 'technical/logger';

type TaskFragmentUpdateMutation =
  | CreateNewTaskMutation
  | UpdateExistingTaskMutation
  | ExtendPrevTaskMutation
  | ExtendNextTaskMutation;
export const taskFragmentUpdate: MutationUpdaterFn<
  TaskFragmentUpdateMutation
> = (
  cache: ApolloCache<TaskFragmentUpdateMutation>,
  { data }: FetchResult<TaskFragmentUpdateMutation>,
) => {
  if (data?.task) {
    const { task } = data;
    const writtenRef = cache.writeFragment({
      id: cache.identify(task),
      fragment: TaskFragment,
      fragmentName: 'TaskFull',
      data: task,
    });
    if (writtenRef === undefined) {
      logger.warn('Cache update failed');
    }
  }
};

export const ADD_NEW_TASK = gql`
  mutation CreateNewTask($object: task_insert_input!) {
    task: insert_task_one(object: $object) {
      ...TaskFull
    }
  }
  ${TaskFragment}
`;

export const EDIT_TASK = gql`
  mutation UpdateExistingTask(
    $id: task_pk_columns_input!
    $input: task_set_input
  ) {
    task: update_task_by_pk(pk_columns: $id, _set: $input) {
      ...TaskFull
    }
  }
  ${TaskFragment}
`;

export const VALIDATE_ALL_TASKS = gql`
  mutation ValidateAllExistingTasks($taskIds: [uuid!]) {
    update_task_many(
      updates: {
        where: { id: { _in: $taskIds } }
        _set: { updatedAt: "now()" }
      }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_TASK = gql`
  mutation DeleteTask($id: uuid!) {
    delete_task_by_pk(id: $id) {
      id
    }
  }
  ${TaskFragment}
`;

export const UPDATE_TASK_FILES = gql`
  mutation UpdateTaskFiles($id: task_pk_columns_input!, $files: jsonb) {
    task: update_task_by_pk(pk_columns: $id, _set: { files: $files }) {
      ...TaskFull
    }
  }
`;

export const EXTEND_PREV_TASK = gql`
  mutation ExtendPrevTask(
    $id: task_pk_columns_input!
    $endDate: timestamptz
    $durationToAdd: Int
  ) {
    task: update_task_by_pk(
      pk_columns: $id
      _set: { endDate: $endDate }
      _inc: { duration: $durationToAdd }
    ) {
      ...TaskFull
    }
  }
  ${TaskFragment}
`;

export const EXTEND_NEXT_TASK = gql`
  mutation ExtendNextTask(
    $id: task_pk_columns_input!
    $startDate: timestamptz
    $durationToAdd: Int
  ) {
    task: update_task_by_pk(
      pk_columns: $id
      _set: { startDate: $startDate }
      _inc: { duration: $durationToAdd }
    ) {
      ...TaskFull
    }
  }
  ${TaskFragment}
`;

export const GET_TASK_FILES_URLS = gql`
  query GetTaskFilesUrls($fileNames: [String!]!) {
    filesUrls: createFilesDownloadTempUrls(fileNames: $fileNames) {
      urls
      success
    }
  }
`;

export const DELETE_NOT_VALIDATED_TASKS = gql`
  mutation DeleteNotValidatedTasks($shiftReportId: uuid!) {
    delete_task(
      where: {
        shiftReportId: { _eq: $shiftReportId }
        isValidated: { _eq: false }
      }
    ) {
      affected_rows
    }
  }
`;
