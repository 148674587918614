import { Select } from 'antd';
import { DEV_LANG_ENABLED, ILanguage } from 'translations';
import { useTranslation } from 'translations/hooks';

interface LanguageData {
  label: string;
  value: ILanguage;
  flag: string;
}

const AVAILABLE_LANGUAGES: LanguageData[] = [
  { label: 'UK', value: ILanguage.enGB, flag: '🇬🇧' },
  { label: 'US', value: ILanguage.enUS, flag: '🇺🇸' },
  { label: 'FR', value: ILanguage.fr, flag: '🇫🇷' },
  { label: 'ES', value: ILanguage.es, flag: '🇪🇸' },
  ...(DEV_LANG_ENABLED
    ? [{ label: 'Dev', value: ILanguage.dev, flag: '🛠️' }]
    : []),
];

const DEFAULT_LANGUAGE = AVAILABLE_LANGUAGES[0];

function getFlagDisplay(originalLang?: LanguageData) {
  const lang = originalLang ?? DEFAULT_LANGUAGE;
  return `${lang.flag} ${lang.label}`;
}

export const LanguageSelector = () => {
  const { language, changeLanguage } = useTranslation();

  return (
    <Select
      popupMatchSelectWidth={false}
      defaultValue={getFlagDisplay(
        AVAILABLE_LANGUAGES.filter((lang) => lang.value === language)[0],
      )}
      onChange={(value) => {
        changeLanguage(value);
      }}
      options={AVAILABLE_LANGUAGES.map((lang) => ({
        value: lang.value,
        label: `${lang.flag} ${lang.label}`,
      }))}
    />
  );
};
