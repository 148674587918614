import { message } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'translations/hooks';

import { zipFileListWithUrlList } from 'business/shift-report/task/services';
import { TaskFullFragment, useGetTaskFilesUrlsQuery } from 'generated/graphql';
import errorReporting from 'technical/error-reporting';
import logger from 'technical/logger';
import { UploadMetadata } from 'ui/form/fileUpload/types';
import Loader from 'ui/loader';

interface Props {
  task: TaskFullFragment;
}

function DisplayTaskFiles({ task }: Props) {
  const { t } = useTranslation();
  const { data, loading, error } = useGetTaskFilesUrlsQuery({
    variables: {
      fileNames: (task.files as UploadMetadata[]).map(
        ({ bucketFileName }) => bucketFileName,
      ),
    },
  });

  useEffect(
    function alertUser() {
      if (error) {
        message.error(t('errors.error_generic'));
        logger.error(error);
        errorReporting.error(error);
      }
    },
    [error, t],
  );

  if (!data?.filesUrls?.success) {
    return loading ? (
      <Loader />
    ) : (
      <p className="text-center">{t('pages.print.errors.filesDisplay')}</p>
    );
  }

  const fileList = zipFileListWithUrlList(
    task.files as UploadMetadata[],
    data.filesUrls.urls,
  );

  return (
    <>
      {fileList.map(({ url, name }) => (
        <div className="bloc" key={name}>
          <img className="additionnalImg" src={url} alt={name} />
        </div>
      ))}
    </>
  );
}

export default DisplayTaskFiles;
