import { useTranslation } from 'translations/hooks';

import { SingleCardPageWithFilterCardLayout } from 'business/layout/single-card-page-with-filter-card';
import { ProdAndPerfFilters } from 'business/production-and-performance/components/prod-and-perf-filters';
import { ProdAndPerfFiltersProvider } from 'business/production-and-performance/providers/prod-and-perf-filters-provider';
import { FilterCard } from 'ui/filter-card';
import { DateSelectionTypeEnum } from 'ui/form/temporality-select-input';

import { RingsTableContainer } from './components/ring-table-container';
import { RingTableHeader } from './components/ring-table-header';

export const RingTablePage = () => {
  const { t } = useTranslation();

  return (
    <ProdAndPerfFiltersProvider
      initialFilters={{
        filterType: 'date',
        dateRange: [null, null],
        // dayMode is not used for RingTable but required in the type by design
        dayMode: 'shift',
      }}
      noDefaultFor={[DateSelectionTypeEnum.Period]}
    >
      <SingleCardPageWithFilterCardLayout
        title={t('productionAndPerformance.ring.title')}
        filters={
          <FilterCard>
            <ProdAndPerfFilters noDefaultFor={[DateSelectionTypeEnum.Period]} />
          </FilterCard>
        }
        header={<RingTableHeader />}
      >
        <RingsTableContainer />
      </SingleCardPageWithFilterCardLayout>
    </ProdAndPerfFiltersProvider>
  );
};

export default RingTablePage;
