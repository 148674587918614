import { Flex, Typography } from 'antd';

import style from './index.module.scss';

export interface CardHeaderProps {
  title: string;
  actions?: React.ReactNode;
  vertical?: boolean;
}

export const CardHeader: React.FC<CardHeaderProps> = ({
  title,
  actions,
  vertical,
}) => {
  return (
    <Flex
      vertical={vertical}
      gap={8}
      justify="space-between"
      align="flex-start"
      wrap
      className={style.cardHeader}
    >
      <div className={style.cardTitle}>
        <Typography.Title className={style.title} level={5}>
          {title}
        </Typography.Title>
        <div className={style.underline} />
      </div>
      <div className={style.actions}>{actions}</div>
    </Flex>
  );
};
