import { Empty, Flex } from 'antd';
import dayjs from 'dayjs';
import invariant from 'tiny-invariant';

import { useRestApiHeaders } from 'business/contextProviders/useApiHeaders';
import { useAppContext } from 'business/contextProviders/useAppContext';
import graphContainerStyles from 'business/layout/single-card-page-with-filter-card/graph.module.scss';
import { useReportProdAnalyzeFilters } from 'business/shift-report/report-analyze/pages/production-analyze/providers/filters';
import { useReportProdAnalyzeEnabledSeriesFilter } from 'business/shift-report/report-analyze/pages/production-analyze/providers/series-filter';
import {
  GROUP_BY_TYPES_MAP,
  SerieMetadata,
} from 'business/shift-report/report-analyze/pages/production-analyze/types';
import { useProductionAnalyzeV1ControllerReportProductionAnalyze } from 'generated/apiComponents';
import { Module_Enum } from 'generated/graphql';
import { getFetchRange } from 'technical/range-filters/get-fetch-range';
import Loader from 'ui/loader';

import { ReportProdAnalyzeAverages } from './averages';
import { ReportProdAnalyzeGraph } from './graph';

export const ReportProdAnalyzeGraphContainer = ({
  shifts,
  teams,
}: {
  shifts: SerieMetadata[];
  teams: SerieMetadata[];
}) => {
  const { currentConstructionSiteId } = useAppContext();
  const headers = useRestApiHeaders(Module_Enum.ShiftReport);
  invariant(currentConstructionSiteId, 'No constructionSite id');

  const filters = useReportProdAnalyzeFilters();
  const fetchRange = getFetchRange(filters);

  const enabledSeriesFilters = useReportProdAnalyzeEnabledSeriesFilter();

  const { data, isLoading } =
    useProductionAnalyzeV1ControllerReportProductionAnalyze(
      {
        queryParams: {
          constructionSiteId: currentConstructionSiteId,
          groupByType: filters.groupByType,
          seriesIds: enabledSeriesFilters[filters.groupByType],
          ...fetchRange,
        },
        headers,
      },
      { enabled: !!fetchRange },
    );

  if (isLoading) {
    return (
      <div className={graphContainerStyles.loaderContainer}>
        <Loader />
      </div>
    );
  }

  if (!data || !data.graphData.length) {
    return <Empty />;
  }

  return (
    <Flex justify="space-between" vertical gap={24}>
      <ReportProdAnalyzeAverages
        averagePerDay={data.averagePerDay}
        averagePerDayAndShift={data.averagePerDayAndShift}
      />
      <div className={graphContainerStyles.graphContainer}>
        <ReportProdAnalyzeGraph
          availableSeries={
            filters.groupByType === GROUP_BY_TYPES_MAP.shift ? shifts : teams
          }
          reportProdAnalyzeGraphData={data.graphData.map(
            ({ date, _total, ...rest }) => ({
              ...rest,
              date: dayjs(date).toDate(),
            }),
          )}
        />
      </div>
    </Flex>
  );
};
