import { FileExcelOutlined } from '@ant-design/icons';
import { Alert, Descriptions, Divider, message, Typography } from 'antd';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Chart from 'react-google-charts';
import { useTranslation } from 'translations/hooks';

import { useAppContext } from 'business/contextProviders/useAppContext';
import TaskAnalyzerInputs from 'business/shift-report/task/pages/TasksAnalyzer/components/task-analyzer-inputs';
import {
  computeGraphData,
  getTotalAvailabilityFromChartData,
} from 'business/shift-report/task/pages/TasksAnalyzer/services/chartServices';
import {
  checkAllActivities,
  emptyChartData,
  shouldDisplayTotalAvailability,
  skipDateRangeChartData,
  skipRingRangeChartData,
  buildCheckableActivityTree,
} from 'business/shift-report/task/pages/TasksAnalyzer/services/inputServices';
import {
  parseCheckedKeys,
  parseStoredOptions,
} from 'business/shift-report/task/pages/TasksAnalyzer/services/local-storage-options';
import {
  CHART_TYPES,
  LocalStorageTaskAnalyzerOptions,
  localStorageTaskAnalyzerOptionsSchema,
  TaskAnalyzerQueryOptions,
} from 'business/shift-report/task/pages/TasksAnalyzer/types';
import config from 'config';
import Routes from 'config/routes';
import {
  ActivityMinimalTreeFragment,
  RingAggregationCriticalTaskEnum,
  RingAggregationGroupByEnum,
  ShiftReportCriticalTaskEnum,
  ShiftReportGroupByEnum,
  useGenerateExcelExportMutation,
  useGetRingAggregationTasksAnalyzerDataQuery,
  useGetShiftReportTasksAnalyzerDataQuery,
  useGetTasksAnalyzerMetadataQuery,
} from 'generated/graphql';
import errorReporting from 'technical/error-reporting';
import {
  getLocalStorageValue,
  insertOrUpdateLocalStorageValue,
} from 'technical/local-storage';
import { useMediaType } from 'technical/media/hooks';
import { downloadFile } from 'technical/print';
import { SIMPLE_DATE_FORMAT } from 'technical/string/formatter';
import AppLogo from 'ui/appLogo';
import HeaderRow from 'ui/BiHeader';
import Button from 'ui/button';
import { MainPageButton } from 'ui/button/main-page-button';
import { PrintButton } from 'ui/button/print-button';
import CheckableTree from 'ui/CheckableTree';
import Loader from 'ui/loader';

import styles from './index.module.scss';

const TASK_ANALYZE_SCOPE = 'taskAnalyze';

const handlePrint = () => {
  window.print();
};

export default function ChartEditor() {
  const { t, language } = useTranslation();
  const { currentConstructionSite } = useAppContext();
  const { isMobile, isTablet } = useMediaType();

  const name = currentConstructionSite?.name;
  const logo = currentConstructionSite?.logo;

  const [title, setTitle] = useState<string>(t('common.title'));

  const storedOptions = getLocalStorageValue<LocalStorageTaskAnalyzerOptions>({
    scope: TASK_ANALYZE_SCOPE,
    key: currentConstructionSite?.id,
    schema: localStorageTaskAnalyzerOptionsSchema,
  });

  const [checkedKeys, setCheckedKeys] = useState<string[]>(
    parseCheckedKeys(storedOptions) ?? [],
  );

  const defaultQueryOptions = useMemo(
    () =>
      ({
        analysisType: 'date',
        dateRangeValue: [dayjs().subtract(1, 'week'), dayjs()],
        ringRangeValue: null,
        chartType: CHART_TYPES[0].type,
        groupByType: ShiftReportGroupByEnum.Day,
        dateCriticalType: ShiftReportCriticalTaskEnum.Both,
        ringCriticalType: RingAggregationCriticalTaskEnum.Both,
        activityLevelAggregation: 1,
        vChartScale: null,
      }) satisfies TaskAnalyzerQueryOptions,
    [],
  );

  const [queryOptions, setQueryOptions] = useState<TaskAnalyzerQueryOptions>(
    parseStoredOptions(storedOptions) ?? defaultQueryOptions,
  );

  const {
    analysisType,
    dateRangeValue,
    ringRangeValue,
    chartType,
    groupByType,
    dateCriticalType,
    ringCriticalType,
    activityLevelAggregation,
    vChartScale,
  } = queryOptions;

  const updateQueryOptions = useCallback(
    (
      newOptions: TaskAnalyzerQueryOptions,
      currentStoredOptions: LocalStorageTaskAnalyzerOptions,
    ) => {
      setQueryOptions(newOptions);
      insertOrUpdateLocalStorageValue({
        scope: TASK_ANALYZE_SCOPE,
        key: currentConstructionSite?.id,
        value: { ...currentStoredOptions, queryOptions: newOptions },
      });
    },
    [currentConstructionSite?.id],
  );

  const updateCheckedKeys = useCallback(
    (
      newCheckedKeys: string[],
      currentStoredOptions: LocalStorageTaskAnalyzerOptions,
    ) => {
      setCheckedKeys(newCheckedKeys);
      insertOrUpdateLocalStorageValue({
        scope: TASK_ANALYZE_SCOPE,
        key: currentConstructionSite?.id,
        value: { ...currentStoredOptions, checkedKeys: newCheckedKeys },
      });
    },
    [currentConstructionSite],
  );

  const {
    data: taskAnalyzerMetadata,
    loading: taskAnalyzerMetadataLoading,
    error: taskAnalyzerMetadataError,
  } = useGetTasksAnalyzerMetadataQuery({
    variables: { constructionSiteId: currentConstructionSite?.id },
    skip: !currentConstructionSite?.id,
  });

  const { data: dateChartData, loading: dateChartDataLoading } =
    useGetShiftReportTasksAnalyzerDataQuery({
      variables: {
        constructionSiteId: currentConstructionSite?.id,
        startDate: dateRangeValue?.[0],
        endDate: dateRangeValue?.[1],
        groupBy:
          chartType === 'PieChart' ? ShiftReportGroupByEnum.Tasks : groupByType,
        activityList: checkedKeys,
        criticalType: dateCriticalType,
        activityLevelAggregation,
      },
      fetchPolicy: 'network-only',
      skip: skipDateRangeChartData({
        analysisType,
        activityList: checkedKeys,
        constructionSiteId: currentConstructionSite?.id,
        dateRangeValue,
      }),
    });

  const { data: ringChartData, loading: ringChartDataLoading } =
    useGetRingAggregationTasksAnalyzerDataQuery({
      variables: {
        constructionSiteId: currentConstructionSite?.id,
        // @ts-expect-error Ignore validation because query is skipped if data is null
        startRing: ringRangeValue?.[0],
        // @ts-expect-error Ignore validation because query is skipped if data is null
        endRing: ringRangeValue?.[1],
        groupBy:
          chartType === 'PieChart'
            ? RingAggregationGroupByEnum.Tasks
            : RingAggregationGroupByEnum.Ring,
        activityList: checkedKeys,
        criticalType: ringCriticalType,
        activityLevelAggregation,
      },
      fetchPolicy: 'network-only',
      skip: skipRingRangeChartData({
        analysisType,
        constructionSiteId: currentConstructionSite?.id,
        ringRangeValue,
      }),
    });
  const loading = dateChartDataLoading || ringChartDataLoading;

  const [generateExcel] = useGenerateExcelExportMutation();

  const {
    data: graphData,
    exportData: graphExportData,
    options,
  } = React.useMemo(() => {
    if (loading) {
      return { data: undefined, exportData: undefined, options: {} };
    }
    return computeGraphData(
      {
        chartType: queryOptions.chartType,
        groupByType:
          analysisType === 'date' ? queryOptions.groupByType : 'ring',
        analysisType,
        chartDateRawData: dateChartData?.tasksAnalyzerData,
        chartRingRawData: ringChartData?.tasksAnalyzerData,
        vChartScale,
      },
      t,
    );
  }, [
    queryOptions,
    dateChartData,
    ringChartData,
    analysisType,
    t,
    loading,
    vChartScale,
  ]);

  const displayedActivities = useMemo(() => {
    if (taskAnalyzerMetadata && taskAnalyzerMetadata.activity.length > 0) {
      return buildCheckableActivityTree(
        taskAnalyzerMetadata.activity,
        taskAnalyzerMetadata.hiddenActivitiesWithTasks.map(({ id }) => id),
      );
    }

    return [];
  }, [taskAnalyzerMetadata]);

  useEffect(() => {
    if (checkedKeys.length === 0) {
      updateCheckedKeys(
        checkAllActivities(
          displayedActivities as ActivityMinimalTreeFragment[],
        ),
        storedOptions,
      );
    }
  }, [
    checkedKeys.length,
    displayedActivities,
    storedOptions,
    updateCheckedKeys,
  ]);

  useEffect(() => {
    const currentStoredOptions =
      getLocalStorageValue<LocalStorageTaskAnalyzerOptions>({
        scope: TASK_ANALYZE_SCOPE,
        key: currentConstructionSite?.id,
        schema: localStorageTaskAnalyzerOptionsSchema,
      });

    setQueryOptions(defaultQueryOptions);
    setCheckedKeys(
      checkAllActivities(displayedActivities as ActivityMinimalTreeFragment[]),
    );

    if (currentStoredOptions) {
      const currentQueryOptions = parseStoredOptions(currentStoredOptions);
      if (currentQueryOptions) {
        setQueryOptions(currentQueryOptions);
      }

      const currentCheckedKeys = parseCheckedKeys(currentStoredOptions);
      if (currentCheckedKeys) {
        setCheckedKeys(currentCheckedKeys);
      }
    }
  }, [currentConstructionSite?.id, defaultQueryOptions, displayedActivities]);

  const handleExcelExport = async () => {
    try {
      if (graphData) {
        const jsonData = JSON.stringify(graphExportData);
        const { data } = await generateExcel({
          variables: {
            fileName: 'export',
            graphData: jsonData,
            locale: language,
          },
        });
        if (data?.generateExcelFile?.url && dateRangeValue) {
          downloadFile(
            data.generateExcelFile.url,
            `${currentConstructionSite?.name}_${dayjs
              .tz(dateRangeValue[0])
              .format('L')}>${dayjs.tz(dateRangeValue[1]).format('L')}.xlsx`,
          );
        }
      }
    } catch (excelGenerationError) {
      message.error(t('pages.manager.excelReport.fail'));
      if (excelGenerationError instanceof Error) {
        errorReporting.error(excelGenerationError);
      }
    }
  };

  const isChartDataEmpty = emptyChartData({
    analysisType,
    dateChartData,
    ringRangeValue,
    ringChartData,
    graphData,
  });

  return (
    <div className={styles.container}>
      <div className={styles.aside}>
        {isMobile ? null : (
          <>
            <MainPageButton
              to={{ pathname: Routes.ReportDashboardAnalyze }}
              className={styles.goBack}
            />
            <Divider />
          </>
        )}
        <div className={styles.tree}>
          <CheckableTree
            title={t('tasksAnalyzer.labels.activities')}
            data={displayedActivities}
            loading={taskAnalyzerMetadataLoading}
            error={taskAnalyzerMetadataError}
            defaultChecked={checkAllActivities(
              displayedActivities as ActivityMinimalTreeFragment[],
            )}
            checkedKeys={checkedKeys}
            setCheckedKeys={(keys) => updateCheckedKeys(keys, storedOptions)}
          />
        </div>
      </div>

      <div className={styles.body}>
        <div className={styles.printHeader}>
          <div className="header-left">
            <AppLogo />
            {logo && (
              <AppLogo
                alt="Construction site logo"
                path={`${config.gcp.publicUri}/${logo}`}
              />
            )}
          </div>
          <h1 id="pdfReportPrint">{name}</h1>
          {dateRangeValue && (
            <h2>
              {t('pages.print.rangeTitle', {
                startDate: dayjs(dateRangeValue[0], SIMPLE_DATE_FORMAT).format(
                  'L',
                ),
                endDate: dayjs(dateRangeValue[1], SIMPLE_DATE_FORMAT).format(
                  'L',
                ),
              })}
            </h2>
          )}
        </div>

        <div className={styles.tabBar}>
          <HeaderRow
            title={
              <Typography.Title
                level={3}
                editable={{
                  onChange: setTitle,
                }}
              >
                {title}
              </Typography.Title>
            }
            button1={
              <PrintButton
                className={styles.cta}
                onClick={handlePrint}
                disabled={isChartDataEmpty || isTablet}
              />
            }
            button2={
              <Button
                className={styles.cta}
                onClick={handleExcelExport}
                disabled={isChartDataEmpty}
                icon={<FileExcelOutlined />}
              >
                {t('common.export')}
              </Button>
            }
          />
          <TaskAnalyzerInputs
            queryOptions={queryOptions}
            setQueryOptions={(newQueryOptions) =>
              updateQueryOptions(newQueryOptions, storedOptions)
            }
          />
        </div>

        <div className={styles.graphContainer}>
          {loading && <Loader />}
          {!isChartDataEmpty && !loading && (
            <Chart
              height="350px"
              width={window.screen.width <= 1024 ? '500px' : '1024px'}
              chartType={chartType}
              loader={<Loader />}
              data={graphData}
              options={{
                ...options,
                title,
                maintainAspectRatio: false,
                chartArea: {
                  // Was set to 0 for task #129948 with "small graph fix" message
                  // but remove the left axis graduation, maybe need more check.
                  // left: 0,
                  width: chartType === 'PieChart' ? '100%' : '70%',
                },
                legend: {
                  maxLines: 2,
                  textStyle: {
                    fontSize: 11,
                  },
                },
              }}
              rootProps={{ 'data-testid': 'react-google-chart-1' }}
              chartPackages={['corechart', 'controls', 'charteditor']}
            />
          )}
          {shouldDisplayTotalAvailability({
            isChartDataEmpty,
            analysisType,
            chartType,
            dateCriticalType,
            ringCriticalType,
          }) && (
            <Descriptions layout="horizontal" size="small">
              <Descriptions.Item label={t('pages.print.totalAvailability')}>
                {getTotalAvailabilityFromChartData(
                  analysisType,
                  analysisType === 'date' ? queryOptions.groupByType : 'ring',
                  analysisType === 'date'
                    ? dateChartData?.tasksAnalyzerData
                    : ringChartData?.tasksAnalyzerData,
                )}
                %
              </Descriptions.Item>
            </Descriptions>
          )}
          {isChartDataEmpty && (
            <Alert
              showIcon
              message={t('tasksAnalyzer.errors.title')}
              type="warning"
            />
          )}
        </div>
      </div>
    </div>
  );
}
