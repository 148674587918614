import dayjs, { Dayjs } from 'dayjs';

export const dateSort = (a: Dayjs | Date, b: Dayjs | Date) =>
  dayjs.utc(a).unix() - dayjs.utc(b).unix();

export const objectWithDateSort = (
  a: {
    date: Dayjs | Date;
    [key: string]: unknown;
  },
  b: {
    date: Dayjs | Date;
    [key: string]: unknown;
  },
) => dateSort(a.date, b.date);
