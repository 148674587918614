import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Typography, Select, Popconfirm, Flex } from 'antd';
import { useTranslation } from 'translations/hooks';

import {
  useGraphSet,
  useGraphSetDispatch,
} from 'business/data-analysis/pages/graph/hooks/graph-context/contexts';
import { GraphSetDispatchActions } from 'business/data-analysis/pages/graph/hooks/graph-context/types';
import { useGraph } from 'business/data-analysis/pages/graph/hooks/use-graph';
import { displayTypes } from 'business/data-analysis/pages/graph/types';
import Button from 'ui/button';
import PencilIcon from 'ui/icons/pencil';
import TrashIcon from 'ui/icons/trash';

import styles from './index.module.scss';

interface Props {
  graphId: string;
  displayParameterList: boolean;
  toogleDisplayParameterList: () => void;
}

const GraphCardHeader = ({
  graphId,
  displayParameterList,
  toogleDisplayParameterList,
}: Props) => {
  const { t } = useTranslation();
  const graph = useGraph(graphId);
  const {
    structure: { graphs },
  } = useGraphSet();
  const dispatch = useGraphSetDispatch();

  const graphTypeOptions = [
    {
      value: displayTypes.graph,
      label: <span>{t('dataAnalysis.graphType.graph')}</span>,
    },
    {
      value: displayTypes.table,
      label: <span>{t('dataAnalysis.graphType.table')}</span>,
    },
  ];

  return (
    <Flex justify="space-between" className={styles.head}>
      <Typography.Text
        className={styles.cardTitle}
        editable={{
          icon: <PencilIcon className={styles.cardTitleAction} />,
          tooltip: t('dataAnalysis.graph.header.tooltip'),
          onChange: (title: string) =>
            dispatch({
              type: GraphSetDispatchActions.RenameGraph,
              graphId,
              title,
            }),
          triggerType: ['icon'],
        }}
      >
        {graph.title !== ''
          ? graph.title
          : t('dataAnalysis.labels.newGraph', {
              graphNumber: graph.graphNumber,
            })}
      </Typography.Text>

      <Flex
        justify="space-between"
        align="center"
        gap={10}
        className={styles.actionContainer}
      >
        <Button
          onClick={() => toogleDisplayParameterList()}
          icon={
            displayParameterList ? <EyeInvisibleOutlined /> : <EyeOutlined />
          }
        >
          {t('dataAnalysis.graph.header.parameterListToogle', {
            context: displayParameterList ? 'hide' : 'show',
          })}
        </Button>
        <Select
          className={styles.graphType}
          options={graphTypeOptions}
          value={graph.displayType}
          onChange={(displayType) =>
            dispatch({
              type: GraphSetDispatchActions.UpdateDisplayType,
              graphId,
              displayType,
            })
          }
        />

        <Popconfirm
          title={t('dataAnalysis.graph.header.delete.title')}
          okButtonProps={{ type: 'primary' }}
          placement="topRight"
          onConfirm={() =>
            dispatch({
              type: GraphSetDispatchActions.DeleteGraph,
              graphId: graphId,
            })
          }
        >
          <Button
            icon={<TrashIcon className={styles.actionIcon} />}
            disabled={graphs.size <= 1}
          />
        </Popconfirm>
      </Flex>
    </Flex>
  );
};

export default GraphCardHeader;
