import { Dayjs } from 'dayjs';
import { i18n } from 'translations';

import { getReportNextDateIfNightShift } from 'business/shift-report/report/services/timeOperations';
import {
  parseShiftTimeString,
  parseShiftTimeStringWithReportDate,
} from 'business/shift-report/shift/services/timeOperations';
import { ShiftFullFragment } from 'generated/graphql';

export const getShiftStartAndEndDate = (
  date: Dayjs,
  { startTime, endTime }: ShiftFullFragment,
) => {
  const shiftStartTime = parseShiftTimeString(startTime);
  const shiftEndTime = parseShiftTimeString(endTime);
  let onTwoDays = false;

  if (shiftStartTime.isAfter(shiftEndTime)) {
    onTwoDays = true;
  }

  const startDate = parseShiftTimeStringWithReportDate(startTime, date);
  const endDate = parseShiftTimeStringWithReportDate(
    endTime,
    onTwoDays ? getReportNextDateIfNightShift(date) : date,
  );

  return {
    startDate,
    endDate,
  };
};

export const getShiftTotalTime = (date: Dayjs, shift: ShiftFullFragment) => {
  const { startDate, endDate } = getShiftStartAndEndDate(date, shift);

  return endDate.diff(startDate, 'minute');
};

export const formatRingInvalidText = (ringsInvalid: string[]) => {
  if (ringsInvalid.length >= 2) {
    return i18n.t('common.and', {
      firstString: ringsInvalid.slice(0, -1).join(', '),
      secondString: ringsInvalid.slice(-1),
    });
  }
  return ringsInvalid[0];
};
