import { AgChartInstance, AgChartOptions } from 'ag-charts-enterprise';
import { RefObject } from 'react';

import { MAX_CHART_HEIGHT } from 'technical/chart-resize/constants';

const CHART_A4_WIDTH = 980;

const MULTIPLE_CHART_A4_HEIGHT = 400;

export const handlePrintWithChart = async (
  ref: RefObject<AgChartInstance<AgChartOptions>> | undefined,
  squareChart: boolean = false,
  maxHeight: number = MAX_CHART_HEIGHT,
) => {
  const chartElement = ref?.current?.getOptions().container;

  if (squareChart) {
    await ref?.current?.updateDelta({
      width: chartElement?.clientHeight,
      height: chartElement?.clientHeight,
    });
  } else {
    await ref?.current?.updateDelta({
      width: CHART_A4_WIDTH,
    });
  }

  window.print();

  if (squareChart) {
    await ref?.current?.updateDelta({
      width:
        chartElement?.clientWidth && chartElement.clientWidth < maxHeight
          ? chartElement.clientWidth
          : maxHeight,
      height:
        chartElement?.clientWidth && chartElement.clientWidth < maxHeight
          ? chartElement.clientWidth
          : maxHeight,
    });
  } else {
    await ref?.current?.updateDelta({
      width: chartElement?.clientWidth,
    });
  }
};

export const handlePrintWithMultipleChart = async (
  refs: Record<string, RefObject<AgChartInstance<AgChartOptions>>> | undefined,
) => {
  if (!refs) {
    return;
  }

  const refsArray = Object.entries(refs);

  const chartElements = refsArray.map(([id, ref]) => {
    const chartElement = ref?.current?.getOptions().container;

    return { id, chartElement };
  });

  await Promise.all(
    refsArray.map(([_, ref]) => {
      return ref?.current?.updateDelta({
        width: CHART_A4_WIDTH,
        height: MULTIPLE_CHART_A4_HEIGHT,
      });
    }),
  );

  window.print();

  await Promise.all(
    chartElements.map(({ id, chartElement }) => {
      const ref = refs[id];

      return ref?.current?.updateDelta({
        width: chartElement?.clientWidth,
        height: chartElement?.clientHeight,
      });
    }),
  );
};
