import { ConfigProvider, Flex, Form, Typography } from 'antd';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'translations/hooks';

import { usePermissions } from 'business/contextProviders/usePermissions';
import { PlotterMode } from 'business/data-analysis/constants';
import { useMode } from 'business/data-analysis/pages/graph/hooks/use-mode';
import { GraphFilters } from 'business/data-analysis/pages/graph/types';
import { useGetCurrentRingQuery } from 'generated/graphql';
import { notEmpty } from 'technical/utils/not-empty';
import { FilterCard } from 'ui/filter-card';
import { DatetimeRangePicker } from 'ui/form/datetime-range-picker';
import NumberRangeInput from 'ui/form/number-range-input';

import { OnChangeGraphFilters } from './hooks/types';
import styles from './index.module.scss';

interface Props {
  filters: GraphFilters;
  onChangeFilters: OnChangeGraphFilters;
}

const DataAnalysisFilterCard = ({ children }: PropsWithChildren) => {
  return <FilterCard>{children}</FilterCard>;
};

const TimePeriodFilterCard = ({
  filters,
  onChangeFilters,
}: Omit<Props, 'mode'>) => {
  const { t } = useTranslation();

  return (
    <DataAnalysisFilterCard>
      <Flex vertical>
        <Form.Item
          layout={'vertical'}
          label={t('dataAnalysis.labels.dateFilter')}
        >
          <DatetimeRangePicker
            onChange={onChangeFilters.onChangeDateRange}
            value={[filters.dateFilters.startDate, filters.dateFilters.endDate]}
            className={styles.dateRange}
          />
        </Form.Item>
      </Flex>
    </DataAnalysisFilterCard>
  );
};

const RingFilterCard = ({ filters, onChangeFilters }: Omit<Props, 'mode'>) => {
  const { t } = useTranslation();

  const { constructionSiteId } = usePermissions();
  const { data, error } = useGetCurrentRingQuery({
    variables: { constructionSiteId },
  });

  const [minRing, maxRing] = filters.ringFilters ?? [];

  const ringModeErrorMessage =
    notEmpty(minRing) && notEmpty(maxRing) && minRing > maxRing
      ? t('errors.invalid_ring_range')
      : undefined;

  return (
    <DataAnalysisFilterCard>
      <Flex vertical className={styles.numberRange}>
        <Form.Item layout={'vertical'} label={t('input.ringRange.label')}>
          {/* Cannot use the NumberRangeInputWithMessage here due to the use of
          `value` and `onChange` props */}
          <NumberRangeInput
            allowClear
            value={filters.ringFilters}
            onChange={onChangeFilters.onChangeRingRange}
            leftInputProps={{
              min: 0,
              placeholder: t('dataAnalysis.labels.startRing'),
            }}
            rightInputProps={{
              min: 0,
              placeholder: t('dataAnalysis.labels.endRing'),
            }}
            errorMessage={ringModeErrorMessage}
          />
        </Form.Item>
        <Typography.Text
          italic
          type="secondary"
          className={styles.currentRingNumber}
        >
          {error
            ? t('dataAnalysis.errors.currentRingNumberError')
            : t('dataAnalysis.labels.currentRingNumber', {
                ringNumber:
                  data?.parameter_ring_aggregate.aggregate?.max?.ringNumber,
              })}
        </Typography.Text>
      </Flex>
    </DataAnalysisFilterCard>
  );
};

export const FilterCardByMode = ({ filters, onChangeFilters }: Props) => {
  const mode = useMode();
  return (
    <div className={styles.filterCard}>
      <ConfigProvider
        theme={{
          components: {
            Form: {
              itemMarginBottom: 0,
            },
          },
        }}
      >
        {mode === PlotterMode.TIME_PERIOD ? (
          <TimePeriodFilterCard
            filters={filters}
            onChangeFilters={onChangeFilters}
          />
        ) : (
          <RingFilterCard filters={filters} onChangeFilters={onChangeFilters} />
        )}
      </ConfigProvider>
    </div>
  );
};
