import {
  LineChartOutlined,
  ExportOutlined,
  CopyOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import { App, Layout, Tooltip } from 'antd';
import { Flex } from 'antd/lib';
import invariant from 'tiny-invariant';
import { useTranslation } from 'translations/hooks';

import { useAppContext } from 'business/contextProviders/useAppContext';
import {
  useGraphSet,
  useGraphSetDispatch,
} from 'business/data-analysis/pages/graph/hooks/graph-context/contexts';
import {
  GraphSetDispatchActions,
  GraphPageState,
} from 'business/data-analysis/pages/graph/hooks/graph-context/types';
import { useExportGraphSet } from 'business/data-analysis/pages/graph/hooks/use-export-graph-set';
import { useMode } from 'business/data-analysis/pages/graph/hooks/use-mode';
import { GraphFilters } from 'business/data-analysis/pages/graph/types';
import { sameGraphSet } from 'business/data-analysis/services/graph-set-comparison';
import { mapToGraphSetFromStructureDto } from 'business/data-analysis/services/map-to-graph-set-from-structure-dto';
import { useMultipleGraphRefsContext } from 'business/providers/graph-reference';
import { useDataAnalysisControllerDuplicateGraphSet } from 'generated/apiComponents';
import { GraphSetTypeEnum_Enum } from 'generated/graphql';
import { handlePrintWithMultipleChart } from 'technical/handle-print-with-chart';
import Button from 'ui/button';
import { PrintButton } from 'ui/button/print-button';
import { ContentTitle } from 'ui/content-title';

import styles from './index.module.scss';

const { Header } = Layout;

interface Props {
  displaySavedGraphs: () => void;
  displaySavedGraphsButtonIsDisabled: boolean;
  setSaveSideBarVisible: (visible: boolean) => void;
  filters: GraphFilters;
}

export const GraphPageHeader = ({
  displaySavedGraphs,
  displaySavedGraphsButtonIsDisabled,
  setSaveSideBarVisible,
  filters,
}: Props) => {
  const { t } = useTranslation();
  const mode = useMode();

  const { currentConstructionSiteId } = useAppContext();
  invariant(currentConstructionSiteId, 'No construction site id');

  const { structure, savedState, displayParameterList } = useGraphSet();
  const dispatch = useGraphSetDispatch();

  const { message } = App.useApp();

  const { generateGraphSetExportByMode } = useExportGraphSet(message);
  const exportGraphSet = async () => {
    generateGraphSetExportByMode(
      mode,
      currentConstructionSiteId,
      structure,
      filters,
    );
  };

  const { mutate: duplicateGraphSet } =
    useDataAnalysisControllerDuplicateGraphSet();

  const atLeastOneParameterIsSelected = Array.from(
    structure.graphs.values(),
  ).some((graph) => graph.parameters.size > 0);

  const isSameGraphSet = sameGraphSet(savedState, structure);

  const onDuplicationClick = () => {
    if (!structure.id) {
      return;
    }

    duplicateGraphSet(
      {
        queryParams: {
          id: structure.id,
        },
      },
      {
        onSuccess: (data) => {
          const graphSetData = {
            ...data,
            type: data.type as GraphSetTypeEnum_Enum,
            id: data.id!,
          };

          const loadedStructure: GraphPageState['structure'] =
            mapToGraphSetFromStructureDto(graphSetData);

          dispatch({
            type: GraphSetDispatchActions.LoadGraphSetStructure,
            structure: loadedStructure,
          });

          message.open({
            type: 'success',
            content: t('dataAnalysis.actions.duplication.message'),
          });
        },
        onError: (error) => {
          // @ts-expect-error Front generated conflict error type do not match backend type
          if (error.stack.statusCode === 409) {
            message.open({
              type: 'error',
              content: t('dataAnalysis.errors.duplicationAlreadyExists'),
            });
            return;
          }

          message.open({
            type: 'error',
            content: t('dataAnalysis.errors.duplicationGenericError'),
          });
        },
      },
    );
  };

  const { refs } = useMultipleGraphRefsContext();

  return (
    <Header className={styles.header}>
      <ContentTitle>{structure.title}</ContentTitle>
      <Flex align="center" gap={10} className={styles.headerButtons}>
        <Flex align="center" gap={4}>
          <Button
            icon={<LineChartOutlined />}
            onClick={displaySavedGraphs}
            disabled={displaySavedGraphsButtonIsDisabled}
          >
            {t('dataAnalysis.actions.savedGraph')}
          </Button>
          {displayParameterList ? (
            <Tooltip
              title={t('dataAnalysis.actions.print.tooltip')}
              placement="bottom"
            >
              <PrintButton disabled />
            </Tooltip>
          ) : (
            <PrintButton onClick={() => handlePrintWithMultipleChart(refs)} />
          )}
          <Button
            icon={<ExportOutlined />}
            onClick={exportGraphSet}
            disabled={!atLeastOneParameterIsSelected}
          >
            {t('dataAnalysis.actions.export')}
          </Button>
          <Button
            icon={<CopyOutlined />}
            onClick={onDuplicationClick}
            disabled={!savedState || !isSameGraphSet}
          >
            {t('dataAnalysis.actions.duplication.button')}
          </Button>
        </Flex>
        <Button
          onClick={() => setSaveSideBarVisible(true)}
          disabled={!atLeastOneParameterIsSelected}
          className={styles.saveGraphSetButton}
          icon={<SaveOutlined />}
        >
          {t('dataAnalysis.actions.saveGraphSet')}
        </Button>
      </Flex>
    </Header>
  );
};
