import { MenuOutlined } from '@ant-design/icons';
import { ButtonProps } from 'antd';
import { useTranslation } from 'translations/hooks';

import { useMediaType } from 'technical/media/hooks';
import Button from 'ui/button';

import styles from './index.module.scss';

interface MenuButtonProps extends Pick<ButtonProps, 'onClick'> {}

export const MenuButton = ({ onClick }: MenuButtonProps) => {
  const { t } = useTranslation();
  const { isMobile } = useMediaType();

  return (
    <Button
      className={styles.menuButton}
      type="primary"
      size="large"
      icon={<MenuOutlined />}
      onClick={onClick}
    >
      {isMobile ? null : t('user.menu.button')}
    </Button>
  );
};
