import { App } from 'antd';
import { useTranslation } from 'translations/hooks';

import config from 'config';
import { useProductionAnalyzeV1ControllerReportProductionAnalyzeExport } from 'generated/apiComponents';
import { downloadFile } from 'technical/print';

export const useReportProdAnalyzeExport = () => {
  const { t } = useTranslation();
  const { message } = App.useApp();

  const { mutate: generateExport } =
    useProductionAnalyzeV1ControllerReportProductionAnalyzeExport({
      onSuccess: (data) => {
        downloadFile(`${config.gcp.publicUri}/${data.url}`, data.fileName);
      },
      onError: () => {
        message.open({
          type: 'error',
          content: t('errors.export_download_error'),
        });
      },
    });

  return {
    generateExport,
  };
};
