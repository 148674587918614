import { getAllDatesInRange } from './get-all-dates-in-range';

export const getMissingDatesInArray = (
  compareArray: Date[],
  startDate: Date,
  endDate: Date,
): Date[] => {
  const allTheDates = getAllDatesInRange(startDate, endDate);

  return allTheDates.filter(
    (date) =>
      !compareArray.some(
        (alreadyPresentDate) => date.valueOf() === alreadyPresentDate.valueOf(),
      ),
  );
};
