import { FloatButton, Layout } from 'antd';
import React, { useState } from 'react';

import { useMediaType } from 'technical/media/hooks';
import { MobileHeader } from 'ui/mobile-header';
import NavBar from 'ui/navBar';

import styles from './index.module.scss';

const { Content, Footer } = Layout;

interface Props {
  children?: React.ReactNode;
  img?: { src: string; alt: string };
  footer?: React.ReactNode;
  isConnected: boolean;
  title: string;
  constructionSiteId?: string;
}

function Page({
  children = null,
  footer = null,
  img,
  isConnected,
  title,
  constructionSiteId,
}: Props) {
  const { isMobile } = useMediaType();

  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <Layout className={styles.layout}>
      {isMobile ? (
        <MobileHeader
          title={title}
          isConnected={isConnected}
          constructionSiteId={constructionSiteId}
          menuState={{ open: menuOpen, setOpen: setMenuOpen }}
        />
      ) : (
        <NavBar
          isConnected={isConnected}
          title={title}
          constructionSiteId={constructionSiteId}
          menuState={{ open: menuOpen, setOpen: setMenuOpen }}
        />
      )}
      {img ? <img src={img.src} alt={img.alt} /> : null}
      <Content className={styles.content}>
        {children}
        <FloatButton.BackTop />
      </Content>
      {footer ? <Footer className={styles.footer}>{footer}</Footer> : null}
    </Layout>
  );
}

export default Page;
