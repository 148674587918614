import { Flex, Tree } from 'antd';
import Search from 'antd/lib/input/Search';
import { useState } from 'react';
import invariant from 'tiny-invariant';
import { useTranslation } from 'translations/hooks';

import { useGraphqlApiHeaders } from 'business/contextProviders/useApiHeaders';
import { useAppContext } from 'business/contextProviders/useAppContext';
import GraphSetSidebar from 'business/data-analysis/pages/graph/components/graph-set-side-bar';
import {
  useGraphSet,
  useGraphSetDispatch,
} from 'business/data-analysis/pages/graph/hooks/graph-context/contexts';
import { GraphSetDispatchActions } from 'business/data-analysis/pages/graph/hooks/graph-context/types';
import { parameterFamilyKeyPrefix } from 'business/data-analysis/pages/graph/types';
import { Module_Enum, useParameterFamiliesQuery } from 'generated/graphql';
import Button from 'ui/button';

import { useSearchParameters } from './hooks/searchParameters';

type SelectedNode = {
  id: number;
  name: string;
  unit: string;
};

export const ParameterSidebar = () => {
  const { t } = useTranslation();
  const { currentConstructionSite } = useAppContext();
  const headers = useGraphqlApiHeaders(Module_Enum.DataAnalysis);

  invariant(currentConstructionSite, 'No construction site id');

  const { data: parameterFamilies } = useParameterFamiliesQuery({
    variables: { constructionSiteId: currentConstructionSite.id },
    context: {
      headers,
    },
    skip: !currentConstructionSite.id,
  });

  const {
    expandedKeys,
    setExpandedKeys,
    onExpand,
    baseTreeData,
    onChange,
    treeData,
    searchInputValue,
    setSearchInputValue,
  } = useSearchParameters(parameterFamilies?.parameter_familyDef ?? []);

  const { currentGraphId } = useGraphSet();
  const dispatch = useGraphSetDispatch();

  const [selectedParameters, setSelectedParameters] = useState<SelectedNode[]>(
    [],
  );

  const onClose = () => {
    dispatch({
      type: GraphSetDispatchActions.SetCurrentGraphId,
      graphId: null,
    });
    setSelectedParameters([]);
    setExpandedKeys([]);
    setSearchInputValue('');
  };

  const onSelectParameter = (info: {
    checkedNodes: { key: string; meta: string; unit: string }[];
  }) => {
    // families can be checked to select all parameters whithin it, but we don't add families to the graph
    const parametersWithoutFamilies = info.checkedNodes.filter(
      (checked) => !checked.key.toString().includes(parameterFamilyKeyPrefix),
    );
    setSelectedParameters(
      parametersWithoutFamilies.map((param) => {
        return { id: parseInt(param.key), name: param.meta, unit: param.unit };
      }),
    );
  };

  const onAddParametersToChart = (parametersToAdd: SelectedNode[]) => {
    if (!currentGraphId) {
      onClose();
      return;
    }

    dispatch({
      type: GraphSetDispatchActions.AddParameters,
      graphId: currentGraphId,
      parameters: parametersToAdd,
    });
    onClose();
  };

  return (
    <GraphSetSidebar
      title={t('dataAnalysis.labels.parameterList')}
      visible={currentGraphId !== null}
      onClose={onClose}
      footer={
        <Flex justify="flex-end" gap={8}>
          <Button onClick={onClose}>{t('common.cancel')}</Button>
          <Button
            onClick={() => onAddParametersToChart(selectedParameters)}
            type="primary"
          >
            {t('dataAnalysis.actions.addParameters')}
          </Button>
        </Flex>
      }
    >
      <Flex vertical justify="space-between" gap={16}>
        <Search
          placeholder="Search"
          onChange={(event) => {
            onChange(event);
          }}
          value={searchInputValue}
        />

        <Tree
          checkable
          onCheck={(_, info) =>
            onSelectParameter(
              info as unknown as {
                checkedNodes: { key: string; meta: string; unit: string }[];
              },
            )
          }
          checkedKeys={selectedParameters.map((param) => param.id)}
          treeData={searchInputValue ? treeData : baseTreeData}
          defaultExpandParent={true}
          selectable={false}
          onExpand={onExpand}
          expandedKeys={expandedKeys}
        />
      </Flex>
    </GraphSetSidebar>
  );
};
