import dayjs, { Dayjs } from 'dayjs';

import {
  LocalStorageTaskAnalyzerOptions,
  TaskAnalyzerQueryOptions,
} from 'business/shift-report/task/pages/TasksAnalyzer/types';
import logger from 'technical/logger';
import { DateRangeType } from 'technical/types';

const safeStringToDayjs = (dateString: Dayjs | string | undefined | null) => {
  try {
    const date = dayjs(dateString as any);
    if (!date.isValid()) {
      return null;
    }
    return date;
  } catch (error) {
    logger.error(error);
    return null;
  }
};

export const parseCheckedKeys = (
  localStorageOptions: LocalStorageTaskAnalyzerOptions | null,
): string[] | null => {
  if (!localStorageOptions?.checkedKeys) {
    return null;
  }

  return localStorageOptions.checkedKeys;
};

export const parseStoredOptions = (
  localStorageOptions: LocalStorageTaskAnalyzerOptions | null,
): TaskAnalyzerQueryOptions | null => {
  if (!localStorageOptions?.queryOptions) {
    return null;
  }

  const dateRange: DateRangeType = localStorageOptions.queryOptions
    .dateRangeValue
    ? [
        localStorageOptions.queryOptions.dateRangeValue[0]
          ? safeStringToDayjs(
              localStorageOptions.queryOptions.dateRangeValue?.at(0),
            )
          : null,
        localStorageOptions.queryOptions.dateRangeValue[1]
          ? safeStringToDayjs(
              localStorageOptions.queryOptions.dateRangeValue?.at(1),
            )
          : null,
      ]
    : null;

  return {
    ...localStorageOptions.queryOptions,
    dateRangeValue: dateRange,
  };
};
