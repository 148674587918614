import { SwapRightOutlined } from '@ant-design/icons';
import { Space, Typography } from 'antd';
import { InputNumberProps } from 'antd/lib/input-number';
import classNames from 'classnames';
import { HTMLInputTypeAttribute } from 'react';
import { z } from 'zod';

import { SIMPLE_DATE_FORMAT } from 'technical/string/formatter';
import { dayjsSchema } from 'technical/type-schemas/dayjs';
import { MobileDatePicker } from 'ui/form/date-picker/mobile-picker';

import styles from './index.module.scss';

const dateOrEmptySchema = z.union([dayjsSchema, z.null()]);

export const dateRangeSchema = z.union([
  z.tuple([dateOrEmptySchema, dateOrEmptySchema]),
  z.null(),
]);
export type DateRangeValue = z.infer<typeof dateRangeSchema>;

export type MobileRangePickerProps = {
  value?: DateRangeValue;
  onChange?: (value: DateRangeValue) => void;
  allowClear?: boolean;
  leftInputProps?: InputNumberProps;
  leftTitle?: string;
  rightInputProps?: InputNumberProps;
  rightTitle?: string;
  errorMessage?: string;
  className?: string;
  format?: string;
  picker?: Extract<
    HTMLInputTypeAttribute,
    'datetime-local' | 'date' | 'week' | 'month'
  >;
};

export const MobileRangePicker = ({
  value,
  onChange,
  leftTitle,
  rightTitle,
  errorMessage,
  className,
  picker,
}: Readonly<MobileRangePickerProps>) => {
  const leftInputId = `leftInput-${leftTitle}`;
  const rightInputId = `rightInput-${rightTitle}`;

  return (
    <Space
      direction={'vertical'}
      className={classNames(styles.rangeInputContainer, className)}
    >
      <Space
        className={classNames(
          styles.rangeInput,
          styles.customPicker,
          //custom picker is duplicate of  'ant-input ant-picker ant-picker-range ant-picker-outlined',
        )}
      >
        <Space>
          {leftTitle ? (
            <label htmlFor={leftInputId}>
              <Typography.Text type="secondary">{leftTitle}</Typography.Text>
            </label>
          ) : null}
          <MobileDatePicker
            styled={false}
            id={leftTitle ? leftInputId : undefined}
            picker={picker}
            value={value?.[0]?.format(SIMPLE_DATE_FORMAT)}
            onChange={(inputValue) => {
              if (!onChange) {
                return;
              }

              onChange([
                inputValue.isValid() ? inputValue : null,
                value ? value[1] : null,
              ]);
            }}
          />
        </Space>
        <SwapRightOutlined
          className={
            styles.customPickerSeparator
            // duplicate of native ant class 'ant-picker-separator'
          }
        />
        <Space>
          {rightTitle ? (
            <label htmlFor={rightInputId}>
              <Typography.Text type="secondary">{rightTitle}</Typography.Text>
            </label>
          ) : null}
          <MobileDatePicker
            styled={false}
            id={rightTitle ? rightInputId : undefined}
            picker={picker}
            value={value?.[1]?.format(SIMPLE_DATE_FORMAT)}
            onChange={(inputValue) => {
              if (!onChange) {
                return;
              }

              onChange([
                value ? value[0] : null,
                inputValue.isValid() ? inputValue : null,
              ]);
            }}
          />
        </Space>
      </Space>
      {errorMessage ? (
        <Typography.Text type="danger">{errorMessage}</Typography.Text>
      ) : null}
    </Space>
  );
};
