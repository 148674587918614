import { DatePicker, Form, Radio, Select, Row, Col } from 'antd';
import React from 'react';
import { GoogleChartWrapperChartType } from 'react-google-charts/dist/types';
import { useTranslation } from 'translations/hooks';

import {
  ringRangeIsValidNumbers,
  ringRangeIsValidValue,
} from 'business/shift-report/task/pages/TasksAnalyzer/services/inputServices';
import { GROUP_BY_OPTIONS } from 'business/shift-report/task/pages/TasksAnalyzer/types';
import { ShiftReportGroupByEnum } from 'generated/graphql';
import NumberRangeInput from 'ui/form/number-range-input';

import { IndicatorAnalyzerOptions, CHART_TYPES } from './types';

const InputRow = ({
  inputLeft,
  inputRight,
}: {
  inputLeft: React.ReactNode;
  inputRight?: React.ReactNode;
}) => (
  <Row>
    <Col md={24} lg={11}>
      {inputLeft}
    </Col>
    {inputRight && (
      <Col md={24} lg={{ span: 11, offset: 2 }}>
        {inputRight}
      </Col>
    )}
  </Row>
);

const inputStyle = { width: '100%' };

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

interface IndicatorAnalyzerInputsProps {
  options: IndicatorAnalyzerOptions;
  setOptions: React.Dispatch<React.SetStateAction<IndicatorAnalyzerOptions>>;
}
export default function IndicatorAnalyzerInputs({
  options,
  setOptions,
}: Readonly<IndicatorAnalyzerInputsProps>) {
  const { t } = useTranslation();
  const { filterBy, dateRange, ringRange, groupBy, chartType } = options;

  return (
    <>
      <InputRow
        inputLeft={
          <Form.Item
            {...formItemLayout}
            label={t('indicators.labels.chartType')}
          >
            <Select<GoogleChartWrapperChartType>
              value={chartType}
              style={inputStyle}
              onChange={(value) =>
                setOptions((current) => ({ ...current, chartType: value }))
              }
            >
              {CHART_TYPES.map(({ label, type }) => (
                <Select.Option key={type} value={type}>
                  {t(label)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        }
      />
      <InputRow
        inputLeft={
          <Form.Item
            {...formItemLayout}
            label={t('indicators.labels.filterBy')}
          >
            <Radio.Group
              style={inputStyle}
              options={[
                {
                  label: t('input.filterByOptions.date'),
                  value: 'date',
                },
                {
                  label: t('input.filterByOptions.ring'),
                  value: 'ring',
                },
              ]}
              onChange={(e) =>
                setOptions((current) => ({
                  ...current,
                  filterBy: e.target.value,
                }))
              }
              value={filterBy}
              optionType="button"
              buttonStyle="solid"
            />
          </Form.Item>
        }
      />
      <InputRow
        inputLeft={
          filterBy === 'date' ? (
            <Form.Item
              {...formItemLayout}
              label={t('indicators.labels.dateRange')}
            >
              <DatePicker.RangePicker
                allowClear={false}
                style={inputStyle}
                format="L"
                onChange={(values) =>
                  setOptions((current) => ({
                    ...current,
                    dateRange: values as IndicatorAnalyzerOptions['dateRange'],
                  }))
                }
                value={dateRange}
              />
            </Form.Item>
          ) : (
            <Form.Item
              {...formItemLayout}
              label={t('indicators.labels.ringRange')}
              help={
                ringRangeIsValidNumbers(ringRange) &&
                !ringRangeIsValidValue(ringRange)
                  ? t('input.ringRange.helper')
                  : null
              }
              shouldUpdate
            >
              <NumberRangeInput
                value={ringRange}
                onChange={(values) =>
                  setOptions((current) => ({
                    ...current,
                    ringRange: values,
                  }))
                }
                leftInputProps={{
                  style: inputStyle,
                  placeholder: t('input.ringRange.startPlaceholder'),
                }}
                rightInputProps={{
                  style: inputStyle,
                  placeholder: t('input.ringRange.endPlaceholder'),
                }}
              />
            </Form.Item>
          )
        }
        inputRight={
          filterBy === 'date' ? (
            <Form.Item
              {...formItemLayout}
              label={t('indicators.labels.groupBy')}
            >
              <Select<ShiftReportGroupByEnum>
                defaultValue={ShiftReportGroupByEnum.Day}
                style={inputStyle}
                onChange={(value) =>
                  setOptions((current) => ({ ...current, groupBy: value }))
                }
                value={groupBy}
              >
                {GROUP_BY_OPTIONS.map(({ value, label }) => (
                  <Select.Option key={value} value={value}>
                    {t(label)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          ) : null
        }
      />
    </>
  );
}
