import { Link, To } from 'react-router-dom';
import { useTranslation } from 'translations/hooks';

import Button from 'ui/button';

interface MainPageButton {
  to: To;
  className?: string;
}

export const MainPageButton = ({ to, className }: MainPageButton) => {
  const { t } = useTranslation();

  return (
    <Link to={to}>
      <Button className={className}>{t('common.to_main_page')}</Button>
    </Link>
  );
};
