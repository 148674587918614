import { Empty, Flex } from 'antd';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import invariant from 'tiny-invariant';

import { useRestApiHeaders } from 'business/contextProviders/useApiHeaders';
import { useAppContext } from 'business/contextProviders/useAppContext';
import graphContainerStyles from 'business/layout/single-card-page-with-filter-card/graph.module.scss';
import { RingPerDayAverages } from 'business/production-and-performance/pages/ring-per-day/components/ring-per-day-averages';
import { RingPerDayGraph } from 'business/production-and-performance/pages/ring-per-day/components/ring-per-day-graph';
import { useRingPerDayLegendFilter } from 'business/production-and-performance/pages/ring-per-day/providers/legend-filter';
import { useProdAndPerfFilters } from 'business/production-and-performance/providers/prod-and-perf-filters-provider';
import { useRingPerDayControllerRingPerDay } from 'generated/apiComponents';
import { Module_Enum } from 'generated/graphql';
import { getFetchRange } from 'technical/range-filters/get-fetch-range';
import { notEmpty } from 'technical/utils/not-empty';
import Loader from 'ui/loader';

export const RingPerDayGraphContainer = () => {
  const { currentConstructionSiteId } = useAppContext();
  const filters = useProdAndPerfFilters();

  const headers = useRestApiHeaders(Module_Enum.ProdPerf);
  invariant(currentConstructionSiteId, 'No constructionSite id');

  const { legendFilter } = useRingPerDayLegendFilter();

  const fetchRange = getFetchRange(filters);

  const legendFilterKeys = Object.entries(legendFilter)
    .map(([key, value]) => (value ? key : null))
    .filter(notEmpty);

  const { data, isLoading, error, refetch } = useRingPerDayControllerRingPerDay(
    {
      queryParams: {
        constructionSiteId: currentConstructionSiteId,
        dayMode: filters.dayMode,
        legendFilter: legendFilterKeys,
        ...fetchRange,
      },
      headers,
    },
    { enabled: !!fetchRange },
  );

  useEffect(() => {
    refetch();
  }, [refetch, legendFilterKeys]);

  if (error) {
    return <Empty />;
  }

  if (isLoading) {
    return (
      // To avoid flicker on loading when legend is clicked
      <div className={graphContainerStyles.loaderContainer}>
        <Loader />
      </div>
    );
  }

  if (!data || !data.graphData.length) {
    return <Empty />;
  }

  const averagePerDayAndShift =
    data.dayMode === 'shift' ? data.averagePerDayAndShift : null;

  return (
    <Flex
      justify="space-between"
      vertical
      gap={24}
      className={graphContainerStyles.flexContainer}
    >
      <RingPerDayAverages
        averagePerDay={data.averagePerDay}
        averagePerDayAndShift={averagePerDayAndShift}
        dayMode={filters.dayMode}
      />
      <div className={graphContainerStyles.graphContainer}>
        <RingPerDayGraph
          ringPerDayGraphData={data.graphData.map(({ date, ...rest }) => ({
            ...rest,
            date: dayjs.tz(date).toDate(),
          }))}
        />
      </div>
    </Flex>
  );
};
