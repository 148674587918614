import AggregatedGraph from 'business/shift-report/report/pages/ReportAggregated/AggregatedGraph';
import { GetTopLevelCriticalTaskAggregateV1Response } from 'generated/apiSchemas';

interface TopAndSecondLevelChartsProps {
  tasksAggregate: GetTopLevelCriticalTaskAggregateV1Response | undefined;
}

export const TopAndSecondLevelCharts = ({
  tasksAggregate,
}: TopAndSecondLevelChartsProps) => {
  return tasksAggregate ? (
    <>
      <AggregatedGraph
        topLevelTasksAggregate={tasksAggregate.firstLevelTasks}
        critical
      />
      {tasksAggregate.aggregatedSecondLevel
        ? Object.entries(tasksAggregate.aggregatedSecondLevel).map((level) => {
            if (level[1].length < 2) {
              return null;
            }

            return (
              <AggregatedGraph
                topLevelTasksAggregate={level[1]}
                critical
                title={level[0]}
              />
            );
          })
        : null}
    </>
  ) : null;
};
