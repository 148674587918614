import { Flex, Tag } from 'antd';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useTranslation } from 'translations/hooks';

import { useMediaType } from 'technical/media/hooks';

import styles from './index.module.scss';

export enum State {
  excavating = 'excavating',
  building = 'building',
  stopped = 'stopped',
}

interface DateProps {
  timeZone?: string;
}

interface StateProps {
  state?: State;
}

const stateTag = {
  [State.excavating]: {
    color: 'green',
    translationKey: 'dashboard.tbm.state.excavating',
  },
  [State.building]: {
    color: 'gold',
    translationKey: 'dashboard.tbm.state.building',
  },
  [State.stopped]: {
    color: undefined,
    translationKey: 'dashboard.tbm.state.stopped',
  },
};

const LastDate = ({ timeZone }: DateProps) => {
  const { t } = useTranslation();
  const { isMobile } = useMediaType();

  const currentDate = dayjs().tz(timeZone);
  const compareDate = currentDate.format('YYYY-MM-DDTHH');

  const date = useMemo(
    () => {
      return currentDate.format('LL LTS');
    },
    // This is intentional; we want to display the full date without
    // state refreshes for minute or second changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [compareDate],
  );

  return (
    <Tag bordered={false} className={styles.corner}>
      <Flex gap={8}>
        {isMobile ? null : t('dashboard.tbm.localDate')}
        <span className={styles.date}>{timeZone ? date : '-'}</span>
      </Flex>
    </Tag>
  );
};

const LastState = ({ state }: StateProps) => {
  const { t } = useTranslation();
  const { isMobile } = useMediaType();

  return (
    <Tag
      color={state ? stateTag[state].color : undefined}
      bordered={false}
      className={styles.corner}
    >
      <Flex gap={8}>
        {isMobile ? null : (
          <span className={styles.statusLabel}>
            {t('dashboard.tbm.state.label')}
          </span>
        )}

        <span className={styles.statusValue}>
          {state ? t(stateTag[state].translationKey) : '-'}
        </span>
      </Flex>
    </Tag>
  );
};

export const LastKnownState = ({ timeZone, state }: DateProps & StateProps) => {
  const { isTablet, isMobile } = useMediaType();

  return (
    <Flex gap={4} wrap={isTablet || isMobile}>
      <LastDate timeZone={timeZone} />
      <LastState state={state} />
    </Flex>
  );
};
