import { AgCartesianSeriesOptions, AgChartOptions } from 'ag-charts-enterprise';
import { AgCharts } from 'ag-charts-react';
import dayjs from 'dayjs';
import { TFunction, useTranslation } from 'translations/hooks';

import { PlotterMode } from 'business/data-analysis/constants';
import {
  GraphPageState,
  RingParameter,
} from 'business/data-analysis/pages/graph/hooks/graph-context/types';
import { useGraphSetData } from 'business/data-analysis/pages/graph/hooks/graph-data-context/contexts';
import { GraphSetData } from 'business/data-analysis/pages/graph/hooks/graph-data-context/types';
import { useGraph } from 'business/data-analysis/pages/graph/hooks/use-graph';
import { useMode } from 'business/data-analysis/pages/graph/hooks/use-mode';
import { parseAndFormatDateForGraph } from 'business/data-analysis/services/parse-and-format-date-for-graph';
import { useMultipleGraphRefsContext } from 'business/providers/graph-reference';
import { useChartResize } from 'technical/chart-resize';

import styles from './index.module.scss';
import { ChartType } from './types';

interface Props {
  graphId: string;
}

type Parameter = Parameters<
  Parameters<
    GraphPageState['structure']['graphs']['set']
  >[1]['parameters']['set']
>[1];

const getTimePeriodSerie = (parameter: Parameter): AgCartesianSeriesOptions => {
  return {
    type: ChartType.LINE,
    xKey: 'date',
    yKey: parameter.id.toString(),
    yName: `${parameter.name} (${parameter.unit})`,
    stroke: parameter.color,
    marker: { stroke: parameter.color, fill: parameter.color, size: 4 },
    connectMissingData: true,
  };
};

const formatParameterName = (parameter: RingParameter) => {
  return `${parameter.id}_${parameter.aggregationType}_${parameter.computeType}`;
};

const isRingParameter = (parameter: any): parameter is RingParameter => {
  return parameter.aggregationType && parameter.computeType;
};

const getRingParameterName = (parameter: Parameter, t: TFunction) => {
  if (isRingParameter(parameter)) {
    return `${parameter.name} (${parameter.unit}) - ${t('dataAnalysis.ringMode.computeType.type', { context: parameter.computeType })} - ${t('dataAnalysis.ringMode.aggregationType.type', { context: parameter.aggregationType })}`;
  }

  return `${parameter.name} (${parameter.unit})`;
};

const getRingSerie = (
  parameter: Parameter,
  t: TFunction,
): AgCartesianSeriesOptions => {
  return {
    type: ChartType.BAR,
    xKey: 'ring',
    yKey: formatParameterName(parameter as RingParameter),
    yName: getRingParameterName(parameter, t),
    fill: parameter.color,
  };
};

const isPeriodData = (
  mode: PlotterMode,
  _data: GraphSetData[number],
): _data is { date: string } & Record<string, string> => {
  return mode === PlotterMode.TIME_PERIOD;
};

const isRingData = (
  mode: PlotterMode,
  _data: GraphSetData[number],
): _data is { ring: number } & Record<string, string> => {
  return mode === PlotterMode.RING;
};

const transformData = (data: GraphSetData, mode: PlotterMode) => {
  return data.map((value) => {
    const values = Object.entries(value).reduce((prev, [key, val]) => {
      if (key === 'date') {
        return prev;
      }
      return { ...prev, [key]: parseFloat(val?.toString()) };
    }, {});

    return {
      ...values,
      date: isPeriodData(mode, value)
        ? dayjs.tz(value.date).toDate()
        : undefined,
      ring: isRingData(mode, value) ? value.ring : undefined,
    };
  });
};

const Chart = ({ graphId }: Props) => {
  const { getRef } = useMultipleGraphRefsContext();
  const ref = getRef(graphId);

  const mode = useMode();
  const graph = useGraph(graphId);
  const values = useGraphSetData();
  const { t } = useTranslation();

  const getSerie =
    mode === PlotterMode.TIME_PERIOD ? getTimePeriodSerie : getRingSerie;

  const parameters = Array.from(graph.parameters.values());

  const series = parameters
    .map((parameter): AgCartesianSeriesOptions => {
      return getSerie(parameter, t);
    })
    .filter((parameter): parameter is AgCartesianSeriesOptions => !!parameter);

  const parameterIds = parameters.map((param) => param.id.toString());

  const data = transformData(values, mode);

  const chartOptions: AgChartOptions = {
    data,
    animation: {
      enabled: false,
    },
    axes:
      mode === PlotterMode.TIME_PERIOD
        ? [
            {
              label: {
                formatter: (params) => parseAndFormatDateForGraph(params.value),
              },
              keys: ['date'],
              type: 'time',
              position: 'bottom',
              nice: false,
            },
            {
              keys: parameterIds,
              position: 'left',
              type: 'number',
            },
          ]
        : [
            {
              keys: ['ring'],
              type: 'category',
              position: 'bottom',
            },
            {
              keys: parameters.map((param) =>
                formatParameterName(param as RingParameter),
              ),
              position: 'left',
              type: 'number',
            },
          ],
    contextMenu: {
      enabled: true,
    },
    legend: {
      enabled: true,
    },
    series,
    zoom: {
      // zoom on where the mouse is
      anchorPointX: 'pointer',
      anchorPointY: 'pointer',
    },
  };

  useChartResize(ref);

  return (
    <div className={styles.chartContainer}>
      <AgCharts options={chartOptions} ref={ref} />
    </div>
  );
};
export default Chart;
