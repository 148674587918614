import { AgChartInstance, AgChartOptions } from 'ag-charts-enterprise';
import {
  PropsWithChildren,
  RefObject,
  createContext,
  createRef,
  useContext,
  useRef,
} from 'react';

type SingleGraphRef = {
  ref: RefObject<AgChartInstance<AgChartOptions>> | undefined;
};

type MultipleGraphRefs = {
  refs: Record<string, RefObject<AgChartInstance<AgChartOptions>>>;
  getRef: (key: string) => RefObject<AgChartInstance<AgChartOptions>>;
};

const SingleGraphReferenceContext = createContext<SingleGraphRef>({
  ref: undefined,
});

export const useSingleGraphReferenceContext = () =>
  useContext(SingleGraphReferenceContext);

export const SingleGraphReferenceContextProvider = ({
  children,
}: PropsWithChildren) => {
  const graphRef = createRef<AgChartInstance<AgChartOptions>>();

  return (
    <SingleGraphReferenceContext.Provider value={{ ref: graphRef }}>
      {children}
    </SingleGraphReferenceContext.Provider>
  );
};

const MultipleGraphRefsContext = createContext<MultipleGraphRefs>({
  refs: {},
  getRef: (_key: string): RefObject<AgChartInstance<AgChartOptions>> => {
    throw new Error('Function not implemented.');
  },
});

export const useMultipleGraphRefsContext = () =>
  useContext(MultipleGraphRefsContext);

export const MultipleGraphReferencesContextProvider = ({
  children,
}: PropsWithChildren) => {
  const refs = useRef<
    Record<string, RefObject<AgChartInstance<AgChartOptions>>>
  >({});

  const getRef = (key: string) => {
    if (!refs.current?.[key]) {
      refs.current[key] = createRef();
    }
    return refs.current[key];
  };

  return (
    <MultipleGraphRefsContext.Provider value={{ refs: refs.current, getRef }}>
      {children}
    </MultipleGraphRefsContext.Provider>
  );
};
