import { LineChartOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { useTranslation } from 'translations/hooks';

import {
  useGraphSet,
  useGraphSetDispatch,
} from 'business/data-analysis/pages/graph/hooks/graph-context/contexts';
import { GraphSetDispatchActions } from 'business/data-analysis/pages/graph/hooks/graph-context/types';
import styles from 'business/data-analysis/pages/graph/index.module.scss';
import Button from 'ui/button';

export const AddNewGraphButton = ({ className }: { className?: string }) => {
  const { t } = useTranslation();

  const { structure } = useGraphSet();
  const dispatch = useGraphSetDispatch();

  return structure.graphs.size < 4 ? (
    <Button
      onClick={() => dispatch({ type: GraphSetDispatchActions.AddGraph })}
      className={classNames(styles.action, className)}
      type="primary"
      icon={<LineChartOutlined />}
    >
      {t('dataAnalysis.actions.addGraph')}
    </Button>
  ) : null;
};
