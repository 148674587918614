import { Flex, Typography } from 'antd';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'translations/hooks';

import { useCheckPermission } from 'business/contextProviders/usePermissions';
import { MenuButton } from 'ui/menu/menu-button';
import MenuPopover from 'ui/menu/menu-popover';

import styles from './index.module.scss';

interface Props {
  permissions: string[];
  open: boolean;
  onOpenChange: (open: boolean) => void;
  disableTitle?: boolean;
}

const MenuContent = ({
  children,
  permissions,
  open,
  onOpenChange,
  disableTitle = false,
}: PropsWithChildren<Props>) => {
  const { t } = useTranslation();

  const permissionCheck = useCheckPermission(permissions);

  if (!permissionCheck) {
    return null;
  }

  return (
    <MenuPopover
      open={open}
      onOpenChange={onOpenChange}
      styles={{
        body: { margin: 20 }, // sadly ant do not expose inner classname
      }}
      classNames={{ root: styles.overlay }}
      content={
        <Flex justify="space-between" className={styles.innerMenuContainer}>
          <div>
            {disableTitle ? null : (
              <Typography className={styles.title}>
                {t('user.menu.title')}
              </Typography>
            )}
            <Flex gap="small">{children}</Flex>
          </div>
        </Flex>
      }
    >
      <MenuButton />
    </MenuPopover>
  );
};

export default MenuContent;
