import {
  AgCartesianAxisOptions,
  AgCartesianSeriesOptions,
  AgChartOptions,
  AgSeriesVisibilityChange,
} from 'ag-charts-enterprise';
import { AgCharts } from 'ag-charts-react';
import dayjs from 'dayjs';
import { TFunction, useTranslation } from 'translations/hooks';

import { formatGraphDateAxisLabel } from 'business/production-and-performance/services/graph-date-format';
import { useSingleGraphReferenceContext } from 'business/providers/graph-reference';
import { useReportProdAnalyzeFilters } from 'business/shift-report/report-analyze/pages/production-analyze/providers/filters';
import { useReportProdAnalyzeSeriesFilter } from 'business/shift-report/report-analyze/pages/production-analyze/providers/series-filter';
import { getEmptyReportProdAnalyzeGraphPoint } from 'business/shift-report/report-analyze/pages/production-analyze/services/get-empty-graph-point';
import {
  ReportProdAnalyzeGraphPoint,
  SerieMetadata,
} from 'business/shift-report/report-analyze/pages/production-analyze/types';
import { useChartResize } from 'technical/chart-resize';
import { addMissingDaysForGraphPerDay } from 'technical/graph/add-missing-days-for-graph-per-day';
import { DateRangeType } from 'technical/types';

type ReportProdAnalyzeGraphProps = {
  reportProdAnalyzeGraphData: ReportProdAnalyzeGraphPoint[];
  availableSeries: SerieMetadata[];
};

const getDateRangeFromData = (
  data: ReportProdAnalyzeGraphPoint[],
): DateRangeType => {
  if (data.length === 0) {
    return null;
  }

  const startDate = dayjs(data[0].date);
  const endDate = dayjs(data[data.length - 1].date);

  return [startDate, endDate];
};

const tooltipRenderer = (params: any, t: TFunction) => {
  return {
    data: [
      {
        label: '',
        value: t('reportAnalyze.productionAnalyze.graph.tooltip.ring', {
          count: parseInt(params.datum[params.yKey]),
        }),
      },
    ],
  };
};

const buildReportProdAnalyzeSeries = (
  availableSeries: SerieMetadata[],
  selectedSeries: Record<string, boolean>,
  t: TFunction,
) => {
  return availableSeries.reduce<AgCartesianSeriesOptions[]>((acc, serie) => {
    acc.push({
      type: 'bar',
      xKey: 'date',
      yKey: serie.id,
      yName: serie.name,
      stacked: true,
      strokeWidth: 1,
      tooltip: { renderer: (params) => tooltipRenderer(params, t) },
      visible: selectedSeries[serie.id],
    });
    return acc;
  }, []);
};

const reportProdAnalyzeGraphAxesOptions = () =>
  [
    {
      type: 'category',
      position: 'bottom',
      label: {
        formatter: ({ value }) => formatGraphDateAxisLabel(value),
      },
    },
    {
      type: 'number',
      position: 'left',
      nice: false,
      interval: {
        step: 1,
      },
    },
  ] satisfies AgCartesianAxisOptions[];

export function ReportProdAnalyzeGraph({
  reportProdAnalyzeGraphData,
  availableSeries,
}: ReportProdAnalyzeGraphProps) {
  const { ref: chartRef } = useSingleGraphReferenceContext();

  const { t } = useTranslation();
  const filters = useReportProdAnalyzeFilters();
  const { seriesFilter, setSeriesFilter } = useReportProdAnalyzeSeriesFilter();

  const reportProdAnalyzeGraphDataWithMissingDates =
    addMissingDaysForGraphPerDay(
      getDateRangeFromData(reportProdAnalyzeGraphData),
      reportProdAnalyzeGraphData,
      getEmptyReportProdAnalyzeGraphPoint,
    );

  const updateVisibility = ({ itemId, visible }: AgSeriesVisibilityChange) => {
    if (itemId && typeof itemId === 'string') {
      setSeriesFilter((value) => {
        const updatedVisibility = {
          ...value,
          [filters.groupByType]: {
            ...value[filters.groupByType],
            [itemId]: visible,
          },
        };
        return updatedVisibility;
      });
    }
  };

  const chartOptions = {
    data: reportProdAnalyzeGraphDataWithMissingDates,
    series: buildReportProdAnalyzeSeries(
      availableSeries,
      seriesFilter[filters.groupByType],
      t,
    ),
    axes: reportProdAnalyzeGraphAxesOptions(),
    legend: {
      enabled: true,
    },
    listeners: {
      seriesVisibilityChange: updateVisibility,
    },
  } satisfies AgChartOptions;

  useChartResize(chartRef);

  return <AgCharts options={chartOptions} ref={chartRef} />;
}
