import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'translations/hooks';

import GraphCardHeader from 'business/data-analysis/pages/graph/components/graph-card-header';
import GraphSection from 'business/data-analysis/pages/graph/components/graph-section';
import ParameterList from 'business/data-analysis/pages/graph/components/parameter-list';
import {
  useGraphSet,
  useGraphSetDispatch,
} from 'business/data-analysis/pages/graph/hooks/graph-context/contexts';
import { GraphSetDispatchActions } from 'business/data-analysis/pages/graph/hooks/graph-context/types';
import { useGraph } from 'business/data-analysis/pages/graph/hooks/use-graph';
import { useMultipleGraphRefsContext } from 'business/providers/graph-reference';
import Button from 'ui/button';
import Flex from 'ui/flex';
import TrashIcon from 'ui/icons/trash';
import { SimpleCard } from 'ui/SimpleCard';

import styles from './index.module.scss';

const PARAMETER_LIST_WIDTH = 400;

interface Props {
  graphId: string;
}

export const GraphCard = ({ graphId }: Props) => {
  const { t } = useTranslation();
  const { getRef } = useMultipleGraphRefsContext();
  const ref = getRef(graphId);

  const graph = useGraph(graphId);
  const { displayParameterList } = useGraphSet();
  const dispatch = useGraphSetDispatch();

  const openParameterSidebar = () => {
    dispatch({ type: GraphSetDispatchActions.SetCurrentGraphId, graphId });
  };

  const removeAllParameters = () => {
    dispatch({
      type: GraphSetDispatchActions.RemoveParameters,
      graphId,
      parameterUniqueIds: Array.from(graph.parameters.keys()),
    });
  };

  const toggleParameterList = async () => {
    const chartContainer = ref.current?.getOptions().container;
    // Correctly resize the chart when the parameter list is toggled open
    // because the chart resize hook only works with window resize events
    if (chartContainer && !displayParameterList) {
      await ref.current.updateDelta({
        width: chartContainer.clientWidth - PARAMETER_LIST_WIDTH,
      });
    }

    dispatch({ type: GraphSetDispatchActions.ToggleParameterList });
  };

  return (
    <SimpleCard className={styles.graphCard}>
      <GraphCardHeader
        graphId={graphId}
        displayParameterList={displayParameterList}
        toogleDisplayParameterList={() => toggleParameterList()}
      />

      <Flex className={styles.graphSectionContainer}>
        {displayParameterList ? (
          <div className={styles.parameterSection}>
            <Button
              className={styles.addParameter}
              type="primary"
              onClick={openParameterSidebar}
              icon={<PlusOutlined />}
            >
              {t('dataAnalysis.actions.addParameter')}
            </Button>
            {graph.parameters.size > 0 ? (
              <Button
                icon={<TrashIcon className={styles.removeAllParametersIcon} />}
                className={styles.removeAllParameters}
                onClick={removeAllParameters}
              >
                {t('dataAnalysis.actions.removeParameters')}
              </Button>
            ) : null}
            <ParameterList graphId={graphId} />
          </div>
        ) : null}

        <GraphSection graphId={graphId} />
      </Flex>
    </SimpleCard>
  );
};
