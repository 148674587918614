import { InputNumber, InputNumberProps } from 'antd';
import { getLocale } from 'translations/locale';
import { Locale } from 'translations/types';

export const DecimalInput = (
  props: Omit<
    React.PropsWithChildren<InputNumberProps<string | number>> &
      React.RefAttributes<HTMLInputElement>,
    'parser' | 'formatter'
  >,
) => {
  return (
    <InputNumber
      {...props}
      parser={(value) => {
        if (!value) {
          return '0';
        }
        return value.replace(/[\.,]/, '.');
      }}
      formatter={(value) => {
        if (!value) {
          return '0';
        }

        const locale = getLocale();
        const stringedValue = value.toString();

        if ([Locale.fr, Locale.es].includes(locale)) {
          return parseFloat(stringedValue).toString().replace(/[\.,]/, ',');
        }
        return parseFloat(stringedValue).toString().replace(/[\.,]/, '.');
      }}
    />
  );
};
