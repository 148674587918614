import { PoweroffOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, Flex } from 'antd';
import { MenuProps } from 'antd/lib';
import { useEffect } from 'react';
import { useTranslation } from 'translations/hooks';

import { useAppContext } from 'business/contextProviders/useAppContext';
import { login, logout, signUp } from 'business/user/services/authentication';
import config from 'config';
import AppLogo from 'ui/appLogo';
import Button from 'ui/button';
import { ConstructionSiteSelector } from 'ui/construction-site-selector';
import { HeaderContainer } from 'ui/header-container';
import { LanguageSelector } from 'ui/language-selector';
import { Menu } from 'ui/menu';
import { PageTitle } from 'ui/page-title';

interface MobileHeaderProps {
  title: string;
  isConnected?: boolean;
  constructionSiteId?: string;
  menuState: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  };
}

export const MobileHeader = ({
  title,
  isConnected,
  constructionSiteId,
  menuState,
}: MobileHeaderProps) => {
  const { t } = useTranslation();
  const {
    user,
    currentConstructionSite,
    constructionSites,
    setCurrentConstructionSiteId,
  } = useAppContext();

  function handleLoginOnClick() {
    login();
  }
  function handleRegisterOnClick() {
    signUp();
  }

  const userInitals =
    user?.firstName && user?.lastName ? (
      `${user.firstName[0]}${user.lastName[0]}`
    ) : (
      <UserOutlined />
    );

  const userNameDisplayed =
    user?.firstName && user?.lastName
      ? `${user.firstName} ${user.lastName}`
      : user?.email;

  const dropdownItems: MenuProps['items'] = [
    { key: 'name', label: userNameDisplayed, disabled: true },
    {
      key: 'language',
      label: <LanguageSelector />,
      disabled: true,
    },
    {
      key: 'constructionSite',
      label: (
        <>
          {!constructionSiteId && constructionSites?.length > 1 ? (
            <ConstructionSiteSelector />
          ) : null}
          {constructionSiteId ||
          (constructionSites && constructionSites.length === 1) ? (
            <h1>{currentConstructionSite?.name}</h1>
          ) : null}
        </>
      ),
      disabled: true,
    },
    {
      key: 'disconnect',
      danger: true,
      onClick: () => logout(),
      label: (
        <>
          <PoweroffOutlined style={{ fontSize: '14px' }} /> {t('user.logout')}
        </>
      ),
    },
    {
      key: 'version',
      disabled: true,
      label: config.version,
    },
  ];

  useEffect(() => {
    if (constructionSiteId) {
      setCurrentConstructionSiteId(constructionSiteId);
    }
  });

  return (
    <HeaderContainer>
      <Flex align="center" gap={10} flex={1}>
        <AppLogo small />
        {!!constructionSites.length ? <Menu menuState={menuState} /> : null}
      </Flex>

      <PageTitle title={title} />

      <Flex justify="flex-end" flex={1}>
        {isConnected ? (
          <Dropdown menu={{ items: dropdownItems }} trigger={['click']}>
            <button
              style={{ border: 'initial', background: 'initial' }}
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
              <Avatar style={{ backgroundColor: '#003a8c' }}>
                {userInitals}
              </Avatar>
            </button>
          </Dropdown>
        ) : (
          <Flex>
            <Button onClick={handleLoginOnClick}>
              {t('notConnected.login.button')}
            </Button>
            <Button
              style={{ marginLeft: '16px' }}
              onClick={handleRegisterOnClick}
              type="primary"
            >
              {t('notConnected.signup')}
            </Button>
          </Flex>
        )}
      </Flex>
    </HeaderContainer>
  );
};
