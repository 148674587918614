import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

import { GroupByType } from 'business/shift-report/report-analyze/pages/production-analyze/types';
import { notEmpty } from 'technical/utils/not-empty';

export type ReportProdAnalyzeSeriesFilter = Record<
  GroupByType,
  Record<string, boolean>
>;

const defaultReportProdAnalyzeSeriesFilter: ReportProdAnalyzeSeriesFilter = {
  shift: {},
  team: {},
};

const ReportProdAnalyzeSeriesFilterContext = createContext<{
  seriesFilter: ReportProdAnalyzeSeriesFilter;
  setSeriesFilter: Dispatch<SetStateAction<ReportProdAnalyzeSeriesFilter>>;
}>({
  seriesFilter: defaultReportProdAnalyzeSeriesFilter,
  setSeriesFilter: () => {},
});

const mapArrayToDefaultVisible = (array: string[]) => {
  return array.reduce((acc, cur) => {
    return {
      ...acc,
      [cur]: true,
    };
  }, {});
};

const ReportProdAnalyzeSeriesFilterProvider = ({
  shift,
  team,
  children,
}: PropsWithChildren<{
  shift: string[];
  team: string[];
}>) => {
  const [seriesFilter, setSeriesFilter] =
    useState<ReportProdAnalyzeSeriesFilter>(
      defaultReportProdAnalyzeSeriesFilter,
    );

  // Sync initial filters with query result
  const staticInitialFilters = [...shift, ...team].join(',');
  useEffect(() => {
    setSeriesFilter({
      shift: mapArrayToDefaultVisible(shift),
      team: mapArrayToDefaultVisible(team),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staticInitialFilters]);

  return (
    <ReportProdAnalyzeSeriesFilterContext.Provider
      value={{ seriesFilter, setSeriesFilter }}
    >
      {children}
    </ReportProdAnalyzeSeriesFilterContext.Provider>
  );
};

const useReportProdAnalyzeSeriesFilter = () =>
  useContext(ReportProdAnalyzeSeriesFilterContext);

const useReportProdAnalyzeEnabledSeriesFilter = () => {
  const { seriesFilter } = useContext(ReportProdAnalyzeSeriesFilterContext);

  return {
    shift: Object.entries(seriesFilter.shift)
      .map(([key, value]) => (value ? key : null))
      .filter(notEmpty),
    team: Object.entries(seriesFilter.team)
      .map(([key, value]) => (value ? key : null))
      .filter(notEmpty),
  };
};

export {
  ReportProdAnalyzeSeriesFilterProvider,
  useReportProdAnalyzeSeriesFilter,
  useReportProdAnalyzeEnabledSeriesFilter,
};
