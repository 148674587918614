import { Col, Flex, Row } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'translations/hooks';

import { TaskFullFragment } from 'generated/graphql';

import styles from './index.module.scss';

interface TaskProps {
  task: TaskFullFragment;
}

export const Task = ({ task }: TaskProps) => {
  const { t } = useTranslation();

  return (
    <Row gutter={[8, 4]}>
      <Col xs={8} sm={8}>
        <Flex vertical>
          <span className={styles.column}>
            {t('pages.report.massValidation.name')}
          </span>
          <span>{task.activity.name}</span>
        </Flex>
      </Col>
      <Col xs={4} sm={4}>
        <Flex vertical>
          <span className={styles.column}>
            {t('pages.report.massValidation.ring')}
          </span>
          <span>{task.ring}</span>
        </Flex>
      </Col>
      <Col xs={6} sm={6}>
        <Flex vertical>
          <span className={styles.column}>
            {t('pages.report.massValidation.start')}
          </span>
          <span>{dayjs(task.startDate).format('HH:mm')}</span>
        </Flex>
      </Col>
      <Col xs={6} sm={6}>
        <Flex vertical>
          <span className={styles.column}>
            {t('pages.report.massValidation.end')}
          </span>
          <span>{dayjs(task.endDate).format('HH:mm')}</span>
        </Flex>
      </Col>
    </Row>
  );
};
