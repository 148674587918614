import dayjs from 'dayjs';
import invariant from 'tiny-invariant';
import { useTranslation } from 'translations/hooks';

import { useAppContext } from 'business/contextProviders/useAppContext';
import { SingleGraphReferenceContextProvider } from 'business/providers/graph-reference';
import { SteeringFilters } from 'business/steering/components/steering-filters';
import { SteeringPageTemplate } from 'business/steering/components/steering-page-template';
import { SteeringGraphContainer } from 'business/steering/pages/graph-page/components/steering-graph-container';
import { SteeringHeader } from 'business/steering/pages/graph-page/components/steering-header';
import { SteeringFiltersProvider } from 'business/steering/providers/steering-filters-provider';
import { FilterCard } from 'ui/filter-card';

const SteeringGraphPage = () => {
  const { t } = useTranslation();

  const { currentConstructionSite } = useAppContext();
  invariant(currentConstructionSite, 'No construction site');

  return (
    <SingleGraphReferenceContextProvider>
      <SteeringFiltersProvider
        initialFilters={{
          filterType: 'date',
          dateRange: [dayjs().subtract(7, 'days'), dayjs()],
          position: 'rear',
          scale: currentConstructionSite.tolerance,
          tolerance: currentConstructionSite.tolerance,
        }}
      >
        <SteeringPageTemplate
          title={t('steering.graph.title')}
          header={<SteeringHeader />}
          filters={
            <FilterCard>
              <SteeringFilters scaleEnabled positionEnabled toleranceEnabled />
            </FilterCard>
          }
          printTitle={t('steering.graph.print.title')}
        >
          <SteeringGraphContainer />
        </SteeringPageTemplate>
      </SteeringFiltersProvider>
    </SingleGraphReferenceContextProvider>
  );
};

export default SteeringGraphPage;
