enum Routes {
  App = '/',
  LoginCallback = '/login/callback',

  Home = '/home',

  ReportUserHome = '/report/user-home',
  ReportDashboardVisualize = '/report/dashboard/visualize',
  ReportDashboardAnalyze = '/report/dashboard/analyze',
  ReportCreate = '/report/create',
  ReportView = '/report/:id/view',
  ReportAggregated = '/report/aggregated/:constructionSiteId/:startDate/:endDate/:type',

  ReportEdit = '/report/:id/edit',
  ReportEditFinishing = '/report/:id/edit/finishing',
  ReportEditEnd = '/report/:id/edit/end',

  ReportExportBatch = '/report/export/batch',
  ReportExportAggregated = '/report/export/aggregated',

  ReportList = '/report/list',
  ReportListValidated = '/report/list/validated',
  ReportListInProgress = '/report/list/in-progress',
  ReportListToValidate = '/report/list/to-validate',

  TaskAnalyzer = '/task/analyzer',
  TaskExport = '/task/export',
  Indicators = '/indicators',
  ProductionAnalyze = '/production/analyze',

  DataAnalysisGraph = '/data-analysis/graph/:mode',
  DataAnalysisModeSelection = '/data-analysis/mode',

  ProdPerfRingList = '/production-and-performance/ring/list',
  ProdPerfRingPerDay = '/production-and-performance/ring-per-day',
  ProdPerfExcavationBuildTime = '/production-and-performance/excavation-build-time',
  ProdPerfPlanning = '/production-and-performance/planning',

  SteeringGraph = '/steering/graph',
  SteeringTable = '/steering/table',

  // Old routes before refacto for redirect
  ReportHistory = '/report/history',
  ReportChart = '/report/chart/:id',
  ReportEndReportForm = '/report/chart/:id/finishing',
  ReportEndReportResult = '/report/chart/:id/end',

  ManagerReportValidationList = '/manager/validation-list',
  ManagerReportHistoryList = '/manager/history',
  ManagerReportChartResult = '/manager/chart/:id/end',
  ManagerAggregatedReport = '/manager/aggregated-report',
  ManagerBatchReport = '/manager/batch-report',
  ManagerPendingReport = '/manager/pending-report',
  ManagerNewReport = '/manager/create-report',

  ManagerReportPrint = '/pdf/shift-report/:id',
  ManagerAggregatedReportPrint = '/pdf/aggregated/:constructionSiteId/:startDate/:endDate/:type/',

  TaskAnalyzerOld = '/tasks-analyzer',
  TaskExportOld = '/manager/task-export',

  Dashboard = '/dashboard',

  // error
  NOT_FOUND = '/404',
  NO_CONSTRUCTION_SITE = '/no-construction-site',
  UNAUTHORIZED = '/unauthorized',
}

export default Routes;
