import { CloseOutlined } from '@ant-design/icons';
import { Empty, Flex, Table, message } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { ReactNode, useEffect, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { TFunction, useTranslation } from 'translations/hooks';

import { useAppContext } from 'business/contextProviders/useAppContext';
import ReportChart from 'business/shift-report/report/components/ReportChart';
import ActivityAndGraph from 'business/shift-report/report/pages/ReportAggregated/ActivityAndGraph';
import { Tables } from 'business/shift-report/report/pages/ReportAggregated/components/tables';
import { TopAndSecondLevelCharts } from 'business/shift-report/report/pages/ReportAggregated/components/top-and-second-level-charts';
import DisplayTasksFiles from 'business/shift-report/report/pages/ReportView/displayTasksFiles';
import {
  getTasksByCategory,
  tasksHasFiles,
} from 'business/shift-report/report/pages/ReportView/services';
import { TableData } from 'business/shift-report/report/pages/ReportView/types';
import {
  calculateBusinessDays,
  formatDurationToReadable,
  formatReportDateStringToLocalizedDateString,
  parseReportDateStringStrict,
} from 'business/shift-report/report/services/timeOperations';
import { AggregatedReportType } from 'business/shift-report/report/types';
import config from 'config';
import Routes from 'config/routes';
import { useReportControllerV1GetTopLevelCriticalTaskAggregate } from 'generated/apiComponents';
import {
  ActivityTreeFullFragment,
  ShiftFullFragment,
  ShiftReportAggregateGroupByEnum,
  TaskFullFragment,
  useGetAggregatedIndicatorResultsQuery,
  useGetDailyReportTimelineMetadataQuery,
  useGetTasksByConstructionSiteInRangeQuery,
  useShiftReportAggregatePdfDataQuery,
} from 'generated/graphql';
import logger from 'technical/logger';
import { useMediaType } from 'technical/media/hooks';
import useSearchParamQuery from 'technical/router/hooks/useSearchParamQuery';
import { NotFound } from 'technical/router/switch/reporting-no-match';
import AppLogo from 'ui/appLogo';
import Button from 'ui/button';
import { PrintButton } from 'ui/button/print-button';
import Loader from 'ui/loader';

import './index.scss';

type AggregatedTimeline = {
  shift: ShiftFullFragment;
  tasks: Array<TaskFullFragment>;
  activities: Array<ActivityTreeFullFragment>;
};

function findFirstActivityOfLevel(level: number, activity: any): any {
  if (activity.level === level) {
    return activity;
  }
  if (activity.parent && activity.level > level) {
    return findFirstActivityOfLevel(level, activity.parent);
  }
  return activity;
}

function aggregateTasksToLevel(
  tasks: Array<TaskFullFragment>,
  level: number,
): Array<TaskFullFragment> {
  return tasks.map((task) => {
    const activity = findFirstActivityOfLevel(level, task.activity);
    return { ...task, activityId: activity.id, activity };
  });
}

const getTableColumnsWithDescription = (t: TFunction) =>
  [
    {
      title: t('pages.print.table.startTime'),
      dataIndex: 'startTime',
    },
    {
      title: t('pages.print.table.endTime'),
      dataIndex: 'endTime',
    },
    {
      title: t('pages.print.table.spentTime'),
      dataIndex: 'spentTime',
      render: (node: number) => formatDurationToReadable(node),
    },
    {
      title: t('pages.print.table.ring'),
      dataIndex: 'ring',
    },
    {
      title: t('pages.print.table.critical'),
      dataIndex: 'critical',
      render: (check: boolean) => (check ? <CloseOutlined /> : null),
    },
    {
      title: t('pages.print.table.description'),
      dataIndex: 'description',
    },
    {
      title: t('pages.print.table.note'),
      dataIndex: 'note',
    },
  ] satisfies ColumnsType<TableData>;

function ReportAggregatedPage() {
  const { currentConstructionSite, constructionSites } = useAppContext();
  const { t } = useTranslation();
  const { constructionSiteId, startDate, endDate, type } = useParams<{
    constructionSiteId: string;
    startDate: string;
    endDate: string;
    type: AggregatedReportType;
  }>();

  invariant(
    constructionSiteId,
    "constructionSiteId isn't set within the route",
  );
  invariant(type, "type isn't set within the route");
  invariant(startDate, "startDate isn't set within the route");
  invariant(endDate, "endDate isn't set within the route");
  const searchParams = useSearchParamQuery();
  const { isMobile, isTablet } = useMediaType();

  let groupBy;

  switch (type) {
    case 'month':
      groupBy = ShiftReportAggregateGroupByEnum.Month;
      break;
    case 'week':
      groupBy = ShiftReportAggregateGroupByEnum.Week;
      break;
    default:
      groupBy = ShiftReportAggregateGroupByEnum.Day;
      break;
  }

  const startDateParsed = parseReportDateStringStrict(startDate);
  const endDateParsed = parseReportDateStringStrict(endDate);
  const nbDays = calculateBusinessDays(
    startDate,
    endDate,
    currentConstructionSite?.nbDaysPerWeek || 7,
  );
  const nbWeeks = endDateParsed.diff(startDateParsed, 'weeks');

  const showTimeline =
    searchParams.get('show-timeline') === 'true' || type === 'detailed-date';
  const includeImages =
    searchParams.get('include-images') === 'true' && type === 'detailed-date';

  const timelineAggregationLevel = type === 'detailed-date' ? 4 : 1;

  const { data: timelineData, loading: loadingTimeline } =
    useGetDailyReportTimelineMetadataQuery({
      variables: { constructionSiteId, date: startDate },
      skip: !showTimeline || !constructionSiteId || !startDateParsed.isValid(),
    });

  const aggregatedTimeline = useMemo((): AggregatedTimeline | null => {
    if (!timelineData) {
      return null;
    }

    const startTime: string | undefined =
      timelineData.tasks[0]?.shiftReport.shift.endTime;
    const endTime: string | undefined =
      timelineData.tasks[timelineData.tasks.length - 1]?.shiftReport.shift
        .endTime;

    if (!startTime || !endTime) {
      return null;
    }

    return {
      shift: {
        id: '00000000-0000-0000-0000-000000000000',
        name: 'Aggregated Shifts',
        constructionSiteId,
        startTime,
        endTime,
      },
      tasks: aggregateTasksToLevel(
        timelineData.tasks,
        timelineAggregationLevel,
      ),
      activities: timelineData.activities.concat(),
    };
  }, [timelineData, constructionSiteId, timelineAggregationLevel]);

  const { data } = useShiftReportAggregatePdfDataQuery({
    variables: {
      constructionSiteId,
      startDate,
      endDate,
      groupBy,
    },
    skip:
      !startDateParsed.isValid() ||
      !endDateParsed.isValid() ||
      Number.isNaN(nbDays),
    fetchPolicy: 'no-cache',
  });

  const {
    isLoading: aggregatedTaskLoading,
    error: aggregatedTaskError,
    data: aggregatedTaskData,
  } = useReportControllerV1GetTopLevelCriticalTaskAggregate({
    queryParams: {
      constructionSiteId,
      startDate,
      endDate,
    },
  });

  const { data: tasksInRangeData, error: tasksInRangeError } =
    useGetTasksByConstructionSiteInRangeQuery({
      variables: {
        constructionSiteId,
        startDate,
        endDate,
      },
      skip:
        !startDateParsed.isValid() ||
        !endDateParsed.isValid() ||
        Number.isNaN(nbDays),
    });

  const {
    loading: loadingIndicatorResults,
    error: errorIndicatorResults,
    data: dataIndicatorResults,
  } = useGetAggregatedIndicatorResultsQuery({
    variables: {
      constructionSiteId,
      startDate,
      endDate,
    },
    skip:
      !startDateParsed.isValid() ||
      !endDateParsed.isValid() ||
      Number.isNaN(nbDays),
    fetchPolicy: 'network-only',
  });

  useEffect(
    function alertUser() {
      if (aggregatedTaskError || tasksInRangeError) {
        message.error(t('errors.error_generic'));
        logger.error(aggregatedTaskError);
      }
      if (errorIndicatorResults) {
        message.error(t('errors.error_generic'));
        logger.error(errorIndicatorResults);
      }
    },
    [aggregatedTaskError, tasksInRangeError, t, errorIndicatorResults],
  );

  const aggregatedReport = data?.shiftReportAggregatePdfData;

  const name = aggregatedReport?.name;
  const logo = aggregatedReport?.logo;

  const tasksInRange = tasksInRangeData?.task;
  let totalExcavationTime = dayjs.duration(0, 'minute');
  let totalBuildTime = dayjs.duration(0, 'minute');
  let totalTime = dayjs.duration(0, 'minute');
  let totalCriticalExcavationTime = dayjs.duration(0, 'minute');
  let totalCriticalBuildTime = dayjs.duration(0, 'minute');
  let totalCriticalTime = dayjs.duration(0, 'minute');

  tasksInRange?.forEach((task) => {
    totalTime = totalTime.add(task.duration!, 'minute');
    if (task.activity.type === 'excavation') {
      totalExcavationTime = totalExcavationTime.add(task.duration!, 'minute');
    }
    if (task.activity.type === 'build') {
      totalBuildTime = totalBuildTime.add(task.duration!, 'minute');
    }
    if (task.critical) {
      totalCriticalTime = totalCriticalTime.add(task.duration!, 'minute');
      if (task.activity.type === 'excavation') {
        totalCriticalExcavationTime = totalCriticalExcavationTime.add(
          task.duration!,
          'minute',
        );
      }
      if (task.activity.type === 'build') {
        totalCriticalBuildTime = totalCriticalBuildTime.add(
          task.duration!,
          'minute',
        );
      }
    }
  });

  const handlePrint = () => {
    window.print();
  };

  if (
    !aggregatedTaskLoading &&
    !constructionSites.find(({ id }) => id === constructionSiteId)
  ) {
    return <NotFound />;
  }

  const indicators =
    dataIndicatorResults?.calculateAggregatedIndicatorResults?.data;

  return (
    <div className="report-aggregated-page page-appear">
      {(aggregatedTaskLoading ||
        loadingIndicatorResults ||
        loadingTimeline) && <Loader />}
      {!aggregatedTaskLoading && !aggregatedReport && (
        <Empty
          className="empty-warning"
          description={<span>{t('pages.print.empty.description')}</span>}
        >
          <Link to={Routes.ReportExportAggregated}>
            <Button type="primary" className="button-center">
              {t('common.go_back')}
            </Button>
          </Link>
        </Empty>
      )}
      {aggregatedReport && (
        <>
          {isMobile ? null : (
            <Flex className="controls">
              <Link to={Routes.ReportExportAggregated}>
                <Button type="primary" ghost>
                  {t('common.go_back')}
                </Button>
              </Link>
              {isTablet ? null : (
                <PrintButton onClick={handlePrint} type="primary" />
              )}
            </Flex>
          )}
          <div className="header">
            <div className="header-left">
              <AppLogo />
              {logo && (
                <AppLogo
                  alt="Construction site logo"
                  path={`${config.gcp.publicUri}/${logo}`}
                />
              )}
            </div>
            <h1 id="pdfReportPrint">
              {t(`pages.print.${type}`)} - {name}
            </h1>
            {startDate !== endDate && (
              <h2>
                {t('pages.print.rangeTitle', {
                  startDate:
                    formatReportDateStringToLocalizedDateString(startDate),
                  endDate: formatReportDateStringToLocalizedDateString(endDate),
                })}
              </h2>
            )}
            {startDate === endDate && (
              <h2>{formatReportDateStringToLocalizedDateString(startDate)}</h2>
            )}
          </div>

          <Tables
            type={type}
            aggregatedReport={aggregatedReport}
            nbDays={nbDays}
            nbWeeks={nbWeeks}
            indicators={indicators}
          />

          {showTimeline && aggregatedTimeline && (
            <ReportChart
              shift={aggregatedTimeline.shift}
              date={startDate}
              activities={aggregatedTimeline.activities}
              tasks={aggregatedTimeline.tasks}
              criticalPathValid={false}
              mode="view"
            />
          )}
          {type === 'detailed-date' ? (
            timelineData &&
            timelineData.tasks.length > 0 && (
              <>
                <div className="bloc" key="tasks-tables">
                  <h1>{t('pages.print.activityNotes')}</h1>
                  {timelineData.activities.reduce((tableList, activity) => {
                    const tableData = getTasksByCategory(
                      activity.id,
                      timelineData.tasks,
                    );
                    if (!tableData) {
                      return tableList;
                    }
                    return [
                      tableList,
                      <Table
                        key={activity.name}
                        rowKey="key"
                        columns={getTableColumnsWithDescription(t)}
                        dataSource={tableData}
                        bordered
                        pagination={false}
                        title={() => (
                          <h3 className="activity-header">{activity.name}</h3>
                        )}
                        size="small"
                        scroll={{ x: isMobile && !isTablet ? true : undefined }}
                      />,
                    ];
                  }, [] as ReactNode[])}
                </div>
                <TopAndSecondLevelCharts tasksAggregate={aggregatedTaskData} />
                {includeImages && tasksHasFiles(timelineData.tasks) && (
                  <div className="bloc" key="additional-files">
                    <h1>{t('pages.print.additionnalImages')}</h1>
                    <DisplayTasksFiles tasks={timelineData.tasks} />
                  </div>
                )}
              </>
            )
          ) : (
            <ActivityAndGraph
              topLevelTasksAggregate={aggregatedReport.topLevelTasksAggregate}
              criticalTasksAggregate={aggregatedTaskData}
              critical={false}
            />
          )}
        </>
      )}
    </div>
  );
}

export default ReportAggregatedPage;
