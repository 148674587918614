import { PoweroffOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, Flex, MenuProps, Typography } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'translations/hooks';

import { useAppContext } from 'business/contextProviders/useAppContext';
import { login, logout, signUp } from 'business/user/services/authentication';
import config from 'config';
import AppLogo from 'ui/appLogo';
import Button from 'ui/button';
import { ConstructionSiteSelector } from 'ui/construction-site-selector';
import { HeaderContainer } from 'ui/header-container';
import { LanguageSelector } from 'ui/language-selector';
import { Menu } from 'ui/menu';
import { PageTitle } from 'ui/page-title';

import styles from './index.module.scss';

export interface NavSection {
  label: string;
  path: string;
}

interface Props {
  isConnected?: boolean;
  title: string;
  constructionSiteId?: string;
  menuState: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  };
}

export default function NavBar({
  isConnected,
  title,
  constructionSiteId,
  menuState,
}: Props) {
  const { t } = useTranslation();
  const {
    user,
    currentConstructionSite,
    constructionSites,
    setCurrentConstructionSiteId,
  } = useAppContext();

  useEffect(() => {
    if (constructionSiteId) {
      setCurrentConstructionSiteId(constructionSiteId);
    }
  });

  const userInitals =
    user?.firstName && user?.lastName ? (
      `${user.firstName[0]}${user.lastName[0]}`
    ) : (
      <UserOutlined />
    );

  const userNameDisplayed =
    user?.firstName && user?.lastName
      ? `${user.firstName} ${user.lastName}`
      : user?.email;

  const logo = currentConstructionSite?.logo;
  const name = currentConstructionSite?.name;

  function handleLoginOnClick() {
    login();
  }
  function handleRegisterOnClick() {
    signUp();
  }

  const menu: MenuProps['items'] = [
    { key: 'name', label: userNameDisplayed, disabled: true },
    {
      key: 'disconnect',
      danger: true,
      onClick: () => logout(),
      label: (
        <>
          <PoweroffOutlined style={{ fontSize: '14px' }} /> {t('user.logout')}
        </>
      ),
    },
    {
      key: 'version',
      disabled: true,
      label: (
        <Typography.Text disabled copyable>
          {config.version}
        </Typography.Text>
      ),
    },
  ];

  return (
    <HeaderContainer>
      <Flex flex={1} justify="flex-start" align="center" gap={20}>
        <AppLogo />
        {logo && (
          <AppLogo
            alt="Construction site logo"
            path={`${config.gcp.publicUri}/${logo}`}
          />
        )}
        {!!constructionSites.length ? <Menu menuState={menuState} /> : null}
      </Flex>
      <Flex
        vertical
        justify="center"
        align="center"
        wrap="wrap"
        gap={8}
        className={styles.titleContainer}
      >
        {!constructionSiteId && constructionSites?.length > 1 ? (
          <ConstructionSiteSelector />
        ) : null}
        {constructionSiteId ||
        (constructionSites && constructionSites.length === 1) ? (
          <h1 className={styles.constructionSiteTitleWithoutSelect}>{name}</h1>
        ) : null}
        <PageTitle title={title} />
      </Flex>
      <Flex flex={1} justify="flex-end" align="center">
        <LanguageSelector />
        {isConnected ? (
          <Dropdown menu={{ items: menu }} trigger={['click']}>
            <button
              style={{ border: 'initial', background: 'initial' }}
              onClick={(e) => e.preventDefault()}
            >
              <Avatar style={{ backgroundColor: '#003a8c' }}>
                {userInitals}
              </Avatar>
            </button>
          </Dropdown>
        ) : (
          <>
            <Button onClick={handleLoginOnClick}>
              {t('notConnected.login.button')}
            </Button>
            <Button
              style={{ marginLeft: '16px' }}
              onClick={handleRegisterOnClick}
              type="primary"
            >
              {t('notConnected.signup')}
            </Button>
          </>
        )}
      </Flex>
    </HeaderContainer>
  );
}
