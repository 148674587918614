import { Form, Radio, Select } from 'antd';
import { GoogleChartWrapperChartType } from 'react-google-charts/dist/types';
import { useTranslation } from 'translations/hooks';

import {
  ringRangeIsValidNumbers,
  ringRangeIsValidValue,
} from 'business/shift-report/task/pages/TasksAnalyzer/services/inputServices';
import {
  ACTIVITY_LEVEL_AGGREGATION,
  CHART_TYPES,
  DATE_CRITICAL_TASKS_OPTIONS,
  GROUP_BY_OPTIONS,
  RING_CRITICAL_TASKS_OPTIONS,
  TaskAnalyzerQueryOptions,
} from 'business/shift-report/task/pages/TasksAnalyzer/types';
import { ShiftReportGroupByEnum } from 'generated/graphql';
import { DateRangePicker } from 'ui/form/date-range-picker';
import NumberRangeInput from 'ui/form/number-range-input';

import styles from './index.module.scss';
import InputRow from './inputRow';

const inputStyle = { width: '100%' };

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

interface TaskAnalyzerInputsProps {
  queryOptions: TaskAnalyzerQueryOptions;
  setQueryOptions: (options: TaskAnalyzerQueryOptions) => void;
}

function TaskAnalyzerInputs({
  queryOptions,
  setQueryOptions,
}: TaskAnalyzerInputsProps) {
  const { t } = useTranslation();

  const {
    analysisType,
    dateRangeValue,
    ringRangeValue,
    chartType,
    groupByType,
    dateCriticalType,
    ringCriticalType,
    activityLevelAggregation,
    vChartScale,
  } = queryOptions;

  const criticalTypeSelector =
    analysisType === 'date' ? (
      <Select
        value={dateCriticalType}
        style={inputStyle}
        onChange={(value) =>
          setQueryOptions({
            ...queryOptions,
            dateCriticalType: value,
          })
        }
      >
        {DATE_CRITICAL_TASKS_OPTIONS.map(({ value, label }) => (
          <Select.Option key={value} value={value}>
            {t(label)}
          </Select.Option>
        ))}
      </Select>
    ) : (
      <Select
        value={ringCriticalType}
        style={inputStyle}
        onChange={(value) =>
          setQueryOptions({
            ...queryOptions,
            ringCriticalType: value,
          })
        }
      >
        {RING_CRITICAL_TASKS_OPTIONS.map(({ value, label }) => (
          <Select.Option key={value} value={value}>
            {t(label)}
          </Select.Option>
        ))}
      </Select>
    );

  return (
    <>
      <InputRow
        inputLeft={
          <Form.Item
            {...formItemLayout}
            label={t('tasksAnalyzer.labels.analyzeType')}
          >
            <Radio.Group
              style={inputStyle}
              options={[
                {
                  label: t('tasksAnalyzer.analyzeTypeOptions.date'),
                  value: 'date',
                },
                {
                  label: t('tasksAnalyzer.analyzeTypeOptions.ring'),
                  value: 'ring',
                },
              ]}
              onChange={(e) =>
                setQueryOptions({
                  ...queryOptions,
                  analysisType: e.target.value,
                })
              }
              value={analysisType}
              optionType="button"
              buttonStyle="solid"
            />
          </Form.Item>
        }
        inputRight={
          analysisType === 'date' ? (
            <Form.Item
              {...formItemLayout}
              label={t('tasksAnalyzer.labels.dateRange')}
            >
              <DateRangePicker
                className={styles.rangeInput}
                format="L"
                value={dateRangeValue}
                onChange={(values) =>
                  setQueryOptions({
                    ...queryOptions,
                    dateRangeValue: values,
                  })
                }
              />
            </Form.Item>
          ) : (
            <Form.Item
              {...formItemLayout}
              label={t('tasksAnalyzer.labels.ringRange')}
              help={
                ringRangeIsValidNumbers(ringRangeValue) &&
                !ringRangeIsValidValue(ringRangeValue)
                  ? t('tasksAnalyzer.input.ringRange.helper')
                  : null
              }
              shouldUpdate
            >
              <NumberRangeInput
                className={styles.numberInput}
                value={ringRangeValue}
                onChange={(values) =>
                  setQueryOptions({
                    ...queryOptions,
                    ringRangeValue: values,
                  })
                }
                leftInputProps={{
                  style: inputStyle,
                  placeholder: t(
                    'tasksAnalyzer.input.ringRange.startPlaceholder',
                  ),
                }}
                rightInputProps={{
                  style: inputStyle,
                  placeholder: t(
                    'tasksAnalyzer.input.ringRange.endPlaceholder',
                  ),
                }}
              />
            </Form.Item>
          )
        }
      />
      <InputRow
        inputLeft={
          <Form.Item
            {...formItemLayout}
            label={t('tasksAnalyzer.labels.chartType')}
          >
            <Select<GoogleChartWrapperChartType>
              value={chartType}
              style={inputStyle}
              onChange={(value) =>
                setQueryOptions({ ...queryOptions, chartType: value })
              }
            >
              {CHART_TYPES.map(({ label, type }) => (
                <Select.Option key={type} value={type}>
                  {t(label)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        }
        inputRight={
          <Form.Item
            {...formItemLayout}
            label={t('tasksAnalyzer.labels.tasksType')}
          >
            {criticalTypeSelector}
          </Form.Item>
        }
      />
      <InputRow
        inputLeft={
          <Form.Item
            {...formItemLayout}
            label={t('tasksAnalyzer.labels.aggregationLevelType')}
          >
            <Select
              value={activityLevelAggregation}
              style={inputStyle}
              onChange={(value) =>
                setQueryOptions({
                  ...queryOptions,
                  activityLevelAggregation: value,
                })
              }
            >
              {ACTIVITY_LEVEL_AGGREGATION.map(({ value, label }) => (
                <Select.Option key={value} value={value}>
                  {t(label, { level: value })}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        }
        inputRight={
          analysisType === 'date' ? (
            <Form.Item
              {...formItemLayout}
              label={t('tasksAnalyzer.labels.aggregationType')}
            >
              <Select<ShiftReportGroupByEnum>
                defaultValue={ShiftReportGroupByEnum.Day}
                style={inputStyle}
                onChange={(value) =>
                  setQueryOptions({ ...queryOptions, groupByType: value })
                }
                value={groupByType}
                disabled={chartType === 'PieChart'}
              >
                {GROUP_BY_OPTIONS.map(({ value, label }) => (
                  <Select.Option key={value} value={value}>
                    {t(label)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          ) : undefined
        }
      />
      {chartType === 'ColumnChart' && (
        <InputRow
          inputLeft={
            <Form.Item
              {...formItemLayout}
              label={t('tasksAnalyzer.labels.vChartRange')}
              help={
                ringRangeIsValidNumbers(vChartScale) &&
                !ringRangeIsValidValue(vChartScale)
                  ? t('tasksAnalyzer.input.vChartRange.helper')
                  : null
              }
              shouldUpdate
            >
              <NumberRangeInput
                value={vChartScale}
                className={styles.numberInput}
                onChange={(values) =>
                  setQueryOptions({
                    ...queryOptions,
                    vChartScale: values,
                  })
                }
                leftInputProps={{
                  style: inputStyle,
                  placeholder: t(
                    'tasksAnalyzer.input.vChartRange.minPlaceholder',
                  ),
                }}
                rightInputProps={{
                  style: inputStyle,
                  placeholder: t(
                    'tasksAnalyzer.input.vChartRange.maxPlaceholder',
                  ),
                }}
              />
            </Form.Item>
          }
        />
      )}
    </>
  );
}

export default TaskAnalyzerInputs;
