import { Flex, message, Result, Select } from 'antd';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'translations/hooks';

import { useAppContext } from 'business/contextProviders/useAppContext';
import { ReportListPageTemplate } from 'business/shift-report/report/components/report-list-template';
import ReportList, {
  DEFAULT_PAGE_LIMIT,
} from 'business/shift-report/report/components/ReportList';
import { SEARCH_PARAM_KEY_MAP } from 'business/shift-report/report/hooks/constants';
import { useFilterParams } from 'business/shift-report/report/hooks/use-filter-params';
import Routes from 'config/routes';
import {
  Maybe,
  Scalars,
  ShiftReport_Bool_Exp,
  useGetManagerReportHistoryQuery,
} from 'generated/graphql';
import { useMediaType } from 'technical/media/hooks';
import { formatUserName, SIMPLE_DATE_FORMAT } from 'technical/string/formatter';
import Button from 'ui/button';
import { MainPageButton } from 'ui/button/main-page-button';
import { FilterCard } from 'ui/filter-card';
import { DateRangePicker } from 'ui/form/date-range-picker';
import SectionTitle from 'ui/title/sectionTitle';

import styles from './index.module.scss';

const mapList = ({
  id,
  name,
}: {
  id: Scalars['uuid']['output'];
  name: string;
}) => (
  <Select.Option key={id} value={id}>
    {name}
  </Select.Option>
);

const userMapList = ({
  id,
  ...rest
}: {
  id: Scalars['uuid']['output'];
  email: string;
  firstName?: Maybe<string>;
  lastName?: Maybe<string>;
}) => (
  <Select.Option value={id} key={id}>
    {formatUserName(rest)}
  </Select.Option>
);

function buildBooleanFilter<Keys extends string>(
  filters: Array<[key: Keys, value: string | undefined]>,
): Partial<Record<Keys, { _eq: string }>> {
  return Object.fromEntries(
    filters
      .map(([key, value]) =>
        value === undefined ? undefined : [key, { _eq: value }],
      )
      .filter(Boolean) as Array<[string, { _eq: string }]>,
  ) as Partial<Record<Keys, { _eq: string }>>;
}

function ReportListValidated() {
  const { t } = useTranslation();
  const { currentConstructionSite } = useAppContext();
  const { isMobile } = useMediaType();

  const {
    currentPage,
    startDate,
    endDate,
    operator,
    shiftLeader,
    shiftEngineer,
    shiftManager,
    shift,
    team,
    updateSearchParam,
    emptyFilters,
    getQueryParamString,
  } = useFilterParams();

  const isManagerRequired = currentConstructionSite?.isManagerRequiredInReport;
  const isEngineerRequired =
    currentConstructionSite?.isEngineerRequiredInReport;

  const filter = useMemo(() => {
    const object: ShiftReport_Bool_Exp = buildBooleanFilter([
      ['operatorId', operator],
      ['shiftLeaderId', shiftLeader],
      ['shiftEngineerId', shiftEngineer],
      ['shiftManagerId', shiftManager],
      ['shiftId', shift],
      ['teamId', team],
    ]);

    if (startDate && endDate) {
      object.date = { _gte: startDate, _lte: endDate };
    }

    return object;
  }, [
    operator,
    shiftLeader,
    shiftEngineer,
    shiftManager,
    shift,
    team,
    startDate,
    endDate,
  ]);

  const { data, loading, error, refetch } = useGetManagerReportHistoryQuery({
    variables: {
      limit: DEFAULT_PAGE_LIMIT,
      offset: (currentPage - 1) * DEFAULT_PAGE_LIMIT,
      constructionSiteId: currentConstructionSite?.id,
      filter,
    },
    fetchPolicy: 'network-only',
    skip: !currentConstructionSite?.id,
  });

  useEffect(
    function alertUser() {
      if (error) {
        message.error(error.message);
      }
    },
    [error],
  );

  const resetFilters = () => {
    emptyFilters();
  };

  const emptyListComponent = (
    <Result
      status="404"
      title={t('pages.report.list.filterNotFound.title')}
      subTitle={
        startDate === null || endDate === null
          ? t('pages.report.list.noReport.label')
          : t('pages.report.list.filterNotFound.label')
      }
    />
  );

  return (
    <ReportListPageTemplate>
      <SectionTitle
        label={t('pages.manager.history.title')}
        className={styles.title}
        leftButton={
          isMobile ? null : (
            <MainPageButton
              to={{ pathname: Routes.ReportDashboardVisualize }}
            />
          )
        }
      />
      <div className={styles.filterCard}>
        <FilterCard>
          <Flex vertical gap={24} align="center">
            <Flex justify="center" wrap="wrap" gap={16}>
              <DateRangePicker
                className={styles.optionInput}
                format="L"
                onChange={(value) => {
                  if (value) {
                    updateSearchParam(
                      SEARCH_PARAM_KEY_MAP.startDate,
                      value[0]?.format(SIMPLE_DATE_FORMAT),
                    );
                    updateSearchParam(
                      SEARCH_PARAM_KEY_MAP.endDate,
                      value[1]?.format(SIMPLE_DATE_FORMAT),
                    );
                  } else {
                    updateSearchParam(SEARCH_PARAM_KEY_MAP.startDate, null);
                    updateSearchParam(SEARCH_PARAM_KEY_MAP.endDate, null);
                  }
                }}
                value={[
                  startDate ? dayjs(startDate) : null,
                  endDate ? dayjs(endDate) : null,
                ]}
                allowClear={false}
              />
              <Select
                className={styles.optionInput}
                onChange={(value) =>
                  updateSearchParam(SEARCH_PARAM_KEY_MAP.operator, value)
                }
                placeholder={t('pages.form.operator.placeholder')}
                value={operator}
                loading={loading}
                allowClear={false}
              >
                {data?.operators.map(mapList)}
              </Select>
              <Select
                className={styles.optionInput}
                onChange={(value) =>
                  updateSearchParam(SEARCH_PARAM_KEY_MAP.shiftLeader, value)
                }
                placeholder={t('pages.form.shiftLeader.placeholder')}
                value={shiftLeader}
                loading={loading}
                allowClear={false}
              >
                {data?.shiftLeaders.map(mapList)}
              </Select>
              {isEngineerRequired && (
                <Select
                  className={styles.optionInput}
                  onChange={(value) =>
                    updateSearchParam(SEARCH_PARAM_KEY_MAP.shiftEngineer, value)
                  }
                  placeholder={t('pages.form.shiftEngineer.placeholder')}
                  value={shiftEngineer}
                  loading={loading}
                  allowClear={false}
                >
                  {data?.shiftEngineers.map(userMapList)}
                </Select>
              )}
              {isManagerRequired && (
                <Select
                  className={styles.optionInput}
                  onChange={(value) =>
                    updateSearchParam(SEARCH_PARAM_KEY_MAP.shiftManager, value)
                  }
                  placeholder={t('pages.form.shiftManager.placeholder')}
                  value={shiftManager}
                  loading={loading}
                  allowClear={false}
                >
                  {data?.shiftManagers.map(userMapList)}
                </Select>
              )}
              <Select
                className={styles.optionInput}
                onChange={(value) =>
                  updateSearchParam(SEARCH_PARAM_KEY_MAP.shift, value)
                }
                placeholder={t('pages.form.shift.placeholder')}
                value={shift}
                loading={false}
                allowClear={false}
              >
                {data?.shift.map(mapList)}
              </Select>
              {data?.team.length !== 0 && (
                <Select
                  className={styles.optionInput}
                  onChange={(value) =>
                    updateSearchParam(SEARCH_PARAM_KEY_MAP.team, value)
                  }
                  placeholder={t('pages.form.team.placeholder')}
                  value={team}
                  loading={false}
                  allowClear={false}
                >
                  {data?.team.map(mapList)}
                </Select>
              )}
            </Flex>

            <div>
              <Button onClick={resetFilters}>
                {t('pages.report.list.filterNotFound.button')}
              </Button>
            </div>
          </Flex>
        </FilterCard>
      </div>
      <Flex
        flex={1}
        gap={20}
        vertical
        className={classNames(styles.reportListValidatedPage)}
      >
        <ReportList
          loading={loading}
          refetchList={refetch}
          shiftReports={data?.shiftReports}
          paginationMeta={{
            shiftReportCount: data?.shiftReportAggregate.aggregate?.count || 0,
          }}
          emptyListComponent={emptyListComponent}
          routeRedirection={`${Routes.ReportListValidated}?${getQueryParamString()}`}
        />
        <MainPageButton
          to={Routes.ReportDashboardVisualize}
          className="button-center"
        />
      </Flex>
    </ReportListPageTemplate>
  );
}

export default ReportListValidated;
