import {
  AgCartesianAxisOptions,
  AgCartesianSeriesOptions,
  AgChartOptions,
} from 'ag-charts-enterprise';
import { AgCharts } from 'ag-charts-react';
import dayjs from 'dayjs';
import { TFunction, useTranslation } from 'translations/hooks';

import { ProgressHistoryGraphPoint } from 'business/dashboard/types';
import { formatDateToDayAndMonth } from 'technical/time-utils/format-date-to-day-and-month';
import { colorPrimary } from 'ui/theme/colors';

type ProgressHistoryGraphProps = {
  progressHistoryGraphData: ProgressHistoryGraphPoint[];
};

const tooltipRenderer = (params: any, t: TFunction) => {
  return {
    data: [
      {
        label: '',
        value: t('productionAndPerformance.ringPerDay.graph.tooltip.ring', {
          count: parseInt(params.datum[params.yKey]),
        }),
      },
    ],
  };
};

const progressHistoryGraphSeriesOptions = (t: TFunction) =>
  [
    {
      type: 'bar',
      xKey: 'date',
      yKey: 'count',
      tooltip: { renderer: (params) => tooltipRenderer(params, t) },
      fill: colorPrimary,
    },
  ] satisfies AgCartesianSeriesOptions[];

const progressHistoryGraphAxesOptions = () => {
  const today = dayjs();

  return [
    {
      type: 'time',
      position: 'bottom',
      nice: false,
      label: {
        formatter: ({ value }) => formatDateToDayAndMonth(value),
        avoidCollisions: false,
      },
      interval: {
        step: 1,
      },
      max: today.toDate(),
      min: today.subtract(7, 'days').toDate(),
    },
    {
      type: 'number',
      position: 'left',
      nice: false,
      interval: {
        step: 1,
      },
    },
  ] satisfies AgCartesianAxisOptions[];
};

export function ProgressHistoryGraph({
  progressHistoryGraphData,
}: ProgressHistoryGraphProps) {
  const { t } = useTranslation();

  const chartOptions = {
    data: progressHistoryGraphData,
    axes: progressHistoryGraphAxesOptions(),
    series: progressHistoryGraphSeriesOptions(t),
  } satisfies AgChartOptions;

  return <AgCharts options={chartOptions} />;
}
