import { DatePicker as AntDatePicker } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import { HTMLInputTypeAttribute } from 'react';

import { useMediaType } from 'technical/media/hooks';

import { DateRangeValue, MobileRangePicker } from './mobile-range-picker';

interface DateRangePickerProps {
  value?: DateRangeValue;
  onChange?: (value: DateRangeValue) => void;
  disabledDate?: RangePickerProps['disabledDate'];
  picker?: Extract<HTMLInputTypeAttribute, 'date' | 'week' | 'month'>;
  format?: string;
  className?: string;
  allowClear?: boolean;
  allowEmpty?: boolean;
}

export const DateRangePicker = (props: DateRangePickerProps) => {
  const { isMobile } = useMediaType();

  if (isMobile) {
    return <MobileRangePicker {...props} />;
  }

  return <AntDatePicker.RangePicker {...props} />;
};
