import { ReloadOutlined } from '@ant-design/icons';
import invariant from 'tiny-invariant';
import { useTranslation } from 'translations/hooks';

import { useAppContext } from 'business/contextProviders/useAppContext';
import {
  useGraphSet,
  useGraphSetDispatch,
} from 'business/data-analysis/pages/graph/hooks/graph-context/contexts';
import { GraphSetDispatchActions } from 'business/data-analysis/pages/graph/hooks/graph-context/types';
import { useGraphSetDataDispatch } from 'business/data-analysis/pages/graph/hooks/graph-data-context/contexts';
import { GraphSetDataDispatchActions } from 'business/data-analysis/pages/graph/hooks/graph-data-context/types';
import { useGetGraphValues } from 'business/data-analysis/pages/graph/hooks/use-get-graph-values';
import { useMode } from 'business/data-analysis/pages/graph/hooks/use-mode';
import { useQueryParams } from 'business/data-analysis/pages/graph/hooks/use-query-params';
import { GraphFilters } from 'business/data-analysis/pages/graph/types';
import { isFetchingDisabled } from 'business/data-analysis/services/is-fetching-disabled';
import Button from 'ui/button';

import styles from './index.module.scss';

interface Props {
  atLeastOneParameterIsSelected: boolean;
  filters: GraphFilters;
}

export const GenerateGraphsButton = ({
  atLeastOneParameterIsSelected,
  filters,
}: Props) => {
  const { t } = useTranslation();

  const { currentConstructionSite } = useAppContext();
  invariant(currentConstructionSite, 'No construction site id');

  const mode = useMode();

  const graphSet = useGraphSet();
  const dispatchGraphSet = useGraphSetDispatch();

  const dispatchGraphSetData = useGraphSetDataDispatch();

  const queryParams = useQueryParams(
    currentConstructionSite.id,
    filters,
    graphSet,
  );

  const { getUpdatedGraphByMode, isFetching } = useGetGraphValues(queryParams);

  const generateGraphs = async () => {
    dispatchGraphSet({
      type: GraphSetDispatchActions.UpdateLoadingStatus,
      isLoading: true,
    });
    try {
      const generateGraphsResponse = await getUpdatedGraphByMode(mode);
      dispatchGraphSetData({
        type: GraphSetDataDispatchActions.UpdateValues,
        values: generateGraphsResponse?.values ?? [],
      });
    } finally {
      dispatchGraphSet({
        type: GraphSetDispatchActions.UpdateLoadingStatus,
        isLoading: false,
      });
    }
  };

  const generateButtonIsDisabled = isFetchingDisabled({
    isFetching,
    atLeastOneParameterIsSelected,
    filters,
    mode,
  });

  return (
    <Button
      icon={<ReloadOutlined />}
      onClick={generateGraphs}
      disabled={generateButtonIsDisabled}
      className={styles.generateGraphButton}
    >
      {t('dataAnalysis.actions.generateGraphs')}
    </Button>
  );
};
