import {
  BarChartOutlined,
  CalendarOutlined,
  ClockCircleOutlined,
  LayoutOutlined,
  LineChartOutlined,
  PieChartOutlined,
} from '@ant-design/icons';
import { Row } from 'antd';
import React from 'react';
import { Navigate, generatePath } from 'react-router-dom';
import { useTranslation } from 'translations/hooks';

import { useAppContext } from 'business/contextProviders/useAppContext';
import {
  getEnabledModuleList,
  usePermissions,
} from 'business/contextProviders/usePermissions';
import { PlotterMode } from 'business/data-analysis/constants';
import Routes from 'config/routes';
import { Module_Enum } from 'generated/graphql';
import { useMediaType } from 'technical/media/hooks';
import ListIcon from 'ui/icons/list';
import ScatterPlotIcon from 'ui/icons/scatter-plot';
import ShiftReportIcon from 'ui/icons/shift-report';
import SteeringDataIcon from 'ui/icons/steering-data';
import MenuColumn from 'ui/menu/menu-column';
import MenuContent from 'ui/menu/menu-content';
import { MenuDrawer } from 'ui/menu/menu-drawer';
import MenuEntry from 'ui/menu/menu-entry';
import MenuSection from 'ui/menu/menu-section';

const SHIFT_REPORT_PERMISSIONS = [Module_Enum.ShiftReport];
const DATA_ANALYSIS_PERMISSIONS = [Module_Enum.DataAnalysis];
const PROD_PERF_PERMISSIONS = [Module_Enum.ProdPerf];
const STEERING_PERMISSIONS = [Module_Enum.Steering];

const ALL_PERMISSIONS = [
  ...SHIFT_REPORT_PERMISSIONS,
  ...DATA_ANALYSIS_PERMISSIONS,
  ...PROD_PERF_PERMISSIONS,
  ...STEERING_PERMISSIONS,
];

const REPORTING_SECTION_PERMISSIONS = [...SHIFT_REPORT_PERMISSIONS];
const DATA_ANALYSIS_SECTION_PERMISSIONS = [...DATA_ANALYSIS_PERMISSIONS];
const PROD_PERF_SECTION_PERMISSIONS = [...PROD_PERF_PERMISSIONS];
const STEERING_SECTION_PERMISSIONS = [...STEERING_PERMISSIONS];

const MenuContentPermissions = [
  ...REPORTING_SECTION_PERMISSIONS,
  ...DATA_ANALYSIS_SECTION_PERMISSIONS,
  ...PROD_PERF_SECTION_PERMISSIONS,
  ...STEERING_SECTION_PERMISSIONS,
];

interface SectionProps {
  onClick: () => void;
  withDescription: boolean;
}

const DataAnalysisSection = ({ onClick, withDescription }: SectionProps) => {
  const { t } = useTranslation();

  return (
    <MenuSection
      title={t('user.menu.sections.dataAnalysis.title')}
      permissions={DATA_ANALYSIS_SECTION_PERMISSIONS}
    >
      <MenuEntry
        route={generatePath(Routes.DataAnalysisGraph, {
          mode: PlotterMode.TIME_PERIOD,
        })}
        icon={<LineChartOutlined />}
        title={t('user.menu.sections.dataAnalysis.timePeriod.title')}
        description={t(
          'user.menu.sections.dataAnalysis.timePeriod.description',
        )}
        permissions={DATA_ANALYSIS_PERMISSIONS}
        onClick={onClick}
        withDescription={withDescription}
      />
      <MenuEntry
        route={generatePath(Routes.DataAnalysisGraph, {
          mode: PlotterMode.RING,
        })}
        icon={<BarChartOutlined />}
        title={t('user.menu.sections.dataAnalysis.ring.title')}
        description={t('user.menu.sections.dataAnalysis.ring.description')}
        permissions={DATA_ANALYSIS_PERMISSIONS}
        onClick={onClick}
        withDescription={withDescription}
      />
    </MenuSection>
  );
};

const ReportingSection = ({ onClick, withDescription }: SectionProps) => {
  const { t } = useTranslation();
  const { currentConstructionSite } = useAppContext();
  const { modules } = usePermissions();

  if (!currentConstructionSite) {
    return <Navigate to={Routes.NO_CONSTRUCTION_SITE} />; // should be handled by router cf: noConstructionSite
  }

  if (modules.SHIFT_REPORT?.isConstructionSiteUser) {
    return (
      <MenuSection
        title={t('user.menu.sections.reporting.title')}
        permissions={REPORTING_SECTION_PERMISSIONS}
      >
        <MenuEntry
          route={Routes.ReportUserHome}
          icon={<ShiftReportIcon />}
          title={t('user.menu.sections.reporting.visualizeShiftReport.title')}
          description={t(
            'user.menu.sections.reporting.visualizeShiftReport.description',
          )}
          permissions={SHIFT_REPORT_PERMISSIONS}
          onClick={onClick}
          withDescription={withDescription}
        />
      </MenuSection>
    );
  }

  return (
    <MenuSection
      title={t('user.menu.sections.reporting.title')}
      permissions={REPORTING_SECTION_PERMISSIONS}
    >
      <MenuEntry
        route={Routes.ReportDashboardVisualize}
        icon={<ShiftReportIcon />}
        title={t('user.menu.sections.reporting.visualizeShiftReport.title')}
        description={t(
          'user.menu.sections.reporting.visualizeShiftReport.description',
        )}
        permissions={SHIFT_REPORT_PERMISSIONS}
        onClick={onClick}
        withDescription={withDescription}
      />
      <MenuEntry
        route={Routes.ReportDashboardAnalyze}
        icon={<PieChartOutlined />}
        title={t('user.menu.sections.reporting.analyzeShiftReport.title')}
        description={t(
          'user.menu.sections.reporting.analyzeShiftReport.description',
        )}
        permissions={SHIFT_REPORT_PERMISSIONS}
        onClick={onClick}
        withDescription={withDescription}
      />
    </MenuSection>
  );
};

const ProdPerfSection = ({ onClick, withDescription }: SectionProps) => {
  const { t } = useTranslation();

  return (
    <MenuSection
      title={t('user.menu.sections.prodPerf.title')}
      permissions={PROD_PERF_SECTION_PERMISSIONS}
    >
      <MenuEntry
        route={Routes.ProdPerfRingPerDay}
        icon={<BarChartOutlined />}
        title={t('user.menu.sections.prodPerf.ringPerDay.title')}
        description={t('user.menu.sections.prodPerf.ringPerDay.description')}
        permissions={PROD_PERF_PERMISSIONS}
        onClick={onClick}
        withDescription={withDescription}
      />
      <MenuEntry
        route={Routes.ProdPerfExcavationBuildTime}
        icon={<ClockCircleOutlined />}
        title={t('user.menu.sections.prodPerf.excavationBuildTime.title')}
        description={t(
          'user.menu.sections.prodPerf.excavationBuildTime.description',
        )}
        permissions={PROD_PERF_PERMISSIONS}
        onClick={onClick}
        withDescription={withDescription}
      />
      <MenuEntry
        route={Routes.ProdPerfRingList}
        icon={<ListIcon />}
        title={t('user.menu.sections.prodPerf.ringList.title')}
        description={t('user.menu.sections.prodPerf.ringList.description')}
        permissions={PROD_PERF_PERMISSIONS}
        onClick={onClick}
        withDescription={withDescription}
      />
      <MenuEntry
        route={Routes.ProdPerfPlanning}
        icon={<CalendarOutlined />}
        title={t('user.menu.sections.prodPerf.planning.title')}
        description={t('user.menu.sections.prodPerf.planning.description')}
        permissions={PROD_PERF_PERMISSIONS}
        onClick={onClick}
        withDescription={withDescription}
      />
    </MenuSection>
  );
};

const SteeringSection = ({ onClick, withDescription }: SectionProps) => {
  const { t } = useTranslation();

  return (
    <MenuSection
      permissions={STEERING_SECTION_PERMISSIONS}
      title={t('user.menu.sections.steering.title')}
    >
      <MenuEntry
        route={Routes.SteeringTable}
        icon={<SteeringDataIcon />}
        title={t('user.menu.sections.steering.table.title')}
        description={t('user.menu.sections.steering.table.description')}
        permissions={STEERING_PERMISSIONS}
        onClick={onClick}
        withDescription={withDescription}
      />
      <MenuEntry
        route={Routes.SteeringGraph}
        icon={<ScatterPlotIcon />}
        title={t('user.menu.sections.steering.graph.title')}
        description={t('user.menu.sections.steering.graph.description')}
        permissions={STEERING_PERMISSIONS}
        onClick={onClick}
        withDescription={withDescription}
      />
    </MenuSection>
  );
};

const DashboardEntry: React.FC<{
  onClick: () => void;
  withDescription: boolean;
}> = ({ onClick, withDescription }) => {
  const { t } = useTranslation();

  const { currentConstructionSite } = useAppContext();
  const { modules } = usePermissions();

  if (!currentConstructionSite) {
    return null;
  }

  const { userPermissions } = currentConstructionSite;

  const filteredPermissions = userPermissions.filter(
    (permission) => permission.module !== Module_Enum.Backoffice,
  );

  const hasShiftReportOnly =
    filteredPermissions.length === 1 &&
    filteredPermissions[0].module === Module_Enum.ShiftReport;

  if (hasShiftReportOnly && modules.SHIFT_REPORT?.isConstructionSiteUser) {
    return null;
  }

  return (
    <MenuSection permissions={ALL_PERMISSIONS}>
      <MenuEntry
        route={Routes.Dashboard}
        title={t('user.menu.dashboard.title')}
        icon={<LayoutOutlined />}
        description={t('user.menu.dashboard.description')}
        permissions={ALL_PERMISSIONS}
        onClick={onClick}
        withDescription={withDescription}
      />
    </MenuSection>
  );
};

export const Menu = ({
  menuState: { open, setOpen },
}: {
  menuState: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  };
}) => {
  const {
    isDataManager,
    modules: {
      SHIFT_REPORT: { isConstructionSiteUser },
    },
  } = usePermissions();

  const { currentConstructionSite } = useAppContext();
  const { isMobile } = useMediaType();

  if (!currentConstructionSite) {
    return null;
  }

  const enabledModules = getEnabledModuleList(
    isDataManager,
    currentConstructionSite,
  );

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  if (
    enabledModules.length === 1 &&
    enabledModules[0] === Module_Enum.ShiftReport
  ) {
    if (isConstructionSiteUser) {
      return null;
    }

    const menuContent = (
      <ReportingSection onClick={hide} withDescription={!isMobile} />
    );

    if (isMobile) {
      return (
        <MenuDrawer open={open} onOpenChange={handleOpenChange}>
          {menuContent}
        </MenuDrawer>
      );
    }

    return (
      <MenuContent
        permissions={MenuContentPermissions}
        open={open}
        onOpenChange={handleOpenChange}
        disableTitle
      >
        {menuContent}
      </MenuContent>
    );
  }

  const numberOfColumns =
    Number(enabledModules.includes(Module_Enum.DataAnalysis)) +
    Number(
      enabledModules.includes(Module_Enum.ShiftReport) ||
        enabledModules.includes(Module_Enum.Steering),
    ) +
    Number(enabledModules.includes(Module_Enum.ProdPerf));

  const menuContent = (
    <Row gutter={[20, 20]} wrap>
      <MenuColumn
        permissions={[...ALL_PERMISSIONS, ...DATA_ANALYSIS_SECTION_PERMISSIONS]}
        numberOfColumns={numberOfColumns}
      >
        <DashboardEntry onClick={hide} withDescription={!isMobile} />
        <DataAnalysisSection onClick={hide} withDescription={!isMobile} />
      </MenuColumn>

      <MenuColumn
        permissions={[
          ...REPORTING_SECTION_PERMISSIONS,
          ...STEERING_SECTION_PERMISSIONS,
        ]}
        numberOfColumns={numberOfColumns}
      >
        <ReportingSection onClick={hide} withDescription={!isMobile} />
        <SteeringSection onClick={hide} withDescription={!isMobile} />
      </MenuColumn>

      <MenuColumn
        permissions={[...PROD_PERF_SECTION_PERMISSIONS]}
        numberOfColumns={numberOfColumns}
      >
        <ProdPerfSection onClick={hide} withDescription={!isMobile} />
      </MenuColumn>
    </Row>
  );

  if (isMobile) {
    return (
      <MenuDrawer open={open} onOpenChange={handleOpenChange}>
        {menuContent}
      </MenuDrawer>
    );
  }

  return (
    <MenuContent
      permissions={MenuContentPermissions}
      open={open}
      onOpenChange={handleOpenChange}
    >
      {menuContent}
    </MenuContent>
  );
};
