import { Flex } from 'antd';
import { PropsWithChildren } from 'react';

import { SimpleCard } from 'ui/SimpleCard';

export const FilterCard = ({ children }: PropsWithChildren) => {
  return (
    <SimpleCard>
      <Flex align="center" gap={24}>
        {children}
      </Flex>
    </SimpleCard>
  );
};
