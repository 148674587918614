import classNames from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import { HTMLInputTypeAttribute, useState } from 'react';

import { SIMPLE_DATE_FORMAT } from 'technical/string/formatter';

import styles from './index.module.scss';

interface MobileDatePickerProps {
  id?: string;
  value?: string;
  onChange?: (value: Dayjs) => void;
  picker?: Extract<
    HTMLInputTypeAttribute,
    'datetime-local' | 'date' | 'week' | 'month'
  >;
  styled?: boolean;
  format?: string;
  className?: string;
}

export const MobileDatePicker = ({
  id,
  value,
  onChange,
  picker,
  styled = true,
  className,
}: MobileDatePickerProps) => {
  const [inputValue, setInputValue] = useState(value ?? '');

  let pattern = undefined;

  if (picker === 'week') {
    pattern = '[0-9]{4}-W[0-9]{2}';
  }
  if (picker === 'month') {
    pattern = '[0-9]{4}-[0-9]{2}';
  }

  return (
    <input
      type={picker ?? 'date'}
      id={id}
      className={classNames(
        styles.inputDate,
        styled ? styles.customPicker : undefined,
        className,
      )}
      pattern={pattern}
      value={inputValue}
      onChange={(newValue) => {
        const dateValue = newValue.target.value;
        setInputValue(dateValue);

        let date = dayjs(dateValue, SIMPLE_DATE_FORMAT);

        if (picker === 'datetime-local') {
          date = dayjs(dateValue, 'YYYY-MM-DDTHH:mm');
        }
        if (picker === 'week') {
          const year = dayjs(dateValue, 'YYYY');
          // The format is always the following `yyyy-Www`. Position 6 is just after the `W`
          const weekNumber = parseInt(dateValue.substring(6));
          date = dayjs(year, 'YYYY').week(weekNumber);
        }
        if (picker === 'month') {
          const year = dayjs(dateValue, 'YYYY');
          // The format is always the following `yyyy-mm`. Position 5 is just after the dash
          const monthNumber = parseInt(dateValue.substring(5));
          date = dayjs(year, 'YYYY').month(monthNumber);
        }

        if (date.isValid()) {
          onChange?.(date);
        }
      }}
    />
  );
};
