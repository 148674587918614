import {
  PlusCircleOutlined,
  StepBackwardOutlined,
  StepForwardOutlined,
} from '@ant-design/icons';
import { Modal, Space, Typography, message } from 'antd';
import { useTranslation } from 'translations/hooks';

import { taskFragmentUpdate } from 'business/shift-report/task/query.gql';
import { parseTaskDateString } from 'business/shift-report/task/services/timeOperations';
import {
  useExtendNextTaskMutation,
  useExtendPrevTaskMutation,
} from 'generated/graphql';
import errorReporting from 'technical/error-reporting';
import Button from 'ui/button';

import { EmptyZone } from './services/metadata/types';

const { Text } = Typography;

const buttonStyle = { width: '100%' };
const modalStyle = { minWidth: '800px' };

interface Props {
  emptyZoneData?: EmptyZone;
  visible: boolean;
  closeModal: () => void;
  newTaskFromTime: (startDate: string, endDate: string) => void;
}

function EmptyZoneModal({
  emptyZoneData,
  visible,
  closeModal,
  newTaskFromTime,
}: Props) {
  const { t } = useTranslation();
  const [extendPrevTask] = useExtendPrevTaskMutation({
    update: taskFragmentUpdate,
  });
  const [extendNextTask] = useExtendNextTaskMutation({
    update: taskFragmentUpdate,
  });

  return (
    <Modal
      title={t('chart.emptyZoneModal.title')}
      open={visible}
      onCancel={closeModal}
      footer={null}
      style={modalStyle}
    >
      {emptyZoneData && (
        <Space direction="vertical">
          <Space style={{ marginBottom: '20px' }}>
            <Text>{t('chart.emptyZoneModal.subTitle')}</Text>
          </Space>
          <Space direction="horizontal" size="large">
            {emptyZoneData?.prevTaskId && (
              <Button
                type="primary"
                style={buttonStyle}
                ghost
                onClick={async () => {
                  if (emptyZoneData) {
                    const {
                      prevTaskId: id,
                      endDate,
                      startDate,
                    } = emptyZoneData;
                    try {
                      await extendPrevTask({
                        variables: {
                          id: { id },
                          endDate,
                          durationToAdd: parseTaskDateString(endDate).diff(
                            parseTaskDateString(startDate),
                            'minutes',
                          ),
                        },
                      });
                      closeModal();
                    } catch (err) {
                      if (err instanceof Error) {
                        errorReporting.error(err);
                      }
                      message.error(t('errors.mutation_failed'));
                    }
                  }
                }}
                icon={<StepBackwardOutlined />}
              >
                {t('chart.emptyZoneModal.extendPrev')}
              </Button>
            )}
            {emptyZoneData?.nextTaskId && (
              <Button
                type="primary"
                style={buttonStyle}
                ghost
                onClick={async () => {
                  if (emptyZoneData) {
                    const {
                      nextTaskId: id,
                      startDate,
                      endDate,
                    } = emptyZoneData;
                    try {
                      await extendNextTask({
                        variables: {
                          id: { id },
                          startDate,
                          durationToAdd: parseTaskDateString(endDate).diff(
                            parseTaskDateString(startDate),
                            'minutes',
                          ),
                        },
                      });
                      closeModal();
                    } catch (err) {
                      if (err instanceof Error) {
                        errorReporting.error(err);
                      }
                      message.error('errors.mutation_failed');
                    }
                  }
                }}
                icon={<StepForwardOutlined />}
              >
                {t('chart.emptyZoneModal.extendNext')}
              </Button>
            )}
            <Button
              type="primary"
              style={buttonStyle}
              ghost
              onClick={() => {
                const { startDate, endDate } = emptyZoneData;
                closeModal();
                newTaskFromTime(startDate, endDate);
              }}
              icon={<PlusCircleOutlined />}
            >
              {t('chart.emptyZoneModal.newTask')}
            </Button>
          </Space>
        </Space>
      )}
    </Modal>
  );
}

export default EmptyZoneModal;
