import { SIMPLE_DATE_FORMAT } from 'technical/string/formatter';

import { RangeFilters } from './types';

export function getFetchRange(
  filters: RangeFilters<Record<string, any>>,
):
  | { startDate?: string; endDate?: string }
  | { startRing?: number; endRing?: number }
  | null {
  if (filters.filterType === 'ring' && filters.ringRange) {
    const [startRing, endRing] = filters.ringRange;

    return {
      startRing: startRing ?? undefined,
      endRing: endRing ?? undefined,
    };
  } else if (filters.filterType === 'date' && filters.dateRange) {
    const [startDate, endDate] = filters.dateRange;

    return {
      startDate: startDate ? startDate.format(SIMPLE_DATE_FORMAT) : undefined,
      endDate: endDate ? endDate.format(SIMPLE_DATE_FORMAT) : undefined,
    };
  }

  return null;
}
