import { Flex } from 'antd';

import { useSingleGraphReferenceContext } from 'business/providers/graph-reference';
import { SerieMetadata } from 'business/shift-report/report-analyze/pages/production-analyze/types';
import { handlePrintWithChart } from 'technical/handle-print-with-chart';
import { PrintButton } from 'ui/button/print-button';

import { ReportProdAnalyzeExportButton } from './export-button';

export const ReportProdAnalyzeHeader = ({
  shifts,
  teams,
}: {
  shifts: SerieMetadata[];
  teams: SerieMetadata[];
}) => {
  const { ref } = useSingleGraphReferenceContext();

  return (
    <Flex gap={4}>
      <PrintButton onClick={() => handlePrintWithChart(ref)} />
      <ReportProdAnalyzeExportButton shifts={shifts} teams={teams} />
    </Flex>
  );
};
