import { DatePicker as AntDatePicker } from 'antd';
import { Dayjs } from 'dayjs';
import { HTMLInputTypeAttribute } from 'react';

import { useMediaType } from 'technical/media/hooks';
import { SIMPLE_DATE_FORMAT } from 'technical/string/formatter';
import { MobileDatePicker } from 'ui/form/date-picker/mobile-picker';

interface DatePickerProps {
  id?: string;
  value?: Dayjs | null;
  picker?: Extract<HTMLInputTypeAttribute, 'date' | 'week' | 'month'>;
  onChange?: (value: Dayjs) => void;
  format?: string;
  allowClear?: boolean;
  styled?: boolean;
  className?: string;
}

export const DatePicker = ({
  value,
  styled = true,
  ...rest
}: DatePickerProps) => {
  const { isMobile } = useMediaType();

  if (isMobile) {
    return (
      <MobileDatePicker
        value={value?.format(SIMPLE_DATE_FORMAT)}
        styled={styled}
        {...rest}
      />
    );
  }

  return <AntDatePicker value={value} {...rest} />;
};
