import { Flex } from 'antd';

import { useSingleGraphReferenceContext } from 'business/providers/graph-reference';
import { handlePrintWithChart } from 'technical/handle-print-with-chart';
import { PrintButton } from 'ui/button/print-button';

import { RingPerDayExport } from './ring-per-day-export';

export const RingPerDayHeader = () => {
  const { ref } = useSingleGraphReferenceContext();

  return (
    <Flex gap={4}>
      <PrintButton onClick={() => handlePrintWithChart(ref)} />
      <RingPerDayExport />
    </Flex>
  );
};
