import * as d3 from 'd3';
import { i18n } from 'translations';

import { ordinalScale } from 'business/shift-report/report/components/ReportChart/services/axis';
import { ReportChartMode } from 'business/shift-report/report/types';
import { formatTaskDateStringToLocalizedTime } from 'business/shift-report/task/services/timeOperations';
import {
  ActivityInvertedTreeFullFragment,
  TaskFullFragment,
} from 'generated/graphql';
import errorReporting from 'technical/error-reporting';

import { SELECTOR, TOOLTIP_CLASS, VERTICAL_SPACE } from './chart';

const getActivityRoot = (activity: ActivityInvertedTreeFullFragment): string =>
  !activity.parent ? activity.name : getActivityRoot(activity.parent);

const noteEllipsis = (note: string) =>
  note.length > 250 ? `${note.substring(0, 250)}...` : note;

// Create Event Handlers for mouse
export function handleMouseOver(yScale: ordinalScale, mode: ReportChartMode) {
  return function handler(this: any, _event: any, task: TaskFullFragment) {
    const tooltipWithText = d3
      .select(`#${SELECTOR}-${mode}`)
      .append('div')
      .attr('class', TOOLTIP_CLASS)
      .html(
        `
    <p class="tooltip-title">${getActivityRoot(task.activity)}</p>
    <p class="tooltip-subtext">
      <span class="tooltip-subtext-label">
        ${i18n.t('chart.tooltip.labels.activity')}
      </span>
      ${task.activity.name}
    </p>
    <p class="tooltip-subtext">
      <span class="tooltip-subtext-label">
        ${i18n.t('chart.tooltip.labels.startDate')}
      </span>
      ${formatTaskDateStringToLocalizedTime(task.startDate)}
    </p>
    <p class="tooltip-subtext">
      <span class="tooltip-subtext-label">
        ${i18n.t('chart.tooltip.labels.endDate')}
      </span>
      ${formatTaskDateStringToLocalizedTime(task.endDate)}
    </p>
    <p class="tooltip-subtext">
      <span class="tooltip-subtext-label">
        ${i18n.t('chart.tooltip.labels.ringNumber')}
      </span>
      ${task.ring}
    </p>
    ${
      task.ended
        ? `<p class="tooltip-subtext">
      <span class="tooltip-subtext-label">
        ${i18n.t('chart.tooltip.labels.done')}
      </span>
    </p>`
        : ''
    }
    ${
      task.note
        ? `
        <p class="tooltip-subtext">
          <span class="tooltip-subtext-label">
            ${i18n.t('chart.tooltip.labels.note')}
          </span>
          <p class="tooltip-note">
            ${noteEllipsis(task.note)}
          </p>
        </p>`
        : ''
    }
    `,
      );

    try {
      // By design will be an Element not null (we just hover it)
      const {
        x: taskPosX,
        y: taskPosY,
        width: taskWidth,
      } = (
        d3.select(this).select('rect').node() as Element
      )?.getBoundingClientRect();

      // Bt design cannot be null, we just created the element
      const { height: tooltipHeight, width: tooltipWidth } = (
        tooltipWithText.node() as Element
      ).getBoundingClientRect();

      const tooltipPosY = taskPosY + yScale.step() - VERTICAL_SPACE;
      // Center tooltip on task
      const tooltipPosX = taskPosX + taskWidth / 2 - tooltipWidth / 2;

      tooltipWithText
        .style('left', `${window.scrollX + tooltipPosX}px`)
        .style('top', `${window.scrollY + tooltipPosY}px`);

      if (tooltipPosY + tooltipHeight > document.body.clientHeight) {
        tooltipWithText.style(
          'top',
          `${window.scrollY + taskPosY - tooltipHeight}px`,
        );
      }
    } catch (err) {
      if (err instanceof Error) {
        errorReporting.error(err);
      }
      tooltipWithText.remove();
    }
  };
}

export const handleMouseOut = () => d3.selectAll(`.${TOOLTIP_CLASS}`).remove();
