import {
  abscissaScale,
  ordinalScale,
} from 'business/shift-report/report/components/ReportChart/services/axis';

import { emptyZoneHatchPattern } from './chart';
import { getWidth, getXPos } from './svg';
import { EmptyZone } from './types';

export const getEmptyZoneXPos =
  (xScale: abscissaScale) =>
  ({ startDate }: EmptyZone) =>
    getXPos(xScale, startDate);

export const getEmptyZoneYPos = () => () => 0;

export const getEmptyZoneWidth =
  (xScale: abscissaScale) =>
  ({ startDate, endDate }: EmptyZone) =>
    getWidth(xScale, startDate, endDate);

export const getEmptyZoneHeight =
  (yScale: ordinalScale, activitiesCount: number) => () =>
    yScale.step() * activitiesCount;

export const getEmptyZoneStyle = () => `fill: ${emptyZoneHatchPattern};`;
