import { App } from 'antd';
import { useTranslation } from 'translations/hooks';

import { useProdAndPerfFilters } from 'business/production-and-performance/providers/prod-and-perf-filters-provider';
import config from 'config';
import { useExcavationAndBuildingDurationControllerGenerateExcavationAndBuildingDurationExport } from 'generated/apiComponents';
import { downloadFile } from 'technical/print';
import { getFetchRange } from 'technical/range-filters/get-fetch-range';

export const useExcavationBuildTimeExport = () => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const filters = useProdAndPerfFilters();

  const { mutate: generateExport } =
    useExcavationAndBuildingDurationControllerGenerateExcavationAndBuildingDurationExport(
      {
        onSuccess: (data) => {
          downloadFile(`${config.gcp.publicUri}/${data.url}`, data.fileName);
        },
        onError: () => {
          message.open({
            type: 'error',
            content: t('errors.export_download_error'),
          });
        },
      },
    );

  const fetchRange = getFetchRange(filters);

  return {
    generateExport,
    fetchRange,
  };
};
