import dayjs, { Dayjs } from 'dayjs';

import { SIMPLE_DATE_FORMAT } from 'technical/string/formatter';

export function calculateBusinessDays(
  firstDate: string,
  secondDate: string,
  nbDaysPerWeek: number,
) {
  const nbDaysPerWeekMoment = nbDaysPerWeek - 1;
  let day1 = dayjs(firstDate).startOf('day');
  let day2 = dayjs(secondDate).startOf('day');
  let adjust = 1;

  if (day1.dayOfYear() === day2.dayOfYear() && day1.year() === day2.year()) {
    return 1;
  }

  if (day2.isBefore(day1)) {
    const temp = day1;
    day1 = day2;
    day2 = temp;
  }

  // Check if first date starts on weekends
  if (day1.day() === nbDaysPerWeekMoment + 1) {
    // 1st day of weekend
    // Move date to next week monday
    day1.day(8);
  } else if (day1.day() === 0) {
    // Sunday
    // Move date to current week monday
    day1.day(1);
  }

  // Check if second date starts on weekends
  if (day2.day() === nbDaysPerWeekMoment + 1) {
    // 1st day of weekend
    // Move date to current week last working day
    day2.day(nbDaysPerWeekMoment);
  } else if (day2.day() === 0) {
    // Sunday
    // Move date to previous week last working day
    day2.day(nbDaysPerWeekMoment - 6);
  }

  const day1Week = day1.week();
  let day2Week = day2.week();

  // Check if two dates are in different week of the year
  if (day1Week !== day2Week) {
    // Check if second date's year is different from first date's year
    if (day2Week < day1Week) {
      day2Week += day1Week;
    }
    // Calculate adjust value to be substracted from difference between two dates
    adjust += (nbDaysPerWeekMoment - 6) * (day2Week - day1Week);
  }

  return day2.diff(day1, 'days') + adjust;
}

export function formatDurationToReadable(duration: number) {
  const hour = Math.floor(duration / 60);
  const min = duration % 60;
  let displayedMin = '';
  if (min === 0) {
    displayedMin = ``;
  } else if (min <= 9) {
    displayedMin = `0${min}min`;
  } else {
    displayedMin = `${min}min`;
  }
  return `${hour}h${displayedMin}`;
}

export function parseReportDateString(
  dateString: string,
  shiftStartTime?: string,
) {
  if (!shiftStartTime) {
    return dayjs.tz(dateString);
  }

  // Here it's a bit same as the explaination for the function getReportNextDateIfNightShift
  // When we get the date of the report it will be at midnight so if the shift start on the very date
  // of the DST change, we'll get the wrong timezone.
  // eg. For a London construction site
  //    Report date: 2025-03-29 (day of the DST change in London
  //    Shift Time: 06:30 to 18:30
  //    Parsing the report date on Europe/London  => 2025-03-29T00:00:00+00:00 ✅ (at midnight, it's the right timezone)
  //    Apply shift time                          => 2025-03-29T06:30:00+00:00 ❌ The timezone should be +1 after the DST change

  const shiftStartDate = dayjs(shiftStartTime, 'HH:mm:ss');
  const reportDate = dayjs.tz(dateString);

  return dayjs.tz(
    reportDate
      .hour(shiftStartDate.hour())
      .minute(shiftStartDate.minute())
      .format('YYYY-MM-DD HH:mm:ss'),
  );
}
export function getReportNextDateIfNightShift(reportDate: Dayjs) {
  // Ok, this here is a strange calcul. When the shift is during the night of the DST change
  // we need a special calcul to get the date on the right timezone

  // We usualy did reportDate.set('hour', shiftHour).set('minute', shiftMinute).add(1, 'day')
  // But this cause to not get the right timezone if you check the report from another timezone
  // eg. you're in Paris, France viewing a report on New York, US

  // The calcul result was
  //    Report date: 2025-03-08 (night of the DST change in New York)
  //    Shift Time: 18:00 to 6:00
  //    Parsing the report date on America/New_York => 2025-03-08T00:00:00-05:00 ✅
  //    Parsing the shiftStartDate                  => 2025-03-08T18:00:00-05:00 ✅
  //    Parsing the shiftEndDate                    => 2025-03-09T06:00:00-05:00 ❌ The timezone should be -4 after the DST change
  // I'm not sure what should be the correct result to this to be honest, it's not a math calculus with a fixed
  // answer, but what I'm sure is that it's not what we want

  // To fix this, the easiest method is to use the parse method of Dayjs
  // dayjs.tz(
  //   date.set('hour', 12).add(1, 'day').format('YYYY-MM-DD HH:mm:ss'),
  // )
  // Explaination of the code above
  //  - set the hour to 12 - this way we're sure the time is after the DST observation time (around 2 or 3 in the morning)
  //  - add 1 day          - to be the next day of the shift
  //  - format the date    - to get an easy parse later without the timezone, so the timezone si the one set globaly
  //  - parse the date

  // This way the original calcul become
  //    Report date: 2025-03-08 (night of the DST change in New York)
  //    Shift Time: 18:00 to 6:00
  //    Parsing the report date on America/New_York => 2025-03-08T00:00:00-05:00 ✅
  //    Parsing the shiftStartDate => 2025-03-08T18:00:00-05:00 ✅
  //    Parsing the shiftEndDate => 2025-03-09T06:00:00-04:00 ✅
  // And that's it

  return dayjs.tz(
    reportDate.set('hour', 12).add(1, 'day').format('YYYY-MM-DD HH:mm:ss'),
  );
}

export function formatReportDateStringToLocalizedDateString(
  dateString: string,
) {
  return dayjs(dateString).format('L');
}

export function formatReportDateStringToLocalizedDateStringWithDayOfWeek(
  dateString: string,
) {
  return dayjs(dateString).format('ddd L');
}

export function parseReportDateStringStrict(dateString: string) {
  return dayjs(dateString, SIMPLE_DATE_FORMAT, true);
}
