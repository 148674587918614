export const getColorPresets = (label: string) => {
  return [
    {
      label,
      colors: [
        '#004488',
        '#3245CC',
        '#24BBCC',
        '#44AAFF',
        '#99EEFF',
        '#027755',
        '#34CC33',
        '#99DD22',
        '#67EEBB',
        '#027788',
        '#882199',
        '#5534CC',
        '#BB33CC',
        '#CC55EF',
        '#8855FF',
        '#AA1044',
        '#CC1033',
        '#FF5655',
        '#FF6699',
        '#FFAAAA',
        '#DD4401',
        '#FF9922',
        '#CCBB33',
        '#FFDD33',
        '#FFCC76',
        '#000000',
      ],
      key: 'colorPresets',
    },
  ];
};
