import { useAppContext } from 'business/contextProviders/useAppContext';
import ReportCreatePage from 'business/shift-report/report/pages/ReportCreate';
import ReportListPage from 'business/shift-report/report/pages/ReportList';

const ShiftReportHome = () => {
  const { currentConstructionSite } = useAppContext();

  return currentConstructionSite?.isCompleted ? (
    <ReportListPage />
  ) : (
    <ReportCreatePage />
  );
};

export default ShiftReportHome;
