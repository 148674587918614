import dayjs from 'dayjs';

import { formatDateToDayAndMonth } from 'technical/time-utils/format-date-to-day-and-month';

export const formatGraphDateAxisLabel = (value: Date) => {
  // If interval is too small, Ag Chart display half day (12AM/12PM) label
  // By returning undefined, we skip the display
  if (value instanceof Date && dayjs(value).hour() != 0) {
    return undefined;
  }

  return formatDateToDayAndMonth(value);
};
