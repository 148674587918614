import dayjs from 'dayjs';
import { createSearchParams, useSearchParams } from 'react-router-dom';

import { DEFAULT_PAGE_NUMBER } from 'business/shift-report/report/components/ReportList';
import {
  SEARCH_PARAM_KEY_MAP,
  SearchParamKey,
} from 'business/shift-report/report/hooks/constants';

export const useFilterParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const currentPageParam = searchParams.get(SEARCH_PARAM_KEY_MAP.page);
  const currentPage = currentPageParam
    ? Number(currentPageParam)
    : DEFAULT_PAGE_NUMBER;

  const startDateParam = searchParams.get(SEARCH_PARAM_KEY_MAP.startDate);
  const startDate = startDateParam ? dayjs(startDateParam) : null;

  const endDateParam = searchParams.get(SEARCH_PARAM_KEY_MAP.endDate);
  const endDate = endDateParam ? dayjs(endDateParam) : null;

  const operator = searchParams.get(SEARCH_PARAM_KEY_MAP.operator) ?? undefined;

  const shiftLeader =
    searchParams.get(SEARCH_PARAM_KEY_MAP.shiftLeader) ?? undefined;
  const shiftManager =
    searchParams.get(SEARCH_PARAM_KEY_MAP.shiftManager) ?? undefined;
  const shiftEngineer =
    searchParams.get(SEARCH_PARAM_KEY_MAP.shiftEngineer) ?? undefined;
  const shift = searchParams.get(SEARCH_PARAM_KEY_MAP.shift) ?? undefined;
  const team = searchParams.get(SEARCH_PARAM_KEY_MAP.team) ?? undefined;

  const updateSearchParam = (
    filter: SearchParamKey,
    value: string | null | undefined,
  ) => {
    setSearchParams((params) => {
      if (value) {
        params.set(filter, value);
      }
      return params;
    });
  };

  const emptyFilters = () => {
    setSearchParams([]);
  };

  const getQueryParams = () => {
    const queryParams = {};
    if (currentPageParam) {
      Object.assign(queryParams, {
        [SEARCH_PARAM_KEY_MAP.page]: currentPageParam,
      });
    }
    if (startDateParam) {
      Object.assign(queryParams, {
        [SEARCH_PARAM_KEY_MAP.startDate]: startDateParam,
      });
    }
    if (endDateParam) {
      Object.assign(queryParams, {
        [SEARCH_PARAM_KEY_MAP.endDate]: endDateParam,
      });
    }
    if (operator) {
      Object.assign(queryParams, {
        [SEARCH_PARAM_KEY_MAP.operator]: operator,
      });
    }
    if (shiftLeader) {
      Object.assign(queryParams, {
        [SEARCH_PARAM_KEY_MAP.shiftLeader]: shiftLeader,
      });
    }
    if (shiftManager) {
      Object.assign(queryParams, {
        [SEARCH_PARAM_KEY_MAP.shiftManager]: shiftManager,
      });
    }
    if (shiftEngineer) {
      Object.assign(queryParams, {
        [SEARCH_PARAM_KEY_MAP.shiftEngineer]: shiftEngineer,
      });
    }
    if (shift) {
      Object.assign(queryParams, {
        [SEARCH_PARAM_KEY_MAP.shift]: shift,
      });
    }
    if (team) {
      Object.assign(queryParams, {
        [SEARCH_PARAM_KEY_MAP.team]: team,
      });
    }

    return queryParams;
  };

  const getQueryParamString = () => {
    return createSearchParams(getQueryParams()).toString();
  };

  return {
    currentPage,
    startDate,
    endDate,
    operator,
    shiftLeader,
    shiftManager,
    shiftEngineer,
    shift,
    team,
    updateSearchParam,
    emptyFilters,
    getQueryParams,
    getQueryParamString,
  };
};
