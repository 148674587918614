import { EditOutlined } from '@ant-design/icons';
import { message, Result } from 'antd';
import classNames from 'classnames';
import { Dayjs } from 'dayjs';
import { useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'translations/hooks';

import { useAppContext } from 'business/contextProviders/useAppContext';
import { usePermissions } from 'business/contextProviders/usePermissions';
import { ReportListPageTemplate } from 'business/shift-report/report/components/report-list-template';
import ReportList, {
  DEFAULT_PAGE_LIMIT,
} from 'business/shift-report/report/components/ReportList';
import { SEARCH_PARAM_KEY_MAP } from 'business/shift-report/report/hooks/constants';
import { useFilterParams } from 'business/shift-report/report/hooks/use-filter-params';
import Routes from 'config/routes';
import { useGetReportsQuery } from 'generated/graphql';
import { SIMPLE_DATE_FORMAT } from 'technical/string/formatter';
import Button from 'ui/button';
import { DatePicker } from 'ui/form/date-picker';

import styles from './index.module.scss';

function ReportListPage() {
  const { t } = useTranslation();
  const { currentConstructionSite } = useAppContext();
  const {
    modules: {
      SHIFT_REPORT: { hasManagerReadPermission },
    },
  } = usePermissions();

  const { currentPage, startDate, updateSearchParam, emptyFilters } =
    useFilterParams();

  const [searchParams] = useSearchParams();
  const routeRedirection = searchParams.get('redirect');

  function filterResults(value: Dayjs | null) {
    if (value === null) {
      emptyFilters();
      return;
    }

    updateSearchParam(
      SEARCH_PARAM_KEY_MAP.startDate,
      value.format(SIMPLE_DATE_FORMAT),
    );
  }

  const { loading, error, data, refetch } = useGetReportsQuery({
    variables: {
      constructionSiteId: currentConstructionSite?.id,
      limit: DEFAULT_PAGE_LIMIT,
      offset: (currentPage - 1) * DEFAULT_PAGE_LIMIT,
      date: startDate,
    },
    fetchPolicy: 'network-only',
    skip: !currentConstructionSite?.id,
  });

  useEffect(
    function alertUser() {
      if (error) {
        message.error(error.message);
      }
    },
    [error],
  );

  const emptyListComponent = (
    <Result
      status="404"
      title={t('pages.report.list.filterNotFound.title')}
      subTitle={
        startDate === null
          ? t('pages.report.list.noReport.label')
          : t('pages.report.list.filterNotFound.label')
      }
      extra={
        <Button onClick={() => emptyFilters()}>
          {t('pages.report.list.filterNotFound.button')}
        </Button>
      }
    />
  );

  return (
    <ReportListPageTemplate>
      <DatePicker
        className={classNames(styles.searchBox, 'button-center')}
        format="L"
        value={startDate}
        onChange={filterResults}
        allowClear
      />
      <ReportList
        loading={loading}
        refetchList={refetch}
        shiftReports={data?.shiftReport}
        paginationMeta={{
          shiftReportCount: data?.shiftReportAggregate.aggregate?.count || 0,
        }}
        emptyListComponent={emptyListComponent}
        routeRedirection={routeRedirection ?? undefined}
      />
      {!currentConstructionSite?.isCompleted && (
        <Link
          to={
            hasManagerReadPermission
              ? Routes.ReportCreate
              : Routes.ReportUserHome
          }
          className="button-center"
        >
          <Button type="primary" icon={<EditOutlined />}>
            {t('pages.report.startNewReport')}
          </Button>
        </Link>
      )}
    </ReportListPageTemplate>
  );
}

export default ReportListPage;
