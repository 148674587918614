import { Flex } from 'antd';
import classNames from 'classnames';
import { PropsWithChildren, ReactNode } from 'react';

import { ContentTitle } from 'ui/content-title';
import { SimpleCard } from 'ui/SimpleCard';

import layoutStyles from './layout.module.scss';

interface Props {
  title?: string;
  header?: ReactNode;
  filters?: ReactNode;
  printHeader?: ReactNode;
  withCard?: boolean;
}

export const SingleCardPageWithFilterCardLayout = ({
  title,
  children,
  header,
  filters,
  printHeader,
  withCard = true,
}: PropsWithChildren<Props>) => {
  return (
    <div className={classNames(layoutStyles.page, 'page-appear')}>
      <Flex
        align="stretch"
        justify="center"
        className={layoutStyles.flexContainer}
        vertical
        gap={20}
      >
        {printHeader ?? null}
        <Flex
          justify="space-between"
          align="center"
          className={layoutStyles.header}
        >
          {title ? <ContentTitle>{title}</ContentTitle> : null}
          {header}
        </Flex>

        <div className={layoutStyles.header}>{filters}</div>

        {withCard ? <SimpleCard>{children}</SimpleCard> : children}
      </Flex>
    </div>
  );
};
