import { DefaultOptionType } from 'antd/es/select';

export type SimpleSelectResource = {
  id: string;
  name: string;
};

export const mapSimpleSelectResourceToSelectOptionProp = ({
  id,
  name,
}: SimpleSelectResource): DefaultOptionType => ({
  label: name,
  value: id,
});
