import { nameWithPreviousActivityName } from 'business/shift-report/activity/services';
import { ReportChartMode } from 'business/shift-report/report/types';
import { parseTaskDateString } from 'business/shift-report/task/services/timeOperations';
import {
  ActivityFullFragment,
  ActivityTreeFullFragment,
  TaskFullFragment,
} from 'generated/graphql';

import { UsedActivity } from './metadata/types';

const taskHasThisActivity = (
  activity: ActivityTreeFullFragment,
  tasks: TaskFullFragment[],
): boolean => {
  const res = tasks.some((task) => task.activityId === activity.id);
  if (!res) {
    return false;
  }
  return true;
};

function allDescendant(
  activity: ActivityFullFragment & { children?: ActivityFullFragment[] },
): ActivityFullFragment[] {
  const children = activity.children ?? [];
  const descendants = children.flatMap((child) => allDescendant(child));
  return descendants.concat(children);
}

function getUsedActivityList(
  activity: ActivityTreeFullFragment,
  tasks: TaskFullFragment[],
  mode: ReportChartMode,
  includeTopLevel: boolean,
): UsedActivity[] {
  const children = activity.children?.flatMap((childActivity) =>
    getUsedActivityList(
      childActivity as ActivityTreeFullFragment,
      tasks,
      mode,
      includeTopLevel,
    ),
  );

  const isActivityUsed = taskHasThisActivity(activity, tasks);
  const isIncludedBecauseTopLevel = includeTopLevel && !activity.parentId;
  const isForcedByMode =
    mode === 'view' && activity.isAlwaysDisplayedInPdfReport;

  if (isActivityUsed || isIncludedBecauseTopLevel || isForcedByMode) {
    return [
      {
        id: activity.id,
        names: nameWithPreviousActivityName(activity),
        hasChildActivity:
          allDescendant(activity).filter(({ id }) =>
            tasks.some((task) => task.activityId === id),
          ).length > 0,
      },
      ...(children ?? []),
    ];
  }

  return children ?? [];
}

/**
 * Filters all activities and keep only used activities (some task uses the activity,
 * top level activities and report chart mode can force some activity to not be filtered out).
 * Return a list of used activities.
 *
 * @param {ActivityTreeFullFragment[]} activities
 * @param {TaskFullFragment[]} tasks
 * @param {boolean} includeTopLevel
 * @param {ReportChartMode} mode
 */
export const getUsedActivitiesList = (
  activities: ActivityTreeFullFragment[],
  tasks: TaskFullFragment[],
  mode: ReportChartMode,
  includeTopLevel = true,
): UsedActivity[] => {
  return activities.flatMap((activity) =>
    getUsedActivityList(activity, tasks, mode, includeTopLevel),
  );
};

export const getActivityTotalTime = (
  id: string,
  tasks: TaskFullFragment[],
  criticalOnly = false,
) => {
  return tasks.reduce((acc, task) => {
    if (
      task.activity.id === id &&
      ((criticalOnly && task.critical) || !criticalOnly)
    ) {
      const startDate = parseTaskDateString(task.startDate);
      const endDate = parseTaskDateString(task.endDate);
      return acc + endDate.diff(startDate, 'minutes');
    }
    return acc;
  }, 0);
};
