import { Flex, Result } from 'antd';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'translations/hooks';

import style from './index.module.scss';

const SmallScreen = () => {
  const { t } = useTranslation();

  return (
    <Flex className={style.tooSmallScreen}>
      <Result status="warning" title={t('errors.too_small_screen')} />
    </Flex>
  );
};

const HideLargePage = ({ children }: PropsWithChildren) => {
  return <div className={style.hidePage}>{children}</div>;
};

export const LandscapeContainer = ({ children }: PropsWithChildren) => {
  return (
    <>
      <HideLargePage>{children}</HideLargePage>
      <SmallScreen />
    </>
  );
};
