import { ButtonProps as AntdButtonProps } from 'antd/lib/button';
import classnames from 'classnames';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import Button from 'ui/button';

import styles from './index.module.scss';

interface ButtonProps extends AntdButtonProps {
  className?: string;
  path?: string;
  onClick?: () => void;
  children: React.ReactNode;
}

type Props = ButtonProps;
export function MenuLink({
  children,
  className,
  path,
  onClick,
  ...props
}: Readonly<Props>) {
  const navigate = useNavigate();

  function handleOnClick() {
    if (path) {
      navigate(path);
    }
  }

  return (
    <Button
      type="link"
      onClick={onClick ?? handleOnClick}
      className={classnames(className, styles.menuLink)}
      {...props}
    >
      {children}
    </Button>
  );
}
