import { DownloadOutlined } from '@ant-design/icons';
import {
  Checkbox,
  Divider,
  Flex,
  Form,
  message,
  Radio,
  Space,
  Typography,
} from 'antd';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useNavigate, generatePath } from 'react-router-dom';
import { useTranslation } from 'translations/hooks';

import { useAppContext } from 'business/contextProviders/useAppContext';
import { AggregatedReportType } from 'business/shift-report/report/types';
import Routes from 'config/routes';
import errorReporting from 'technical/error-reporting';
import { SIMPLE_DATE_FORMAT } from 'technical/string/formatter';
import { requiredErrorAlert } from 'technical/validation/rules';
import Button from 'ui/button';
import { MainPageButton } from 'ui/button/main-page-button';
import CapCard from 'ui/cap-card';
import { DatePicker } from 'ui/form/date-picker';
import { DateRangePicker } from 'ui/form/date-range-picker';

import style from './index.module.scss';

const { Text } = Typography;

interface FormValues {
  aggregationType?: AggregatedReportType;
  constructionSiteId?: string;
  picker?: any;
  range?: any;
  showTimeline?: boolean;
  includeImages?: boolean;
}

function ReportExportAggregatedPage() {
  const { t } = useTranslation();
  const { currentConstructionSite } = useAppContext();
  const navigate = useNavigate();

  const onFinish = async (values: FormValues) => {
    let startDate;
    let endDate;
    if (!values.aggregationType) {
      return;
    }
    try {
      // generate an aggregated report between 2 dates
      if (values.aggregationType === 'periodic') {
        if (!values.range[0] || !values.range[1]) {
          throw new Error('missing fields');
        }
        // Generate an aggregated report for the reports with current constructionSite, in values.range with values.rangeTyp
        startDate = values.range[0].format(SIMPLE_DATE_FORMAT);
        endDate = values.range[1].format(SIMPLE_DATE_FORMAT);
      }
      // generate an aggregated report for the selected date / week / month
      else {
        if (!values.picker) {
          throw new Error('missing fields');
        }
        startDate = values.picker;
        endDate = values.picker;
        switch (values.aggregationType) {
          case 'month':
            startDate = dayjs(startDate)
              .startOf('month')
              .format(SIMPLE_DATE_FORMAT);
            endDate = dayjs(endDate).endOf('month').format(SIMPLE_DATE_FORMAT);
            break;
          case 'week':
            startDate = dayjs(startDate)
              .startOf('week')
              .format(SIMPLE_DATE_FORMAT);
            endDate = dayjs(endDate).endOf('week').format(SIMPLE_DATE_FORMAT);
            break;
          default:
            startDate = startDate.format(SIMPLE_DATE_FORMAT);
            endDate = endDate.format(SIMPLE_DATE_FORMAT);
            break;
        }
      }
      const searchParams = new URLSearchParams();

      if (values.showTimeline) {
        searchParams.set('show-timeline', 'true');
      }
      if (values.includeImages) {
        searchParams.set('include-images', 'true');
      }

      navigate({
        pathname: generatePath(Routes.ReportAggregated, {
          constructionSiteId: currentConstructionSite?.id,
          type: values.aggregationType,
          startDate: startDate,
          endDate: endDate,
        }),
        search: searchParams.toString(),
      });
    } catch (newTaskError) {
      message.error(t('pages.manager.aggregatedReport.getReportFail'));
      if (newTaskError instanceof Error) {
        errorReporting.error(newTaskError);
      }
    }
  };

  return (
    <Flex
      vertical
      justify="center"
      className={classNames(
        style.capAggregatedReportPage,
        'main-bg-image',
        'page-appear',
      )}
    >
      <CapCard
        title={t('pages.manager.aggregatedReport.title')}
        className={style.card}
      >
        <Form
          name="basic"
          layout="vertical"
          size="middle"
          initialValues={{
            aggregationType: 'periodic',
            picker: dayjs().subtract(1, 'week'),
            range: [dayjs().subtract(1, 'week'), dayjs()],
            activityLevelAggregation: 1,
            showTimeline: false,
          }}
          onFinish={onFinish}
          onFinishFailed={requiredErrorAlert}
        >
          <Form.Item name="aggregationType">
            <Radio.Group buttonStyle="solid">
              <Radio.Button value="periodic">
                {t('pages.manager.aggregatedReport.periodic')}
              </Radio.Button>
              <Radio.Button value="date">
                {t('pages.manager.aggregatedReport.date')}
              </Radio.Button>
              <Radio.Button value="week">
                {t('pages.manager.aggregatedReport.week')}
              </Radio.Button>
              <Radio.Button value="month">
                {t('pages.manager.aggregatedReport.month')}
              </Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Form.Item name="aggregationType">
            <Radio.Group buttonStyle="solid">
              <Radio.Button value="detailed-date">
                {t('pages.manager.aggregatedReport.detailed-date')}
              </Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Divider />

          {/* Daily / Weekly / Monthly */}
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.aggregationType !== currentValues.aggregationType
            }
          >
            {({ getFieldValue }) => {
              const aggregationType: AggregatedReportType =
                getFieldValue('aggregationType');
              return (
                <Space direction="vertical">
                  <Text>
                    {t('pages.manager.aggregatedReport.info', {
                      context:
                        aggregationType === 'detailed-date' ? 'detailed' : null,
                      level:
                        currentConstructionSite?.aggregatedReportActivityLevel,
                    })}
                  </Text>
                  <Space direction="horizontal" size="large">
                    {aggregationType !== 'periodic' ? (
                      <Form.Item
                        label={t('pages.form.date.label')}
                        name="picker"
                        rules={[
                          {
                            required: true,
                            message: t('pages.form.date.alert'),
                          },
                        ]}
                      >
                        <DatePicker
                          className="input"
                          picker={
                            aggregationType === 'detailed-date'
                              ? 'date'
                              : aggregationType
                          }
                          format="L"
                        />
                      </Form.Item>
                    ) : null}
                    {aggregationType === 'periodic' ? (
                      <Form.Item
                        label={t('pages.form.date.label')}
                        name="range"
                        rules={[
                          {
                            required: true,
                            message: t('pages.form.date.alert'),
                          },
                        ]}
                      >
                        <DateRangePicker className="input" format="L" />
                      </Form.Item>
                    ) : null}
                    {aggregationType === 'date' ? (
                      <Form.Item
                        name="showTimeline"
                        valuePropName="checked"
                        label="Timeline"
                      >
                        <Checkbox className="input">
                          {t('pages.form.show-timeline.label')}
                        </Checkbox>
                      </Form.Item>
                    ) : null}
                    {aggregationType === 'detailed-date' ? (
                      <Form.Item
                        name="includeImages"
                        valuePropName="checked"
                        label={t('pages.form.include-images.checkbox.label')}
                      >
                        <Checkbox className="input">
                          {t('pages.form.include-images.label')}
                        </Checkbox>
                      </Form.Item>
                    ) : null}
                  </Space>
                </Space>
              );
            }}
          </Form.Item>

          <Form.Item className="button-center">
            <Button
              type="primary"
              htmlType="submit"
              icon={<DownloadOutlined />}
            >
              {t('pages.manager.aggregatedReport.generate')}
            </Button>
          </Form.Item>
        </Form>

        <MainPageButton
          to={{ pathname: Routes.ReportDashboardAnalyze }}
          className="button-center"
        />
      </CapCard>
    </Flex>
  );
}

export default ReportExportAggregatedPage;
