import { Empty } from 'antd';
import invariant from 'tiny-invariant';

import { useAppContext } from 'business/contextProviders/useAppContext';
import { useGetDataQuery } from 'business/steering/hooks/use-get-steering-data-query';
import { SteeringGraph } from 'business/steering/pages/graph-page/components/steering-graph';
import moduleStyles from 'business/steering/steering.module.scss';
import Loader from 'ui/loader';

export const SteeringGraphContainer = () => {
  const { currentConstructionSiteId: constructionSiteId } = useAppContext();
  invariant(constructionSiteId, 'No construction site id');

  const { data, loading } = useGetDataQuery(constructionSiteId);

  if (loading) {
    return <Loader />;
  }

  if (!data || !data.parameter_ring.length) {
    return <Empty />;
  }

  return (
    <div className={moduleStyles.graphContainer}>
      <SteeringGraph data={data?.parameter_ring} />
    </div>
  );
};
