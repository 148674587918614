import { DatePicker } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';

import { useMediaType } from 'technical/media/hooks';
import { MobileRangePicker } from 'ui/form/date-range-picker/mobile-range-picker';

export const DatetimeRangePicker = (
  props: Omit<RangePickerProps, 'showTime' | 'format'>,
) => {
  const { isMobile } = useMediaType();

  if (isMobile) {
    return <MobileRangePicker picker="datetime-local" />;
  }

  return (
    <DatePicker.RangePicker
      {...props}
      showTime={{
        format: 'HH:mm',
      }}
      format="L HH:mm"
    />
  );
};
