import dayjs, { Dayjs } from 'dayjs';

import { TIME_HOUR_MINUTE_FORMAT } from 'technical/string/formatter';
import { isUndefinedOrNull } from 'technical/utils/is-null-or-undefined';

export function formatTime(timestamp: Dayjs): Dayjs {
  return timestamp.second(0).millisecond(0);
}

function parseTimeString(timeString: string) {
  return dayjs(timeString, TIME_HOUR_MINUTE_FORMAT);
}

/**
 * @description Compare two time string without date or timezone
 */
export const isOnTwoDays = (startTime: string, endTime: string) =>
  parseTimeString(startTime).isAfter(parseTimeString(endTime));

export const hoursInArray = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23,
];

export const ensureD3NumberValueIsDate = (
  value: Date | d3.NumberValue,
): Date | number => {
  if (value instanceof Date) {
    return value;
  }

  if (typeof value === 'number') {
    return value;
  }

  return value.valueOf();
};

export function parseDurationStringToMilliseconds(
  durationString?: string | null,
) {
  if (isUndefinedOrNull(durationString)) {
    return 0;
  }

  return dayjs.duration(durationString).asMilliseconds();
}

export const setDateToStartOfDay = (date: Dayjs) =>
  date.hour(0).minute(0).second(0).millisecond(0);
