import { Button as AntdButton } from 'antd';
import { ButtonProps as AntdButtonProps } from 'antd/lib/button/button';
import React from 'react';

export interface ButtonProps extends AntdButtonProps {
  eventName?: string;
  logEventData?: Record<string, unknown>;
}

const Button = ({
  onClick: originalOnClick,
  iconPosition = 'end',
  ...props
}: ButtonProps) => {
  const [isLoading, setIsLoading] = React.useState(false);

  return (
    <AntdButton
      loading={isLoading}
      iconPosition={iconPosition}
      {...props}
      onClick={(...args) => {
        if (originalOnClick) {
          setIsLoading(true);
          Promise.resolve(originalOnClick(...args)).finally(() =>
            setIsLoading(false),
          );
        }
      }}
    />
  );
};

export default Button;
