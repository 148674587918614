import { Col, Flex, message, Row, Typography } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'translations/hooks';

import { useAppContext } from 'business/contextProviders/useAppContext';
import { usePermissions } from 'business/contextProviders/usePermissions';
import MaintenanceAlert from 'business/user/components/maintenanceAlert';
import historyIcon from 'business/user/pages/Dashboard/assets/history.svg';
import newReport from 'business/user/pages/Dashboard/assets/newReport.svg';
import pendingReport from 'business/user/pages/Dashboard/assets/pendingReport.svg';
import waitingValidationIcon from 'business/user/pages/Dashboard/assets/waitingValidation.svg';
import DashboardTile from 'business/user/pages/Dashboard/components/dashboard-tile';
import styles from 'business/user/pages/Dashboard/index.module.scss';
import Routes from 'config/routes';
import { useCountShiftReportToValidateQuery } from 'generated/graphql';

const { Text } = Typography;

export function VisualizeShiftReportDashboardPage() {
  const { t } = useTranslation();
  const { currentConstructionSite } = useAppContext();
  const {
    modules: {
      SHIFT_REPORT: { hasManagerReadPermission, hasManagerEditPermission },
    },
  } = usePermissions();

  const managerValidationRequired =
    currentConstructionSite?.managerValidationRequired;
  const id = currentConstructionSite?.id;

  const countShiftReportToValidateQuery = useCountShiftReportToValidateQuery({
    variables: { constructionSiteId: id },
    fetchPolicy: 'network-only',
    skip: !hasManagerReadPermission,
  });

  useEffect(() => {
    if (countShiftReportToValidateQuery.error) {
      message.error(t('errors.error_generic'));
    }
  }, [countShiftReportToValidateQuery.error, t]);

  const loading = countShiftReportToValidateQuery.loading;

  const count =
    countShiftReportToValidateQuery.data?.shiftReportCount.aggregate?.count;
  const shiftReportToValidationText = countShiftReportToValidateQuery.data
    ?.shiftReportCount.aggregate ? (
    <Text>
      {t('pages.manager.validation.text', {
        count: count || 0,
        context: hasManagerEditPermission ? 'manager' : 'reader',
      })}
    </Text>
  ) : (
    <Text>{t('pages.manager.validation.textError')}</Text>
  );

  return (
    <Flex vertical align="center" className={styles.container}>
      <MaintenanceAlert />
      <Row gutter={[30, 30]} className={styles.menuContainer}>
        {managerValidationRequired &&
          hasManagerReadPermission &&
          !currentConstructionSite?.isCompleted && (
            <Col xs={24} md={12}>
              <DashboardTile
                title={t('pages.manager.validation.title')}
                icon={waitingValidationIcon}
                loading={loading}
                text={shiftReportToValidationText}
                route={Routes.ReportListToValidate}
                ctaLabel={t('pages.manager.validation.button')}
              />
            </Col>
          )}
        {hasManagerReadPermission && !currentConstructionSite?.isCompleted && (
          <Col xs={24} md={12}>
            <DashboardTile
              title={t('pages.manager.pending.title')}
              icon={pendingReport}
              loading={false}
              route={Routes.ReportListInProgress}
              ctaLabel={t('pages.manager.pending.button')}
            />
          </Col>
        )}
        <Col xs={24} md={12}>
          <DashboardTile
            title={t('pages.manager.history.title')}
            icon={historyIcon}
            loading={false}
            route={Routes.ReportListValidated}
            ctaLabel={t('pages.manager.history.button')}
          />
        </Col>
        {hasManagerEditPermission && !currentConstructionSite?.isCompleted && (
          <Col xs={24} md={12}>
            <DashboardTile
              title={t('pages.manager.new.title')}
              icon={newReport}
              loading={false}
              route={Routes.ReportCreate}
              ctaLabel={t('pages.manager.new.button')}
            />
          </Col>
        )}
      </Row>
    </Flex>
  );
}
