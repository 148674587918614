import { Flex } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'translations/hooks';
import { getLocale } from 'translations/locale';

import { useAppContext } from 'business/contextProviders/useAppContext';
import { PlotterMode } from 'business/data-analysis/constants';
import { UseFilters } from 'business/data-analysis/pages/graph/components/filter-card/hooks/use-filters';
import { useGraphSet } from 'business/data-analysis/pages/graph/hooks/graph-context/contexts';
import { useMode } from 'business/data-analysis/pages/graph/hooks/use-mode';
import config from 'config';
import AppLogo from 'ui/appLogo';

import styles from './index.module.scss';

interface Props {
  filters: ReturnType<UseFilters>['filters'];
}

export const DataAnalysePrintTitle = ({ filters }: Props) => {
  const { t } = useTranslation();
  const locale = getLocale();
  const { currentConstructionSite } = useAppContext();

  let dateRange, ringRange;

  const {
    structure: { title },
  } = useGraphSet();

  const mode = useMode();

  if (mode === PlotterMode.TIME_PERIOD) {
    dateRange = filters.dateFilters;
  }
  if (mode === PlotterMode.RING) {
    ringRange = filters.ringFilters;
  }

  const range = {
    [PlotterMode.TIME_PERIOD]: t('common.printTitle.dateRange', {
      startDate: dayjs.tz(dateRange?.startDate).locale(locale).format('L'),
      endDate: dayjs.tz(dateRange?.endDate).locale(locale).format('L'),
    }),
    [PlotterMode.RING]: t('common.printTitle.ringRange', {
      startRing: ringRange?.[0],
      endRing: ringRange?.[1],
    }),
  };

  return (
    <Flex vertical className={styles.header}>
      <Flex justify="center" gap={20}>
        <AppLogo />
        {currentConstructionSite?.logo ? (
          <AppLogo
            alt="Construction site logo"
            path={`${config.gcp.publicUri}/${currentConstructionSite.logo}`}
          />
        ) : null}
      </Flex>
      {currentConstructionSite?.name ? (
        <h1 className={styles.constructionSiteName}>
          {currentConstructionSite?.name}
        </h1>
      ) : null}
      <h1>{title}</h1>
      <h3>{range[mode]}</h3>
    </Flex>
  );
};
