import {
  CheckCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  EyeOutlined,
  FileImageOutlined,
} from '@ant-design/icons';
import {
  Descriptions,
  Flex,
  Popconfirm,
  Space,
  Typography,
  message,
} from 'antd';
import { Link, createSearchParams } from 'react-router-dom';
import { useTranslation } from 'translations/hooks';

import { useAppContext } from 'business/contextProviders/useAppContext';
import { usePermissions } from 'business/contextProviders/usePermissions';
import { formatReportDateStringToLocalizedDateString } from 'business/shift-report/report/services/timeOperations';
import Routes from 'config/routes';
import {
  ShiftReportCardInfoFragment,
  useDeleteShiftReportMutation,
} from 'generated/graphql';
import logger from 'technical/logger';
import { useMediaType } from 'technical/media/hooks';
import { formatUserName } from 'technical/string/formatter';
import Button from 'ui/button';
import CapCard from 'ui/cap-card';

import styles from './index.module.scss';

const getMessageKey = (id: string) => `delete-shift-report:${id}`;

interface Props {
  report: ShiftReportCardInfoFragment;
  refetchList: () => Promise<unknown>;
  routeRedirection?: string;
}

function ReportCard({
  report,
  refetchList,
  routeRedirection = Routes.ReportListInProgress,
}: Props) {
  const { t } = useTranslation();
  const { isMobile } = useMediaType();
  const { user } = useAppContext();
  const {
    modules: {
      SHIFT_REPORT: {
        hasManagerReadPermission,
        hasManagerEditPermission,
        isConstructionSiteFullReader,
      },
    },
  } = usePermissions();
  const [deleteReport] = useDeleteShiftReportMutation();

  const isUserReport = report.userId === user?.id;
  const isFinished = report.operatorValidation || isConstructionSiteFullReader;
  const isValidated = report.managerValidation;
  const reportTitle = t(`pages.report.title`, {
    date: formatReportDateStringToLocalizedDateString(report.date),
    shiftName: report.shift.name,
    context: isFinished ? 'prev' : '',
  });
  const ctaLink =
    !report.operatorValidation ||
    (!report.managerValidation && hasManagerReadPermission)
      ? Routes.ReportEdit.replace(':id', report.id)
      : Routes.ReportView.replace(':id', report.id);

  const fileCount = report.tasks_aggregate.files?.count ?? 0;

  const removeReport = async () => {
    try {
      message.loading({
        content: t('pages.report.list.deleteMessage'),
        key: getMessageKey(report.id),
        // No auto-dismiss
        duration: 0,
      });
      const { data, errors } = await deleteReport({
        variables: {
          id: report.id,
        },
      });
      message.destroy(getMessageKey(report.id));

      if (errors || !data?.delete_shiftReport_by_pk?.id) {
        message.warning(t('pages.report.list.deleteReportFail'));
        logger.error(errors);
      } else {
        message.success(t('pages.report.list.deleteReportSuccess'));
        await refetchList();
      }
    } catch (error) {
      message.error(t('pages.report.list.deleteReportFail'));
      logger.error(error);
    }
  };

  return (
    <CapCard
      title={
        <>
          {reportTitle}
          {fileCount > 0 ? (
            <FileImageOutlined style={{ marginLeft: 8 }} />
          ) : null}
        </>
      }
      isFinished={isFinished}
      isValidated={isValidated}
      className={styles.reportCard}
    >
      {!isFinished && <EditOutlined className={styles.editIcon} />}
      {isValidated && <CheckCircleOutlined className={styles.checkIcon} />}
      <Flex vertical align="center" className={styles.reportCardBody}>
        <Flex>
          <Descriptions
            column={1}
            className="first-info-block-width"
            layout={isMobile ? 'vertical' : 'horizontal'}
            colon={!isMobile}
          >
            <Descriptions.Item
              label={
                <Typography.Text strong>
                  {t('pages.form.creator.label')}
                </Typography.Text>
              }
            >
              {report.userName}
            </Descriptions.Item>

            <Descriptions.Item
              label={
                <Typography.Text strong>
                  {t('pages.form.operator.label')}
                </Typography.Text>
              }
            >
              {report.operator?.name}
            </Descriptions.Item>
            <Descriptions.Item
              label={
                <Typography.Text strong>
                  {t('pages.form.shiftLeader.label')}
                </Typography.Text>
              }
            >
              {report.shiftLeader?.name}
            </Descriptions.Item>
            {report.team && (
              <Descriptions.Item
                label={
                  <Typography.Text strong>
                    {t('pages.form.team.label')}
                  </Typography.Text>
                }
              >
                {report.team.name}
              </Descriptions.Item>
            )}

            {(report.shiftEngineer || report.shiftManager) && (
              <>
                {report.shiftEngineer && (
                  <Descriptions.Item
                    label={
                      <Typography.Text strong>
                        {t('pages.form.shiftEngineer.label')}
                      </Typography.Text>
                    }
                  >
                    {formatUserName(report.shiftEngineer)}
                  </Descriptions.Item>
                )}
                {report.shiftManager && (
                  <Descriptions.Item
                    label={
                      <Typography.Text strong>
                        {t('pages.form.shiftManager.label')}
                      </Typography.Text>
                    }
                  >
                    {formatUserName(report.shiftManager)}
                  </Descriptions.Item>
                )}
              </>
            )}
          </Descriptions>

          <Descriptions
            column={1}
            size="small"
            layout={isMobile ? 'vertical' : 'horizontal'}
            colon={!isMobile}
          >
            <Descriptions.Item
              label={
                <Typography.Text strong>
                  {t('pages.form.startMetricPoint.label')}
                </Typography.Text>
              }
            >
              {report.startMetricPoint}
            </Descriptions.Item>
            {report.endMetricPoint && (
              <Descriptions.Item
                label={
                  <Typography.Text strong>
                    {t('pages.form.endMetricPoint.label')}
                  </Typography.Text>
                }
              >
                {report.endMetricPoint}
              </Descriptions.Item>
            )}

            {report.lastFinishedRing !== null &&
              report.lastFinishedRing !== -1 && (
                <Descriptions.Item
                  label={
                    <Typography.Text strong>
                      {t('pages.form.lastFinishedRing.label')}
                    </Typography.Text>
                  }
                >
                  {report.lastFinishedRing}
                </Descriptions.Item>
              )}
          </Descriptions>
        </Flex>
        <Descriptions
          column={1}
          size="small"
          layout={isMobile ? 'vertical' : 'horizontal'}
          colon={!isMobile}
        >
          <Descriptions.Item
            label={
              <Typography.Text strong>
                {t('pages.form.note.label')}
              </Typography.Text>
            }
            className={styles.comment}
          >
            {report.note ? report.note : 'N/A'}
          </Descriptions.Item>
        </Descriptions>

        <Space>
          {!isFinished && (hasManagerEditPermission || isUserReport) && (
            <Popconfirm
              title={t('pages.report.list.deletePopup')}
              icon={<ExclamationCircleFilled style={{ color: 'red' }} />}
              onConfirm={removeReport}
              okText={t('pages.report.list.delete')}
              okButtonProps={{ danger: true }}
            >
              <Button icon={<DeleteOutlined />} />
            </Popconfirm>
          )}
          <Link
            to={{
              pathname: ctaLink,
              search: createSearchParams({
                redirect: routeRedirection,
              }).toString(),
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              icon={isFinished ? <EyeOutlined /> : <EditOutlined />}
            >
              {t('pages.report.ctaButton', {
                context: isUserReport && !isFinished ? 'edit' : 'read',
              })}
            </Button>
          </Link>
        </Space>
      </Flex>
    </CapCard>
  );
}

export default ReportCard;
