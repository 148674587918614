import { ReportChartMode } from 'business/shift-report/report/types';
import { TaskFullFragment } from 'generated/graphql';

import { abscissaScale, ordinalScale } from './axis';
import {
  getTaskHeight,
  getTaskStyle,
  getTaskWidth,
  getTaskXPos,
  getTaskYPos,
} from './metadata/task';
import {
  getTaskTextTransformations,
  getTaskTextValue,
  getTaskTextXPos,
  getTaskTextYPos,
} from './metadata/taskText';
import { handleMouseOut, handleMouseOver } from './metadata/tooltip';
import {
  ExternalEventHandlers,
  CreateElementGroup,
  UpdateElementGroup,
} from './metadata/types';

export const createTasks = (
  taskGroup: CreateElementGroup<TaskFullFragment>,
  xScale: abscissaScale,
  yScale: ordinalScale,
  isTablet: boolean,
  mode: ReportChartMode,
  externalEventHandlers?: ExternalEventHandlers,
) => {
  // Add rectangle for task
  taskGroup
    .append('rect')
    // Set position on the chart
    .attr('x', getTaskXPos(xScale))
    .attr('y', getTaskYPos(yScale))
    // Set round corners
    .attr('rx', 5)
    .attr('ry', 5)
    // Set size
    .attr('width', getTaskWidth(xScale))
    .attr('height', getTaskHeight(yScale))
    // Set style
    .attr('style', getTaskStyle);

  // Place task text if enable
  taskGroup
    .append('text')
    .attr('x', getTaskTextXPos(xScale))
    .attr('y', getTaskTextYPos(yScale))
    .text(getTaskTextValue)
    .attr('transform', getTaskTextTransformations(xScale, yScale))
    .attr('font-weight', 'bold')
    .attr('dominant-baseline', 'middle')
    .attr('text-anchor', 'middle');

  taskGroup.raise();

  if (externalEventHandlers) {
    const { handleTaskMouseClick } = externalEventHandlers;
    taskGroup
      // Event trigger
      .on('click', handleTaskMouseClick);
  }
  if (!isTablet) {
    taskGroup
      .on('mouseover', handleMouseOver(yScale, mode))
      .on('mouseout', handleMouseOut);
  }

  return taskGroup;
};

export const updateTasks = (
  taskGroup: UpdateElementGroup<TaskFullFragment>,
  xScale: abscissaScale,
  yScale: ordinalScale,
  isTablet: boolean,
  mode: ReportChartMode,
) => {
  // Update previous tasks
  taskGroup
    .select('rect')
    .attr('x', getTaskXPos(xScale))
    .attr('y', getTaskYPos(yScale))
    .attr('height', getTaskHeight(yScale))
    .attr('width', getTaskWidth(xScale))
    .attr('style', getTaskStyle)
    .transition();

  taskGroup
    .select('text')
    .attr('x', getTaskTextXPos(xScale))
    .attr('y', getTaskTextYPos(yScale))
    .text(getTaskTextValue)
    .attr('transform', getTaskTextTransformations(xScale, yScale))
    .attr('font-weight', 'bold')
    .attr('dominant-baseline', 'middle')
    .attr('text-anchor', 'middle')
    .transition();

  taskGroup.raise();

  // Custom triggers
  if (!isTablet) {
    taskGroup
      .on('mouseover', handleMouseOver(yScale, mode))
      .on('mouseout', handleMouseOut);
  } else {
    taskGroup.on('mouseover', null).on('mouseout', null);
  }

  // Remove deleted task
  taskGroup.exit().remove();

  return taskGroup;
};
