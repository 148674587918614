import {
  CheckOutlined,
  CloseOutlined,
  CopyOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import {
  App,
  Cascader,
  Col,
  Drawer,
  Form,
  InputNumber,
  Modal,
  Popconfirm,
  Row,
  Space,
  Switch,
  TimePicker,
  Tooltip,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { UploadFile } from 'antd/lib/upload/interface';
import dayjs, { Dayjs } from 'dayjs';
import isEqual from 'lodash.isequal';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'translations/hooks';
import { v4 as uuid } from 'uuid';

import { useAppContext } from 'business/contextProviders/useAppContext';
import { ACTIVITY_TYPES } from 'business/shift-report/activity/types';
import { QUERY_SHIFT_REPORT_METADATA } from 'business/shift-report/report/pages/ReportEdit/query.gql';
import { parseShiftTimeStringWithReportDate } from 'business/shift-report/shift/services/timeOperations';
import { taskFragmentUpdate } from 'business/shift-report/task/query.gql';
import {
  formatFileListToFileUploadWithUrl,
  getInitialValues,
} from 'business/shift-report/task/services';
import { NewTaskFormValues } from 'business/shift-report/task/types';
import {
  ActivityFullFragment,
  ActivityInvertedTreeFullFragment,
  ActivityTreeFullFragment,
  Scalars,
  ShiftFullFragment,
  TaskFullFragment,
  useCreateNewTaskMutation,
  useDeleteTaskMutation,
  useGetTaskFilesUrlsQuery,
  useUpdateExistingTaskMutation,
  useUpdateTaskFilesMutation,
} from 'generated/graphql';
import errorReporting from 'technical/error-reporting';
import logger from 'technical/logger';
import { TIME_HOUR_MINUTE_FORMAT } from 'technical/string/formatter';
import {
  customLimitedInputLength,
  requiredErrorAlert,
} from 'technical/validation/rules';
import Button from 'ui/button';
import FileUpload from 'ui/form/fileUpload';
import {
  REPORT_FILES_UPLOAD_LIMIT,
  TASK_FILES_UPLOAD_LIMIT,
  formatFileUploadValueToDatabaseMetadata,
} from 'ui/form/fileUpload/services';
import { UploadMetadata } from 'ui/form/fileUpload/types';

import './index.scss';
import {
  getDisabledTimeForShift,
  isTaskInShiftRange,
  setTimeRangeDate,
  taskFilter,
} from './services';

interface PreviewModal {
  visible: boolean;
  file?: UploadFile;
}

export interface UploadedTaskFile {
  taskId: string;
  uploadedFilesCount: number;
}

const getTranslationContext = (task?: TaskFullFragment) => {
  if (task && !task.isValidated) {
    return 'toValidate';
  }
  if (task) {
    return 'edit';
  }
  // Undefined will use translation for new task
  return undefined;
};

interface Props {
  visible: boolean;
  closeDrawer: () => void;
  resetDrawer: () => void;
  duplicateTask: () => void;
  shiftReportId: string;
  activities?: ActivityTreeFullFragment[];
  excavationOrBuildActivities?: ActivityFullFragment[];
  task?: TaskFullFragment;
  previousTask?: TaskFullFragment;
  criticalTasks?: TaskFullFragment[];
  previousRing?: number;
  taskTime?: [string, string];
  taskActivity?: ActivityInvertedTreeFullFragment;
  date: Dayjs;
  shift: ShiftFullFragment;
  uploadedTaskFiles: UploadedTaskFile[];
}

function NewTaskDrawer({
  visible,
  closeDrawer,
  resetDrawer,
  duplicateTask,
  shiftReportId,
  activities,
  excavationOrBuildActivities,
  task,
  previousTask,
  criticalTasks,
  previousRing,
  taskTime,
  taskActivity,
  date,
  shift,
  uploadedTaskFiles,
}: Props) {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const { currentConstructionSite } = useAppContext();
  const [form] = Form.useForm();
  const [previewFileModal, setPreviewFileModal] = useState<PreviewModal>({
    visible: false,
  });
  const [filesNotLinkedToTask, setFilesNotLinkedToTask] = useState<
    UploadMetadata[]
  >([]);

  const { data: fileUrlsData } = useGetTaskFilesUrlsQuery({
    variables: {
      fileNames: (
        (task?.files as UploadMetadata[]) || filesNotLinkedToTask
      ).map(({ bucketFileName }) => bucketFileName),
    },
    skip: !task && filesNotLinkedToTask.length === 0,
    fetchPolicy: 'no-cache',
  });

  const [createNewTask] = useCreateNewTaskMutation();
  const [updateTask] = useUpdateExistingTaskMutation({
    update: taskFragmentUpdate,
  });
  const [deleteTask] = useDeleteTaskMutation();
  const [updateTaskFiles] = useUpdateTaskFilesMutation();

  const translationContext = getTranslationContext(task);
  let prefillStartDate: Dayjs | undefined;
  if (!task && !previousTask) {
    prefillStartDate = parseShiftTimeStringWithReportDate(
      shift.startTime,
      date,
    );
  }

  // TODO: Refacto to fetch separate array for excavation and build activities
  const excavationActivitiesIds = excavationOrBuildActivities?.reduce(
    (acc: string[], { id, type }) =>
      type === ACTIVITY_TYPES.EXCAVATION ? [...acc, id] : acc,
    [],
  );
  const buildActivitiesIds = excavationOrBuildActivities?.reduce(
    (acc: string[], { id, type }) =>
      type === ACTIVITY_TYPES.BUILD ? [...acc, id] : acc,
    [],
  );

  const prefillStartDateValue = prefillStartDate?.valueOf();
  const prefillEndDate = dayjs();
  const prefillEndDateValue = prefillEndDate?.valueOf();

  useEffect(
    () => {
      const initialValues = getInitialValues({
        task,
        taskTime,
        taskActivity,
        previousTask,
        previousRing,
        prefillStartDate,
        prefillEndDate,
      });
      // Id used to store files if it's a new task
      // and then used as the id to create the task
      form.setFieldsValue({ ...initialValues, id: task?.id ?? uuid() });
    },
    // It is in fact exhaustive but we check the stable value of prefillStartDate
    // and not the object who change every render
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      form,
      taskTime,
      task,
      taskActivity,
      previousTask,
      previousRing,
      prefillStartDateValue,
      prefillEndDateValue,
    ],
  );

  useEffect(() => {
    if (
      fileUrlsData?.filesUrls?.urls &&
      fileUrlsData?.filesUrls?.urls.length > 0
    ) {
      form.setFieldsValue({
        files: formatFileListToFileUploadWithUrl(
          task?.files ?? filesNotLinkedToTask,
          fileUrlsData.filesUrls.urls,
        ),
      });
    }
  }, [form, task, filesNotLinkedToTask, fileUrlsData]);

  function checkCriticalOverlap(
    values: NewTaskFormValues,
    tasks: TaskFullFragment[],
  ) {
    let criticalOverlap = false;
    tasks.forEach((existingTask) => {
      if (
        !criticalOverlap &&
        values.startDate &&
        values.endDate &&
        ((values.startDate.isSameOrAfter(existingTask.startDate) &&
          values.startDate.isBefore(existingTask.endDate)) ||
          (values.endDate.isAfter(existingTask.startDate) &&
            values.endDate.isSameOrBefore(existingTask.endDate)))
      ) {
        Modal.warning({
          title: t('errors.criticalTaskOverlap.title'),
          content: t('errors.criticalTaskOverlap.content'),
        });
        criticalOverlap = true;
      }
    });
    return criticalOverlap;
  }

  async function addNewTask(values: NewTaskFormValues) {
    if (!values.activity) {
      message.error(t('pages.report.chart.drawer.taskMutationFailed'));
      return;
    }
    // check if this task doesn't overlap an existing critical task
    if (values.critical && criticalTasks) {
      const criticalOverlap = checkCriticalOverlap(values, criticalTasks);
      if (criticalOverlap) {
        return;
      }
    }

    const activityId = values.activity[values.activity.length - 1];
    try {
      const { data } = await createNewTask({
        variables: {
          object: {
            id: values.id,
            ring: values.ring,
            startDate: values.startDate,
            endDate: values.endDate,
            duration: values.endDate?.diff(values.startDate, 'minutes'),
            note: values.note,
            critical: values.critical,
            files: formatFileUploadValueToDatabaseMetadata(values.files),
            ended: values.ended,
            shiftReportId,
            activityId,
          },
        },
        refetchQueries: [
          {
            query: QUERY_SHIFT_REPORT_METADATA,
            variables: {
              shiftReportId,
              constructionSiteId: currentConstructionSite?.id,
            },
          },
        ],
      });
      if (data?.task) {
        closeDrawer();
        form.resetFields();
        message.success(t('pages.report.chart.drawer.taskMutationSuccess'));
      } else {
        message.warning(t('pages.report.chart.drawer.taskMutationFailed'));
      }
    } catch (newTaskError) {
      message.error(t('pages.report.chart.drawer.taskMutationFailed'));
      logger.error(newTaskError);
    }
  }

  async function editTask(id: Scalars['uuid'], values: NewTaskFormValues) {
    if (!values.activity) {
      message.error(
        t('pages.report.chart.drawer.taskMutationFailed', {
          context: translationContext,
        }),
      );
      return;
    }
    // check if this task doesn't overlap an existing critical task
    const otherCriticalTasks = criticalTasks?.filter(
      (criticalTask) => criticalTask.id !== id,
    );
    if (values.critical && otherCriticalTasks) {
      const criticalOverlap = checkCriticalOverlap(values, otherCriticalTasks);
      if (criticalOverlap) {
        return;
      }
    }
    const activityId = values.activity[values.activity.length - 1];
    try {
      const { data } = await updateTask({
        variables: {
          id: { id },
          input: {
            ring: values.ring,
            startDate: values.startDate,
            endDate: values.endDate,
            duration: values.endDate?.diff(values.startDate, 'minutes'),
            note: values.note,
            critical: values.critical,
            files: formatFileUploadValueToDatabaseMetadata(values.files),
            ended: values.ended,
            activityId,
          },
        },
      });
      if (data?.task) {
        closeDrawer();
        form.resetFields();
        message.success(
          t('pages.report.chart.drawer.taskMutationSuccess', {
            context: translationContext,
          }),
        );
      } else {
        message.warning(
          t('pages.report.chart.drawer.taskMutationFailed', {
            context: translationContext,
          }),
        );
      }
    } catch (newTaskError) {
      message.error(
        t('pages.report.chart.drawer.taskMutationFailed', {
          context: translationContext,
        }),
      );
      logger.error(newTaskError);
    }
  }

  async function removeTask() {
    if (task?.id) {
      try {
        const { data } = await deleteTask({
          variables: {
            id: task.id,
          },
          refetchQueries: [
            {
              query: QUERY_SHIFT_REPORT_METADATA,
              variables: { shiftReportId },
            },
          ],
        });
        if (data?.delete_task_by_pk) {
          closeDrawer();
          form.resetFields();
          message.success(
            t('pages.report.chart.drawer.taskMutationSuccess', {
              context: 'delete',
            }),
          );
        } else {
          message.warning(
            t('pages.report.chart.drawer.taskMutationFailed', {
              context: 'delete',
            }),
          );
        }
      } catch (newTaskError) {
        message.error(
          t('pages.report.chart.drawer.taskMutationFailed', {
            context: 'delete',
          }),
        );
        logger.error(newTaskError);
      }
    }
  }

  const uploadedFilesForOtherTasks = uploadedTaskFiles
    .filter((uploadedFiles) => uploadedFiles.taskId !== task?.id)
    .reduce((acc, { uploadedFilesCount }) => acc + uploadedFilesCount, 0);

  const uploadedFileForThisTask = fileUrlsData?.filesUrls?.urls?.length ?? 0;
  const isTaskUploadLimitReached =
    uploadedFileForThisTask >= TASK_FILES_UPLOAD_LIMIT;
  const isReportUploadLimitReached =
    uploadedFileForThisTask + uploadedFilesForOtherTasks >=
    REPORT_FILES_UPLOAD_LIMIT;

  const getUploadLimitErrorMessage = (children: React.ReactNode) => {
    if (!isReportUploadLimitReached || !isTaskUploadLimitReached) {
      return children;
    }

    return (
      <>
        {isReportUploadLimitReached ? (
          <div>{t('pages.form.fileUpload.reportUploadLimit')}</div>
        ) : null}
        {isTaskUploadLimitReached ? (
          <div>{t('pages.form.fileUpload.taskUploadLimit')}</div>
        ) : null}
      </>
    );
  };

  function handleDynamicFilesChange(changedFiles: UploadFile[]) {
    const readyFiles = changedFiles.filter(({ status }) => status === 'done');
    if (task) {
      const taskFilenames = (task.files as UploadMetadata[]).map(
        ({ name }) => name,
      );
      const readyFilenames = readyFiles.map(({ name }) => name);

      if (isEqual(taskFilenames, readyFilenames)) {
        return;
      }

      updateTaskFiles({
        variables: {
          id: { id: task.id },
          files: formatFileUploadValueToDatabaseMetadata(readyFiles),
        },
      }).catch((err) => {
        logger.error(err);
        errorReporting.error(err);
        message.error(t('pages.form.fileUpload.updateError'));
      });
    } else {
      setFilesNotLinkedToTask(
        formatFileUploadValueToDatabaseMetadata(readyFiles),
      );
    }
  }

  function getActivityType(activityIds?: string[]): ACTIVITY_TYPES | null {
    if (!activityIds) {
      return null;
    }

    const activityId = activityIds[activityIds.length - 1];
    if (excavationActivitiesIds?.includes(activityId)) {
      return ACTIVITY_TYPES.EXCAVATION;
    }
    if (buildActivitiesIds?.includes(activityId)) {
      return ACTIVITY_TYPES.BUILD;
    }

    return ACTIVITY_TYPES.OTHER;
  }

  function onFinish(values: NewTaskFormValues) {
    const [startDate, endDate] = setTimeRangeDate(date, shift)(
      values.startDate,
      values.endDate,
    );
    if (!isTaskInShiftRange(date, shift)(startDate, endDate)) {
      message.warning(t('pages.report.chart.notInRange'));
      return undefined;
    }
    if (task?.id) {
      return editTask(task.id, { ...values, startDate, endDate });
    }
    // reset files not linked
    setFilesNotLinkedToTask([]);
    return addNewTask({ ...values, startDate, endDate });
  }

  const handleFilePreview = (file: UploadFile) => {
    setPreviewFileModal({ visible: true, file });
  };

  const ctaButtonLabel = t('pages.report.chart.drawer.cta', {
    context: translationContext,
  });

  return (
    <Drawer
      forceRender
      className="drawer-sidebar"
      width={680}
      onClose={() => {
        closeDrawer();
      }}
      afterOpenChange={() => {
        if (!visible) {
          resetDrawer();
        }
      }}
      open={visible}
      title={t('pages.report.chart.drawer.label', {
        context: translationContext,
      })}
      footer={
        <Form
          form={form}
          layout="inline"
          name="newTaskFooter"
          onFinish={onFinish}
          onFinishFailed={requiredErrorAlert}
        >
          <Space direction="horizontal" style={{ margin: '0 auto' }}>
            <Form.Item
              className="button"
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.activity !== currentValues.activity ||
                prevValues.ended !== currentValues.ended
              }
              noStyle
            >
              {({ isFieldTouched, getFieldValue }) => {
                const isTaskEndedTouched = isFieldTouched('ended');
                const activityIds = getFieldValue('activity');
                const activityType = getActivityType(activityIds);
                // Cannot facto button composant because Button needs to be
                // the direct child of Popconfirm
                return !task &&
                  !isTaskEndedTouched &&
                  (activityType === ACTIVITY_TYPES.EXCAVATION ||
                    activityType === ACTIVITY_TYPES.BUILD) ? (
                  <Popconfirm
                    title={t(
                      'pages.report.chart.taskEnded.endFormConfirmation',
                      { context: activityType },
                    )}
                    onConfirm={() => form.submit()}
                    okText={t('pages.report.chart.taskEnded.ended')}
                    cancelText={t('pages.report.chart.taskEnded.notEnded')}
                  >
                    <Button type="primary" icon={<CheckOutlined />}>
                      {ctaButtonLabel}
                    </Button>
                  </Popconfirm>
                ) : (
                  <Button
                    type="primary"
                    htmlType="submit"
                    icon={<CheckOutlined />}
                  >
                    {ctaButtonLabel}
                  </Button>
                );
              }}
            </Form.Item>
            {task && (
              <Button icon={<DeleteOutlined />} onClick={removeTask}>
                {t('pages.report.chart.drawer.ctaRemove')}
              </Button>
            )}
          </Space>
        </Form>
      }
    >
      <Form
        form={form}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        name="newTask"
        layout="horizontal"
        size="middle"
      >
        <Row>
          <Col span={task ? 16 : 24}>
            <Form.Item
              label={t('pages.form.activity.label')}
              name="activity"
              rules={[
                { required: true, message: t('pages.form.activity.alert') },
              ]}
            >
              <Cascader
                placeholder={t('pages.form.activity.placeholder')}
                options={activities}
                showSearch={{ filter: taskFilter }}
              />
            </Form.Item>
          </Col>
          {task && (
            <Col offset={1} span={7}>
              {/* Add blank character as label to fix broken height if
                  there is no label or only a space is provided */}
              <Form.Item label="&#8203;">
                <Button
                  onClick={() => duplicateTask()}
                  icon={<CopyOutlined />}
                  style={{ width: '100%' }}
                >
                  {t('pages.report.chart.drawer.ctaDuplicate')}
                </Button>
              </Form.Item>
            </Col>
          )}
        </Row>

        <Row>
          <Col span={11}>
            <Form.Item
              label={t('pages.form.startTime.label')}
              name="startDate"
              rules={[
                { required: true, message: t('pages.form.startTime.alert') },
              ]}
            >
              <TimePicker
                style={{ width: '100%' }}
                format={TIME_HOUR_MINUTE_FORMAT}
                disabledTime={() => getDisabledTimeForShift(shift, date)}
                needConfirm={false}
                hideDisabledOptions
                showNow={false}
              />
            </Form.Item>
          </Col>
          <Col span={11} offset={2}>
            <Form.Item
              label={t('pages.form.endTime.label')}
              name="endDate"
              rules={[
                {
                  required: true,
                  message: t('pages.form.endTime.alert'),
                },
              ]}
            >
              <TimePicker
                style={{ width: '100%' }}
                format={TIME_HOUR_MINUTE_FORMAT}
                disabledTime={() => getDisabledTimeForShift(shift, date)}
                needConfirm={false}
                hideDisabledOptions
                showNow={false}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={5}>
            <Form.Item
              label={t('pages.form.ring.label')}
              name="ring"
              rules={[
                {
                  required: true,
                  message: t('pages.form.ring.alert'),
                },
              ]}
            >
              <InputNumber style={{ width: '100%' }} step={1} />
            </Form.Item>
          </Col>
          <Col span={4} offset={2}>
            <Form.Item
              label={t('pages.form.critical.label')}
              name="critical"
              valuePropName="checked"
            >
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                defaultChecked
              />
            </Form.Item>
          </Col>
          <Col span={11} offset={2}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.activity !== currentValues.activity
              }
            >
              {({ getFieldValue }) => {
                const activityIds = getFieldValue('activity');
                const activityType = getActivityType(activityIds);
                return activityType === ACTIVITY_TYPES.EXCAVATION ||
                  activityType === ACTIVITY_TYPES.BUILD ? (
                  <Form.Item
                    name="ended"
                    label={
                      <Space direction="horizontal">
                        {t('pages.form.ended.label', {
                          context: activityType,
                        })}
                        <Tooltip
                          title={t('pages.form.ended.tooltip', {
                            context: activityType,
                          })}
                        >
                          <QuestionCircleOutlined />
                        </Tooltip>
                      </Space>
                    }
                    rules={[{ required: true }]}
                    valuePropName="checked"
                  >
                    <Switch
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                    />
                  </Form.Item>
                ) : null;
              }}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label={t('pages.form.note.label')}
              name="note"
              rules={[customLimitedInputLength(500)]}
            >
              <TextArea
                placeholder={t('pages.form.note.taskPlaceholder', {
                  number: 500,
                })}
                autoSize={{ minRows: 2, maxRows: 4 }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label={t('pages.form.fileUpload.label')} name="files">
              <FileUpload
                uploadFolder={`task-files/${form.getFieldValue('id')}`}
                onDynamicUpdate={handleDynamicFilesChange}
                listType="picture-card"
                onPreview={handleFilePreview}
                disableUpload={
                  isReportUploadLimitReached || isTaskUploadLimitReached
                }
              >
                {getUploadLimitErrorMessage(
                  <div className="file-upload">
                    <p className="ant-upload-text">
                      {t('pages.form.fileUpload.taskUploadLimitCount', {
                        remaining:
                          TASK_FILES_UPLOAD_LIMIT - uploadedFileForThisTask,
                      })}
                    </p>
                    <p className="ant-upload-text">
                      {t('pages.form.fileUpload.reportUploadLimitCount', {
                        remaining:
                          REPORT_FILES_UPLOAD_LIMIT -
                          uploadedFilesForOtherTasks -
                          uploadedFileForThisTask,
                      })}
                    </p>
                    <p className="ant-upload-drag-icon">
                      <UploadOutlined />
                    </p>
                    <p className="ant-upload-text">
                      {t('pages.form.fileUpload.draggerText')}
                    </p>
                    <p className="ant-upload-hint" />
                  </div>,
                )}
              </FileUpload>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Modal
        open={previewFileModal.visible}
        title={previewFileModal.file?.name}
        footer={null}
        onCancel={() => setPreviewFileModal({ visible: false })}
      >
        <img
          className="preview-img-modal"
          alt={previewFileModal.file?.name}
          src={previewFileModal.file?.url}
        />
      </Modal>
    </Drawer>
  );
}

export default NewTaskDrawer;
