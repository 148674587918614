import { Select, Space } from 'antd';
import { Dayjs } from 'dayjs';
import React, { useState } from 'react';
import { useTranslation } from 'translations/hooks';

import { DateRangeType } from 'technical/types';
import { DatePicker } from 'ui/form/date-picker';
import { DateRangePicker } from 'ui/form/date-range-picker';

import styles from './index.module.scss';

export enum DateSelectionTypeEnum {
  Period = 'period',
  Date = 'date',
  Week = 'week',
  Month = 'month',
}

export interface TemporalitySelectInputProps {
  value?: DateRangeType;
  onChange?: (value: DateRangeType) => void;
  defaultType?: DateSelectionTypeEnum;
  allowClear?: boolean;
  allowEmpty?: boolean;
  defaultDate?: Dayjs | null;
  excludedOptions?: DateSelectionTypeEnum[];
  noDefaultFor?: DateSelectionTypeEnum[];
}

const TemporalitySelectInput: React.FC<TemporalitySelectInputProps> = ({
  value,
  onChange,
  defaultType = DateSelectionTypeEnum.Period,
  allowEmpty = true,
  allowClear = true,
  defaultDate = null,
  excludedOptions = [],
  noDefaultFor = [],
}) => {
  const { t } = useTranslation();
  const [currentType, setCurrentType] =
    useState<DateSelectionTypeEnum>(defaultType);

  const range = value ?? [null, null];

  const [min] = range;

  const handleDataReset = () => {
    if (onChange) {
      onChange([null, null]);
    }
  };

  const handleDatesChange = (dates: DateRangeType) => {
    if (dates && onChange) {
      onChange(dates);
    } else {
      handleDataReset(); // if we have no data the user click clear button
    }
  };

  const handleSelectChange = (type: DateSelectionTypeEnum) => {
    setCurrentType(type);
    if (defaultDate && !noDefaultFor.includes(type)) {
      // if we have a default date we assume we never want an empty date, so we determine a date according to the default date
      // another approach could use the previous date value in select
      switch (type) {
        case DateSelectionTypeEnum.Period:
          handleDatesChange([defaultDate.subtract(1, 'week'), defaultDate]);
          break;
        case DateSelectionTypeEnum.Date:
        case DateSelectionTypeEnum.Week:
        case DateSelectionTypeEnum.Month:
          handleDatesChange([
            defaultDate.startOf(type),
            defaultDate.endOf(type),
          ]);
          break;
      }
    } else {
      handleDataReset();
    }
  };

  const options = [
    {
      label: t('time.temporalitySelect.period'),
      value: DateSelectionTypeEnum.Period,
    },
    {
      label: t('time.temporalitySelect.date'),
      value: DateSelectionTypeEnum.Date,
    },
    {
      label: t('time.temporalitySelect.week'),
      value: DateSelectionTypeEnum.Week,
    },
    {
      label: t('time.temporalitySelect.month'),
      value: DateSelectionTypeEnum.Month,
    },
  ].filter((option) => !excludedOptions.includes(option.value));

  return (
    <Space direction={'horizontal'}>
      <Select
        value={currentType}
        onChange={handleSelectChange}
        options={options}
        className={styles.tempoSelector}
      />

      {currentType === DateSelectionTypeEnum.Period ? (
        <DateRangePicker
          value={range}
          className={styles.range}
          allowClear={allowClear}
          allowEmpty={allowEmpty}
          onChange={(dates) => handleDatesChange(dates)}
          format="L"
        />
      ) : (
        <DatePicker
          value={min}
          allowClear={allowClear}
          className={styles.date}
          onChange={(date) => {
            if (date) {
              handleDatesChange([
                date.startOf(currentType),
                date.endOf(currentType),
              ]);
            } else {
              handleDataReset();
            }
          }}
          picker={currentType}
          format="L"
        />
      )}
    </Space>
  );
};

export default TemporalitySelectInput;
