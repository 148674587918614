import { Flex } from 'antd';
import classNames from 'classnames';
import { PropsWithChildren } from 'react';

import style from './index.module.scss';

export const ReportListPageTemplate = ({ children }: PropsWithChildren) => {
  return (
    <Flex
      vertical
      flex={1}
      gap={10}
      align="center"
      className={classNames(style.page, 'page-appear')}
    >
      {children}
    </Flex>
  );
};
