import { GoogleChartWrapperChartType } from 'react-google-charts/dist/types';
import { z } from 'zod';

import {
  RingAggregationCriticalTaskEnum,
  RingAggregationTasksAnalyzerFullFragment,
  ShiftReportCriticalTaskEnum,
  ShiftReportGroupByEnum,
  ShiftReportTasksAnalyzerFullFragment,
} from 'generated/graphql';
import { googleChartWrapperChartSchema } from 'technical/type-schemas/google-chart';
import { dateRangeSchema } from 'technical/types';
import {
  NumberRangeValue,
  numberRangeSchema,
} from 'ui/form/number-range-input';

const analysisTypeSchema = z.union([z.literal('date'), z.literal('ring')]);
export type AnalysisType = z.infer<typeof analysisTypeSchema>;

export type DataRecord = Record<string | number, number[]>;

const taskAnalyzerQueryOptionsSchema = z.object({
  analysisType: analysisTypeSchema,
  dateRangeValue: dateRangeSchema,
  ringRangeValue: numberRangeSchema,
  chartType: googleChartWrapperChartSchema,
  groupByType: z.nativeEnum(ShiftReportGroupByEnum),
  dateCriticalType: z.nativeEnum(ShiftReportCriticalTaskEnum),
  ringCriticalType: z.nativeEnum(RingAggregationCriticalTaskEnum),
  activityLevelAggregation: z.number(),
  vChartScale: numberRangeSchema,
});

export type TaskAnalyzerQueryOptions = z.infer<
  typeof taskAnalyzerQueryOptionsSchema
>;

export type ShiftReportGroupByEnumWithRing = ShiftReportGroupByEnum | 'ring';

export interface GraphMeta {
  analysisType: AnalysisType;
  chartDateRawData?: ShiftReportTasksAnalyzerFullFragment[] | null;
  chartRingRawData?: RingAggregationTasksAnalyzerFullFragment[] | null;
  groupByType: ShiftReportGroupByEnumWithRing;
  chartType: GoogleChartWrapperChartType;
  vChartScale?: NumberRangeValue;
}

export const CHART_TYPES: {
  type: GoogleChartWrapperChartType;
  label: string;
}[] = [
  { type: 'ColumnChart', label: 'tasksAnalyzer.chartOptions.ColumnChart' },
  { type: 'LineChart', label: 'tasksAnalyzer.chartOptions.LineChart' },
  { type: 'Table', label: 'tasksAnalyzer.chartOptions.Table' },
  { type: 'AreaChart', label: 'tasksAnalyzer.chartOptions.AreaChart' },
  { type: 'PieChart', label: 'tasksAnalyzer.chartOptions.PieChart' },
];

export const GROUP_BY_OPTIONS = [
  {
    label: 'tasksAnalyzer.groupByOptions.report',
    value: ShiftReportGroupByEnum.Report,
  },
  {
    label: 'tasksAnalyzer.groupByOptions.day',
    value: ShiftReportGroupByEnum.Day,
  },
  {
    label: 'tasksAnalyzer.groupByOptions.week',
    value: ShiftReportGroupByEnum.Week,
  },
  {
    label: 'tasksAnalyzer.groupByOptions.month',
    value: ShiftReportGroupByEnum.Month,
  },
];

export const ACTIVITY_LEVEL_AGGREGATION = [
  {
    label: 'tasksAnalyzer.activityAggregationLevel',
    value: 1,
  },
  {
    label: 'tasksAnalyzer.activityAggregationLevel',
    value: 2,
  },
  {
    label: 'tasksAnalyzer.activityAggregationLevel',
    value: 3,
  },
  {
    label: 'tasksAnalyzer.activityAggregationLevel',
    value: 4,
  },
];

export const DATE_CRITICAL_TASKS_OPTIONS = [
  {
    label: 'tasksAnalyzer.criticalTaskOptions.both',
    value: ShiftReportCriticalTaskEnum.Both,
  },
  {
    label: 'tasksAnalyzer.criticalTaskOptions.critical',
    value: ShiftReportCriticalTaskEnum.Critical,
  },
  {
    label: 'tasksAnalyzer.criticalTaskOptions.nonCritical',
    value: ShiftReportCriticalTaskEnum.NonCritical,
  },
];

export const RING_CRITICAL_TASKS_OPTIONS = [
  {
    label: 'tasksAnalyzer.criticalTaskOptions.both',
    value: RingAggregationCriticalTaskEnum.Both,
  },
  {
    label: 'tasksAnalyzer.criticalTaskOptions.critical',
    value: RingAggregationCriticalTaskEnum.Critical,
  },
  {
    label: 'tasksAnalyzer.criticalTaskOptions.nonCritical',
    value: RingAggregationCriticalTaskEnum.NonCritical,
  },
];

export const localStorageTaskAnalyzerOptionsSchema = z.object({
  queryOptions: taskAnalyzerQueryOptionsSchema.optional(),
  checkedKeys: z.array(z.string()).optional(),
});
export type LocalStorageTaskAnalyzerOptions = z.infer<
  typeof localStorageTaskAnalyzerOptionsSchema
>;
