const SEARCH_PARAM_KEYS = [
  'page',
  'startDate',
  'endDate',
  'operator',
  'shiftLeader',
  'shiftEngineer',
  'shiftManager',
  'shift',
  'team',
] as const;

export type SearchParamKey = (typeof SEARCH_PARAM_KEYS)[number];

export const SEARCH_PARAM_KEY_MAP = {
  page: 'page',
  startDate: 'startDate',
  endDate: 'endDate',
  operator: 'operator',
  shiftLeader: 'shiftLeader',
  shiftEngineer: 'shiftEngineer',
  shiftManager: 'shiftManager',
  shift: 'shift',
  team: 'team',
} as const satisfies { [K in SearchParamKey]: K };
