import { App, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import {
  UploadProps,
  UploadChangeParam,
  UploadFile,
  UploadListType,
} from 'antd/lib/upload/interface';
import React, { useImperativeHandle } from 'react';
import { useTranslation } from 'translations/hooks';

import {
  useCreatePrivateDeleteUrlMutation,
  useCreatePrivateUploadUrlMutation,
} from 'generated/graphql';
import logger from 'technical/logger';

import { FILES_TOTAL_MB_MAX, headers, MB_SIZE } from './services';

const { Dragger } = Upload;

interface Props<Value = UploadFile[]> {
  onChange?: (value: Value) => void;
  onPreview?: (file: UploadFile) => void;
  onDynamicUpdate?: (value: Value) => void;
  value?: Value;
  children?: React.ReactNode;
  accept?: string;
  uploadFolder?: string;
  listType?: UploadListType;
  disableUpload?: boolean;
}

type RcCustomRequestOptions = Parameters<
  Exclude<UploadProps['customRequest'], undefined>
>[0];

const FileUpload = React.forwardRef<any, Props>(
  (
    {
      onChange,
      onPreview,
      onDynamicUpdate,
      value,
      accept,
      children,
      uploadFolder = '',
      listType = 'text',
      disableUpload = false,
    }: Props,
    refs,
  ) => {
    const { t } = useTranslation();
    const { message } = App.useApp();
    const [createUploadUrl] = useCreatePrivateUploadUrlMutation();
    const [createDeleteUrl] = useCreatePrivateDeleteUrlMutation();
    useImperativeHandle(refs, () => ({ value, onChange }));

    function onChangeCustom(info: UploadChangeParam) {
      if (onChange) {
        // removing file without status - i e which were refused during beforeUpload
        const finalFiles = info.fileList.filter((file) => !!file.status);
        onChange(finalFiles);
      }

      if (onDynamicUpdate && info.file.status === 'done') {
        const finalFiles = info.fileList.filter((file) => !!file.status);
        onDynamicUpdate(finalFiles);
      }

      logger.info('fileUpload - ', info);
      if (info.file.status === 'error') {
        message.error(
          t('common.fileUpload.upload', {
            fileName: info.file.name,
            context: 'error',
          }),
        );
      }
    }

    const customRequest = async ({
      file,
      action,
      onSuccess,
      onError,
    }: RcCustomRequestOptions) => {
      // @todo: use axios and show progress indicator
      return fetch(action, {
        method: 'PUT',
        body: file,
        headers,
      })
        .then((res: any) => {
          if (onSuccess) {
            onSuccess(file, res);
          }
        })
        .catch((err: Error) => {
          if (onError) {
            onError(err);
          }
        });
    };

    const beforeUpload = (file: RcFile, files: RcFile[]) => {
      if (disableUpload) {
        message.error(t('common.fileUpload.uploadLimitReached'));
        return false;
      }
      if (
        value &&
        value.some(({ name }: { name: string }) => name === file.name)
      ) {
        message.error(t('common.fileUpload.duplicateWarning'));
        return false;
      }
      const totalSize = (value ?? [])
        .concat(files)
        .reduce(
          (acc: number, currentFile: UploadFile) =>
            acc + (currentFile.size || 0),
          0,
        );
      // Convert Bytes to MBytes and validate lower than max
      const isLowSizeEnough = totalSize / MB_SIZE < FILES_TOTAL_MB_MAX;
      if (!isLowSizeEnough) {
        message.error(
          t('common.fileUpload.sizeWarning', { size: FILES_TOTAL_MB_MAX }),
        );
        return false;
      }
      return true;
    };

    const action = (file: RcFile) => {
      if (disableUpload) {
        throw new Error('upload limit reached');
      }
      return new Promise<string>((resolve, reject) =>
        createUploadUrl({
          variables: { fileName: file.name, folderName: uploadFolder },
        })
          .then((result) => {
            const uploadUrl = result.data?.createFileUploadTempUrl?.url;
            if (!uploadUrl) {
              throw new Error('Cannot create upload URL');
            }
            resolve(uploadUrl);
          })
          .catch((err) => {
            logger.error(err);
            message.error(
              t('common.fileUpload.upload', {
                fileName: file.name,
                context: 'error',
              }),
            );
            reject(err.message);
          }),
      );
    };

    const onRemove = (file: UploadFile<any>) =>
      new Promise<boolean | void>((resolve, reject) => {
        const url = `${uploadFolder}/${file.name}`;
        createDeleteUrl({
          variables: { fileName: url },
        })
          .then((result) => {
            const deleteUrl = result.data?.createFileDeleteTempUrl?.url;
            if (!deleteUrl) {
              throw new Error('Cannot create delete URL');
            }
            fetch(deleteUrl, {
              method: 'DELETE',
              headers,
            })
              .then(() => {
                resolve(true);
              })
              .catch((err: Error) => {
                logger.error(err);
                reject(err.message);
              });
          })
          .catch((err: Error) => {
            logger.error(err);
            message.error(
              t('common.fileUpload.delete', {
                fileName: file.name,
                context: 'error',
              }),
            );
            reject(err.message);
          });
      });

    return (
      <Dragger
        accept={accept ?? 'image/*'}
        customRequest={customRequest}
        method="put"
        name="file"
        multiple
        listType={listType}
        action={action}
        beforeUpload={beforeUpload}
        onPreview={onPreview}
        onChange={onChangeCustom}
        onRemove={onRemove}
        fileList={value ?? []}
        showUploadList={{
          showDownloadIcon: false,
          showRemoveIcon: true,
        }}
      >
        {children}
      </Dragger>
    );
  },
);

export default FileUpload;
