import { nameWithPreviousActivityName } from 'business/shift-report/activity/services';
import { parseTaskDateString } from 'business/shift-report/task/services/timeOperations';
import { TaskFullFragment } from 'generated/graphql';

import DisplayTaskFiles from './displayTaskFiles';

interface Props {
  tasks: TaskFullFragment[];
}

function DisplayTasksFiles({ tasks }: Props) {
  return (
    <>
      {tasks.map((task) =>
        task.files.length > 0 ? (
          <div className="bloc" key={task.id}>
            <h3>{`${nameWithPreviousActivityName(task.activity).join(
              '/',
            )} - ${parseTaskDateString(task.startDate).format(
              'LT',
            )} - ${parseTaskDateString(task.endDate).format('LT')}`}</h3>
            <DisplayTaskFiles task={task} />
          </div>
        ) : null,
      )}
    </>
  );
}

export default DisplayTasksFiles;
