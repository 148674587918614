import { Flex, Pagination } from 'antd';
import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';

import { useAppContext } from 'business/contextProviders/useAppContext';
import ReportCard from 'business/shift-report/report/components/ReportCard';
import { SEARCH_PARAM_KEY_MAP } from 'business/shift-report/report/hooks/constants';
import { useFilterParams } from 'business/shift-report/report/hooks/use-filter-params';
import { ShiftReportCardInfoFragment } from 'generated/graphql';
import Loader from 'ui/loader';

import styles from './index.module.scss';

export const DEFAULT_PAGE_LIMIT = 5;
export const DEFAULT_PAGE_NUMBER = 1;

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  loading: boolean;
  refetchList: () => Promise<unknown>;
  shiftReports?: ShiftReportCardInfoFragment[];
  paginationMeta?: {
    pageSize?: number;
    shiftReportCount: number;
  };
  emptyListComponent: React.ReactNode;
  routeRedirection?: string;
}

function ReportList({
  loading,
  refetchList,
  shiftReports,
  emptyListComponent,
  paginationMeta,
  className,
  routeRedirection,
  ...divProps
}: Props) {
  const { currentConstructionSiteId } = useAppContext();

  const { currentPage, updateSearchParam, emptyFilters } = useFilterParams();
  const [isMounted, setIsMounted] = useState(false);

  const shiftReportList =
    shiftReports && shiftReports.length > 0
      ? shiftReports.map((report) => {
          return (
            <ReportCard
              key={report.id}
              report={report}
              refetchList={refetchList}
              routeRedirection={routeRedirection}
            />
          );
        })
      : emptyListComponent;

  const changePage = useCallback(
    (newPage: number) => {
      updateSearchParam(SEARCH_PARAM_KEY_MAP.page, `${newPage}`);
    },
    [updateSearchParam],
  );

  useEffect(() => {
    // To avoid resetting filters on coming back from a report card
    if (isMounted) {
      emptyFilters();
    } else {
      setIsMounted(true);
    }
    // Re run only on construction site change, any other change is not interesting here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentConstructionSiteId]);

  return (
    <div {...divProps} className={classNames(styles.reportList, className)}>
      <Flex vertical align="center" gap={20}>
        {loading ? <Loader /> : shiftReportList}
      </Flex>
      {paginationMeta && (
        <Pagination
          current={currentPage}
          defaultCurrent={DEFAULT_PAGE_NUMBER}
          total={paginationMeta.shiftReportCount}
          showSizeChanger={false}
          className={styles.paginationBox}
          hideOnSinglePage
          onChange={changePage}
          defaultPageSize={paginationMeta.pageSize ?? DEFAULT_PAGE_LIMIT}
        />
      )}
    </div>
  );
}

export default ReportList;
