import dayjs from 'dayjs';
import invariant from 'tiny-invariant';
import { useTranslation } from 'translations/hooks';

import { useGraphqlApiHeaders } from 'business/contextProviders/useApiHeaders';
import { useAppContext } from 'business/contextProviders/useAppContext';
import { SingleCardPageWithFilterCardLayout } from 'business/layout/single-card-page-with-filter-card';
import { SingleGraphReferenceContextProvider } from 'business/providers/graph-reference';
import {
  Module_Enum,
  useGetProductionAnalyzeSeriesQuery,
} from 'generated/graphql';
import { FilterCard } from 'ui/filter-card';

import { ReportProdAnalyzeFilters } from './components/filters';
import { ReportProdAnalyzeGraphContainer } from './components/graph-container';
import { ReportProdAnalyzeHeader } from './components/header';
import { PrintHeader } from './components/print-title';
import { ReportProdAnalyzeFiltersProvider } from './providers/filters';
import { ReportProdAnalyzeSeriesFilterProvider } from './providers/series-filter';
import { GROUP_BY_TYPES_MAP } from './types';

const ProductionAnalyze = () => {
  const { t } = useTranslation();
  const { currentConstructionSiteId } = useAppContext();
  invariant(currentConstructionSiteId, 'No construction site id');
  const headers = useGraphqlApiHeaders(Module_Enum.ShiftReport);

  const { data } = useGetProductionAnalyzeSeriesQuery({
    variables: {
      constructionSiteId: currentConstructionSiteId,
    },
    context: {
      headers,
    },
  });

  return (
    <SingleGraphReferenceContextProvider>
      <ReportProdAnalyzeFiltersProvider
        initialFilters={{
          filterType: 'date',
          dateRange: [dayjs().subtract(1, 'week'), dayjs()],
          groupByType: GROUP_BY_TYPES_MAP.shift,
        }}
      >
        <ReportProdAnalyzeSeriesFilterProvider
          shift={data?.shift.map(({ id }) => id) ?? []}
          team={data?.team.map(({ id }) => id) ?? []}
        >
          <SingleCardPageWithFilterCardLayout
            title={t('pages.manager.productionAnalyze.title')}
            header={
              <ReportProdAnalyzeHeader
                shifts={data?.shift ?? []}
                teams={data?.team ?? []}
              />
            }
            printHeader={
              <PrintHeader
                printTitle={t('reportAnalyze.productionAnalyze.print.title')}
              />
            }
            filters={
              <FilterCard>
                <ReportProdAnalyzeFilters
                  teamSwitchEnabled={(data?.team.length ?? 0) > 0}
                />
              </FilterCard>
            }
          >
            <ReportProdAnalyzeGraphContainer
              shifts={data?.shift || []}
              teams={data?.team || []}
            />
          </SingleCardPageWithFilterCardLayout>
        </ReportProdAnalyzeSeriesFilterProvider>
      </ReportProdAnalyzeFiltersProvider>
    </SingleGraphReferenceContextProvider>
  );
};

export default ProductionAnalyze;
