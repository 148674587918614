import { TimePickerProps } from 'antd';
import dayjs, { Dayjs } from 'dayjs';

import { parseShiftTimeStringWithReportDate } from 'business/shift-report/shift/services/timeOperations';
import { ShiftFullFragment } from 'generated/graphql';
import { formatTime, isOnTwoDays } from 'technical/time-utils';

import { CascaderOptionType } from './types';

export const setDate = (time: Dayjs, date: Dayjs): Dayjs => {
  const year = date.year();
  const dayOfYear = date.dayOfYear();
  return time.year(year).dayOfYear(dayOfYear);
};

const HOURS = Array.from({ length: 24 }, (_, hour) => hour);
const MINUTES = Array.from({ length: 60 }, (_, minute) => minute);

export function getDisabledTimeForShift(
  shift: ShiftFullFragment,
  date: Dayjs,
): ReturnType<Exclude<TimePickerProps['disabledTime'], undefined>> {
  const startDate = parseShiftTimeStringWithReportDate(shift.startTime, date);
  let endDate = parseShiftTimeStringWithReportDate(shift.endTime, date);

  if (endDate < startDate) {
    endDate = endDate.add(1, 'day');
  }

  const rounded = {
    start: startDate.clone().startOf('hour'),
    end: endDate.clone().endOf('hour'),
  };

  const enabledHours: number[] = [];
  let testDate = startDate.clone();

  // This loop will add one more available hour in case of DST change
  while (testDate.isBetween(rounded.start, rounded.end, null, '[)')) {
    enabledHours.push(testDate.get('hour'));
    testDate = testDate.add(1, 'hour');
  }

  function disabledMinutes(hour: number): Array<number> {
    if (enabledHours[0] === hour) {
      const start = startDate.minute();
      return MINUTES.filter((minute) => minute < start);
    }
    if (enabledHours[enabledHours.length - 1] === hour) {
      const end = endDate.minute();
      return MINUTES.filter((minute) => minute > end);
    }
    return [];
  }

  function disabledHours() {
    return HOURS.filter((hour) => !enabledHours.includes(hour));
  }

  return { disabledHours, disabledMinutes };
}

export const taskFilter = (inputValue: string, path: CascaderOptionType[]) => {
  const filteredPath = path.filter((option: any) =>
    option.label.toLowerCase().includes(inputValue.toLowerCase()),
  );
  return filteredPath.length > 0;
};

export const setTimeRangeDate =
  (date: Dayjs, shift: ShiftFullFragment) =>
  (startTime?: Dayjs | null, endTime?: Dayjs | null): [Dayjs?, Dayjs?] => {
    if (!startTime || !endTime) {
      return [];
    }

    // Set timezone for dayjs object
    // DatePicker value doest not pick dayjs timezone. We need to recreate object with the right one.
    // To compare the values, $x.$localOffset is missing in the object coming from DatePicker
    let taskStartTime = dayjs.tz(startTime.format('YYYY-MM-DD HH:mm:ss'));
    let taskEndTime = dayjs.tz(endTime.format('YYYY-MM-DD HH:mm:ss'));

    const shiftStartTime = parseShiftTimeStringWithReportDate(
      shift.startTime,
      date,
    );

    // Setting the value to the report date can create inconsistency
    // with time zone. Therefore it's only use to compare
    const startTimeCompareValue = setDate(taskStartTime, date);
    const endTimeCompareValue = setDate(taskEndTime, date);
    const diffBetweenTaskStartTimeAndShiftStartTimeOnReportDay =
      startTimeCompareValue.diff(shiftStartTime);
    const diffBetweenTaskStartTimeAndShiftStartTimeOnReportNextDay =
      startTimeCompareValue.add(1, 'day').diff(shiftStartTime);

    // Determine if the task start time is closer from the start date of the shift
    // on the report date or on the next day
    // For this code to work, the shift time interval can only be 12hours max
    if (
      Math.abs(diffBetweenTaskStartTimeAndShiftStartTimeOnReportDay) >
      Math.abs(diffBetweenTaskStartTimeAndShiftStartTimeOnReportNextDay)
    ) {
      taskStartTime = setDate(taskStartTime, date.add(1, 'day'));
    } else {
      taskStartTime = setDate(taskStartTime, date);
    }

    // Comparing the updated task start time with the end time on the report date
    // to notice a day change
    if (taskStartTime.isAfter(endTimeCompareValue)) {
      taskEndTime = setDate(taskEndTime, date.add(1, 'day'));
    } else {
      taskEndTime = setDate(taskEndTime, date);
    }

    return [formatTime(taskStartTime), formatTime(taskEndTime)];
  };

export const isTaskInShiftRange =
  (date: Dayjs, shift: ShiftFullFragment) =>
  (startDate?: Dayjs | null, endDate?: Dayjs | null) => {
    const shiftStartTime = parseShiftTimeStringWithReportDate(
      shift.startTime,
      date,
    );
    let shiftEndTime = parseShiftTimeStringWithReportDate(shift.endTime, date);

    if (isOnTwoDays(shift.startTime, shift.endTime)) {
      shiftEndTime = shiftEndTime.add(1, 'day');
    }

    if (startDate?.isAfter(endDate)) {
      return false;
    }

    if (
      startDate?.isBefore(shiftStartTime) ||
      startDate?.isAfter(shiftEndTime) ||
      endDate?.isBefore(shiftStartTime) ||
      endDate?.isAfter(shiftEndTime)
    ) {
      return false;
    }
    return true;
  };
