import dayjs from 'dayjs';
import { getLocale } from 'translations/locale';
import { Locale } from 'translations/types';

export const formatDateToDayAndMonth = (dateString: string | Date) => {
  const locale = getLocale();

  switch (locale) {
    case Locale.en: {
      return dayjs(dateString).format('MM/DD');
    }

    case Locale.enGb:
    case Locale.es:
    case Locale.fr:
    default: {
      return dayjs(dateString).format('DD/MM');
    }
  }
};
