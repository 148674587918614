import { ExportOutlined } from '@ant-design/icons';
import invariant from 'tiny-invariant';
import { useTranslation } from 'translations/hooks';
import { getLocale } from 'translations/locale';

import { useRestApiHeaders } from 'business/contextProviders/useApiHeaders';
import { useAppContext } from 'business/contextProviders/useAppContext';
import { useLegendFilter } from 'business/production-and-performance/pages/excavation-build-time/providers/legend-filter';
import { useProdAndPerfFilters } from 'business/production-and-performance/providers/prod-and-perf-filters-provider';
import { Module_Enum } from 'generated/graphql';
import { notEmpty } from 'technical/utils/not-empty';
import Button from 'ui/button';

import { useExcavationBuildTimeExport } from './use-excavation-build-time-export';

export const ExcavationBuildTimeExport = () => {
  const { t } = useTranslation();
  const { currentConstructionSiteId } = useAppContext();
  const headers = useRestApiHeaders(Module_Enum.ProdPerf);
  invariant(currentConstructionSiteId, 'No constructionSite id');

  const { dayMode, showTotalDurations } = useProdAndPerfFilters();
  const { legendFilter } = useLegendFilter();

  const { generateExport, fetchRange } = useExcavationBuildTimeExport();

  const onClick = () => {
    const legendFilterKeys = Object.entries(legendFilter)
      .map(([key, value]) => (value ? key : null))
      .filter(notEmpty);

    generateExport({
      body: {
        constructionSiteId: currentConstructionSiteId,
        dayMode: dayMode,
        fetchTotalDurations: showTotalDurations ?? false,
        legendFilter: legendFilterKeys,
        locale: getLocale(),
        ...fetchRange,
      },
      headers,
    });
  };

  return (
    <Button icon={<ExportOutlined />} onClick={onClick}>
      {t('common.export')}
    </Button>
  );
};
