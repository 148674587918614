import { Descriptions, Typography } from 'antd';
import { useTranslation } from 'translations/hooks';

import pageStyle from 'business/shift-report/report/pages/ReportEdit/index.module.scss';
import { ShiftReportChartViewFragment } from 'generated/graphql';
import { formatUserName } from 'technical/string/formatter';

interface Props {
  report: ShiftReportChartViewFragment;
}

const getColumns = ({
  operatorValidation,
  isEngineer,
  isManager,
}: {
  operatorValidation: boolean;
  isEngineer: boolean;
  isManager: boolean;
}) => {
  let columns = 3;
  columns += operatorValidation ? 1 : 0;
  columns += isEngineer ? 1 : 0;
  columns += isManager ? 1 : 0;
  columns = columns > 4 ? columns / 2 : columns;
  return { xl: columns, lg: columns, md: columns, sm: 1 };
};

const ReportChartHeaderInfo = ({ report }: Readonly<Props>) => {
  const { t } = useTranslation();

  return (
    <Descriptions
      layout="horizontal"
      column={getColumns({
        operatorValidation: report.operatorValidation,
        isEngineer: !!report.shiftEngineer,
        isManager: !!report.shiftManager,
      })}
      size="small"
      className={pageStyle.headerInfo}
    >
      <Descriptions.Item
        label={
          <Typography.Text strong>
            {t('pages.form.creator.label')}
          </Typography.Text>
        }
      >
        {report.userName}
      </Descriptions.Item>
      <Descriptions.Item
        label={
          <Typography.Text strong>
            {t('pages.form.operator.label')}
          </Typography.Text>
        }
      >
        {report.operator?.name}
      </Descriptions.Item>
      <Descriptions.Item
        label={
          <Typography.Text strong>
            {t('pages.form.shiftLeader.label')}
          </Typography.Text>
        }
      >
        {report.shiftLeader?.name}
      </Descriptions.Item>
      {report.team && (
        <Descriptions.Item
          label={
            <Typography.Text strong>
              {t('pages.form.team.label')}
            </Typography.Text>
          }
        >
          {report.team.name}
        </Descriptions.Item>
      )}
      {report.shiftEngineer && (
        <Descriptions.Item
          label={
            <Typography.Text strong>
              {t('pages.form.shiftEngineer.label')}
            </Typography.Text>
          }
        >
          {formatUserName(report.shiftEngineer)}
        </Descriptions.Item>
      )}
      {report.shiftManager && (
        <Descriptions.Item
          label={
            <Typography.Text strong>
              {t('pages.form.shiftManager.label')}
            </Typography.Text>
          }
        >
          {formatUserName(report.shiftManager)}
        </Descriptions.Item>
      )}
      <Descriptions.Item
        label={
          <Typography.Text strong>
            {t('pages.form.startMetricPoint.label')}
          </Typography.Text>
        }
      >
        {report.startMetricPoint}
      </Descriptions.Item>
      {report.operatorValidation && (
        <Descriptions.Item
          label={
            <Typography.Text strong>
              {t('pages.form.endMetricPoint.label')}
            </Typography.Text>
          }
        >
          {report.endMetricPoint}
        </Descriptions.Item>
      )}
    </Descriptions>
  );
};

export default ReportChartHeaderInfo;
