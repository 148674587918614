const GROUP_BY_TYPES = ['shift', 'team'] as const;

export type GroupByType = (typeof GROUP_BY_TYPES)[number];
export type GroupByTypesMap = { [K in GroupByType]: K };

export const GROUP_BY_TYPES_MAP = {
  shift: 'shift',
  team: 'team',
} satisfies GroupByTypesMap;

export type ReportProdAnalyzeGraphPoint = {
  date: Date;

  // The `Date` is required to match the record signature
  [serieId: string]: number | Date;
};

export type SerieMetadata = {
  id: string;
  name: string;
};
